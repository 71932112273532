import React, { useEffect, useRef, useState } from "react";
import { ApiService } from "../../Components/Services/apiServices";
import { useNavigate } from "react-router-dom";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomProgressBar from '../../Components/Elements/progress_bar';
import { TailSpin } from "react-loader-spinner";
import { showToastWithOkButton } from "../../Components/Elements/toastutils";

let apiServices = new ApiService();
function Bseonboardingstep3() {
  const navigate = useNavigate();
  const [countryData, setCountryData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [Addresstypedata, setAddressTypedata] = useState([]);
  const [statesData, setStatesData] = useState([]);
  const [spinnerLoading, setSpinnerLoading] = useState(false)
  const didMountRef = useRef(true)
  useEffect(() => {
    if (didMountRef.current) {
      toast.error = (message) => {
        showToastWithOkButton(message);
      };
      getCountryData();
      getstateData();
      getAddressType()
      setSpinnerLoading(true)
      const dataString = {
      }
      apiServices.bsegetuserdataPostRequest(dataString).then(result => {
        if (result.data.status == 'success') {
          setSpinnerLoading(false)
          setFormData({
            ...formData,
            address1: result.data.data.gs_address1,
            address2: result.data.data.gs_address2,
            city: result.data.data.gs_city,
            pinCode: result.data.data.gs_pin,
            state: result.data.data.gs_state,
            address_type: result?.data?.data?.gs_add_type,
            country: result?.data?.data?.gs_country
          });
        } else if (result.data.status == 'error' && result.data.message == 'Session expired') {
          navigate("/");
          localStorage.removeItem('AUTH_TOKEN');
          setSpinnerLoading(false)
        }
      })
    }
    didMountRef.current = false;
  }, []);
  const handleNextClick = () => {
    console.log(formData)
    if (
      !formData.address1 ||
      !formData.state ||
      !formData.country ||
      !formData.pinCode ||
      !formData.city ||
      !formData?.address_type ||
      formData.pinCode.length !== 6
    ) {
      if (!formData.address1) {
        toast.error('Please enter your address.');
      } else if (!formData?.address_type) {
        toast.error('Please enter address type.');
      }
      else if (!formData.pinCode) {
        toast.error('Please enter your pincode.');
        return
      }
      else if (formData.pinCode.length > 6 || formData.pinCode.length < 6) {
        toast.error('Please enter correct pincode.');
        return
      }
      else if (!formData.city) {
        toast.error('Please enter your city.');
      }
      else if (!formData.state) {
        toast.error('Please enter your state.');
      }
      else if (!formData.country) {
        toast.error('Please enter your country.');
      }
      return;
    }
    setIsLoading(true)
    const dataString = {
      addr1: formData.address1,
      addr2: formData.address2,
      city: formData.city,
      pin: formData.pinCode,
      state: formData.state,
      country: formData.country,
      address_type: formData?.address_type
    };
    apiServices.bseuseraddressPostRequest(dataString).then((result) => {
      if (result.data.status === 'success') {
        navigate('/bseonboarding/step3cont');
        setIsLoading(false)
      } else if (result.data.status === "error" && result.data.message === "Session expired") {
        navigate("/");
        setIsLoading(false)
        localStorage.removeItem('AUTH_TOKEN');
      }
      else {
        toast.error(result?.data?.message)
        setIsLoading(false)
      }
    })
      .catch(error => {
        console.error("API Request Error:", error);
      })
  };
  const getCountryData = (e) => {
    apiServices.getbsecountryGetRequest().then(result => {
      if (result.data.status == "success") {
        setCountryData(result.data.data);
      }
    })
  }
  const getAddressType = (e) => {
    apiServices.getAddressTypegetrequest().then(result => {
      console.log(result)
      if (result.data.status == "success") {
        setAddressTypedata(result.data.data);
      }
    })
  }
  const getstateData = (e) => {
    apiServices.getbsestatesGetRequest().then(result => {
      if (result.data.status == "success") {
        setStatesData(result.data.data);
      }
    })
  }
  const handleBackClick = () => {
    navigate("/bseonboarding/step2");
  };
  const [formData, setFormData] = useState({
    address1: '',
    address2: '',
    city: '',
    pinCode: '',
    state: '',
    country: 'IN',
    address_type: ""
  });


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    // console.log(value)
    if (name == 'pinCode') {
      if (value.length > 5) {
        const dataString = {
          'Pincode': value
        }
        apiServices.checkPincodePostRequest(dataString).then(res => {
          console.log(res); 
          let stateName = '';
          if (res.data.StateData !== null) {
            stateName = res.data.StateData?.state_code
          }
          else {
            stateName = '';
          }

          // setFormData({ state:stateName, city:res.data.Pincodedata.City});
          setFormData({
            ...formData,

            city: res.data.Pincodedata.City,
            pinCode: value,
            state: stateName,

          });
        })
      }
    }
  };





  const pincodechage = (e) => {
    const { name, value } = e.target;
    console.log(formData)
    setFormData({ pinCode: value });

  }

  return (
    <>
     <div className={spinnerLoading == true ? 'parentDisable' : ''} width="100%">
          <div className='overlay-box'>
            <TailSpin
              color="#ffffff"
              visible={spinnerLoading}
            />
          </div>
          </div>
      <BrowserView>
        <div className="auth-page-wrapper pt-50">
          <div className="auth-page-content">
            <div className="container">
              <div className="row justify-content-center mt-3">
                <div className="col-lg-6">
                  <div className="crds">
                    <CustomProgressBar percent={28} />
                    <div className="crds-body p-50">
                      <div className="text-center mb-30">
                        <img src="/img/logo.png" className="wd-150 mb-4"></img>
                        <p>Step 3 of 7</p>
                        <h5>Communication Address (India)</h5>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="from-group mb-20">
                            <label>Address 1<span className="tx-red">*</span></label>
                            <input
                              type="text"
                              name="address1"
                              value={formData.address1}
                              onChange={handleInputChange}
                              maxLength="20"
                              />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="from-group mb-20">
                            <label>Address 2</label>
                            <input
                              type="text"
                              name="address2"
                              maxLength="30"
                              value={formData.address2}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="from-group mb-20">
                            <label>Pincode<span className="tx-red">*</span></label>
                            <input
                              type="number"
                              name="pinCode"
                              value={formData.pinCode}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="from-group mb-20">
                            <label>City<span className="tx-red">*</span></label>
                            <input
                              type="text"
                              name="city"
                              value={formData.city}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="from-group mb-20">
                            <label>State<span className="tx-red">*</span></label>
                            <select
                              name="state"
                              value={formData.state}
                              className="custom-select"
                              onChange={handleInputChange}
                            >
                              <option value="">Select</option>
                              {statesData.map((value) => (
                                <option key={value.state_code} value={value.state_code} >{value.state_name}</option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="from-group mb-20">
                            <label> Residence Country<span className="tx-red">*</span></label>
                            <select name="country" value={formData.country} defaultValue={"IN"} onChange={handleInputChange} className="custom-select">
                              {countryData.map((value) => (
                                <option
                                  key={value.country_code}
                                  value={value.country_code}
                                  selected={value.country_code === formData?.country}
                                >
                                  {value.country_name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-12 ">
                          <div className="from-group mb-20">
                            <label>Address Type<span className="tx-red">*</span></label>
                            <select
                              name="address_type"
                              className="custom-select"
                              onChange={handleInputChange}
                            >
                              <option value="">Select</option>
                              {Addresstypedata.map((value) => (
                                <option key={value.at_code} value={value.at_code} selected={value.at_code == formData.address_type ? true : false}>{value.at_name}</option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                      <button className="btn btn-primary btn-lg btn-full mt-1" onClick={handleNextClick} disabled={isLoading}>
                        {isLoading ? (
                          <img src="/img/loder01.gif" width="60px" height="11px" />
                        ) : (
                          "Next"
                        )}
                      </button>
                      <button className="btn btn-outline-primary btn-lg btn-full mt-3" onClick={handleBackClick} >Back</button>


                      {/* ************************************* */}
                      {/* <div className="col-lg-12">
                        <input type="number" name="Pincode" value={pinCode.Pincode} onChange={(e) => pincodechage(e)} />

                        
                      </div> */}
                      {/* ************************************* */}

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </BrowserView>
      <MobileView>
=
        <header className="mheader">
          <div className="container">
            <div className="mheader-left">
              <div className="dflex align-items-center" onClick={handleBackClick}>
                <i class="icon-arrow-left8"></i>
                <div className="ms-3">
                  <h6 className="mb-0"> Communication Address (India)</h6>
                  <p className="tx-12 mb-0 line18">Step 3 of 7</p>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="mobile-main">
          <div className="mobile-form">
            <CustomProgressBar percent={28} />
            <div className="p15 pb-5 mb-5">
              <div className="row g-2">
                <div className="col-lg-6">
                  <div className="from-group mb-2">
                    <label>Address 1<span className="tx-red">*</span></label>
                    <input
                      type="text"
                      name="address1"
                      value={formData.address1}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="from-group mb-2">
                    <label>Address 2</label>
                    <input
                      type="text"
                      name="address2"
                      value={formData.address2}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="from-group mb-2">
                    <label>Pincode<span className="tx-red">*</span></label>
                    <input
                      type="number"
                      name="pinCode"
                      value={formData.pinCode}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-6">
                  <div className="from-group mb-2">
                    <label>City<span className="tx-red">*</span></label>
                    <input
                      type="text"
                      name="city"
                      value={formData.city}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-6">
                  <div className="from-group mb-2">
                    <label>State<span className="tx-red">*</span></label>
                    <select
                      name="state"
                      className="custom-select"
                      value={formData.state}
                      onChange={handleInputChange}
                    >
                      <option value="">Select</option>
                      {statesData.map((value) => (
                        <option key={value.state_code} value={value.state_code}>{value.state_name}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="from-group mb-2">
                    <label> Residence Country<span className="tx-red">*</span></label>

                    <select name="country" value={formData.country} defaultValue={"IN"} onChange={handleInputChange} className="custom-select">

                      {countryData.map((value) => (
                        <option
                          key={value.country_code}
                          value={value.country_code}
                          selected={value.country_code === formData?.country}>
                          {value.country_name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-lg-12 ">
                  <div className="from-group mb-2">
                    <label>Address Type<span className="tx-red">*</span></label>
                    <select
                      name="address_type"
                      className="custom-select"
                      onChange={handleInputChange}
                    >
                      <option value="">Select</option>
                      {Addresstypedata.map((value) => (
                        <option key={value.at_code} value={value.at_code} selected={value.at_code == formData.address_type ? true : false}>{value.at_name}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-button">
          <div className='d-flex'>
            <button className="btn btn-primary btn-lg btn-full " onClick={handleNextClick} disabled={isLoading}>
              {isLoading ? (
                <img src="/img/loder01.gif" width="60px" height="11px" />
              ) : (
                "Next"
              )}
            </button>
          </div>
        </div>
      </MobileView>
    </>
  )
}
export default Bseonboardingstep3
