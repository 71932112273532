import React, { useEffect, useRef, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import {  toast } from "react-toastify";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { ApiService } from "../../Components/Services/apiServices";
import SweetAlert from 'react-bootstrap-sweetalert';
import { showToastWithOkButton } from "../../Components/Elements/toastutils";

let apiServices = new ApiService();

function ForgetPassword() {
    const [registerData, setRegisterData] = useState({ user_email: '', email_otp: '', cnfpassword: '', password: '' });
    const [isLoading, setIsLoading] = useState(false);
    const [show, setShow] = useState(false)
    const [alerttitle, setalerttitle] = useState("")
    const navigate = useNavigate()
    const didMountRef = useRef(true)
     useEffect(() => {
        if(didMountRef.current){
          toast.error = (message) => {
            showToastWithOkButton(message);
          };
        }
        didMountRef.current = false;
      }, [])
    const firststep = (e) => {
        e.preventDefault();
        if (registerData.user_email == '') {
            toast.error("Email Address Cannot Be Empty");
            return false;
        }
        setIsLoading(true)
        const dataString = {
            "user_email": registerData.user_email,
            "step": 1,
        }
        apiServices.resetpasswordPostRequest(dataString).then(result => {
            if (result.data.status == 'success') {
                setShow(true)
                setalerttitle(result.data.message);
                setIsLoading(false)

            }
            else {

                toast.error(result.data.message)
                setIsLoading(false)
            }
        })
            .catch(error => {
            })

    }
    const confirm = () => {
        navigate("/");

    }
    const handleInput = (e) => {
        const key = e.target.name;
        const value = e.target.value;
        setRegisterData({ ...registerData, [key]: value })
    }
    return (
        <>

            <BrowserView>
             
                <div className="auth-page-wrapper pt-50">
                    <div className="auth-page-content">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-5">
                                <div className="crds">
                                    <div className="crds-body p-50">
                                        <div className="text-center mb-30">
                                        <img src="/img/logo.png" className="wd-150 mb-4"></img>
                                            <h5>Reset Password</h5>
                                            <p>Please Enter Your Registered Email Address</p>
                                        </div>
                                        <div className="from-group mb-20">
                                            <label> Email address</label>
                                            <input type="email" name="user_email" onChange={handleInput} ></input>
                                            <p className="tx-12 mt-2">We will send you Reset link on your Email.</p>
                                        </div>

                                        <button
                                            className="btn btn-primary btn-lg btn-full mb-4"
                                            onClick={firststep}
                                            disabled={isLoading}>
                                            {isLoading ? (
                                                <img src="/img/loader.gif" width="30px" height="30px" />
                                            ) : (
                                                "Continue"
                                            )}
                                        </button>
                                        <p className="mb-0 text-center">Already have an account? <span className="tx-theme" onClick={() => navigate("/")} style={{ cursor: "pointer" }}>Login</span></p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
      
                </div>
            </BrowserView>
            <MobileView>
                <div className="p25 mt-5">
                    <Link to="/" className="backbutton"><img src="img/back.png" className="wd-20" /></Link>

                    <div className="mb-4 text-center">
                        <img src="/img/logo.png" className="wd-150 mb-4"></img>
                        <h5>Reset Password</h5>
                        <p className="tx-gray tx-12">Please Enter Your Registered Email Address</p>

                    </div>
                    <div className="from-group mb-3">
                        <label>Enter Email Address<span className="text-danger fs-5">*</span></label>
                        <input type="email" name="user_email" onChange={handleInput} placeholder="yourname@yourmail.com"></input>
                        <p className="tx-12 mt-2">We will send you Reset link on your Email.</p>
                    </div>


                    <button
                        className="btn btn-primary btn-lg btn-full mb-4"
                        onClick={firststep}
                        disabled={isLoading} // Disable the button when loading
                    >
                        {isLoading ? (
                            <img src="/img/loader.gif" width="30px" height="30px" />
                        ) : (
                            "Continue"
                        )}
                    </button>
                    <p className="mb-0 text-center">Already have an account?<span className="tx-theme" onClick={() => navigate("/")} style={{ cursor: "pointer" }}> Login</span></p>

                </div>

            </MobileView>
            <SweetAlert warning confirmBtnCssClass='alertpop' title={alerttitle} confirmBtnText='Ok'
                show={show} onConfirm={confirm} btnSize="md"
            >
            </SweetAlert>
        </>
    )
}
export default ForgetPassword