import React, { useEffect, useRef, useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { useNavigate , Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { ApiService } from "../../Components/Services/apiServices";
import { showToastWithOkButton } from "../../Components/Elements/toastutils";

let apiServices = new ApiService()
function ForgetPasswordLink(){
    const [registerData, setRegisterData] = useState({ user_password: '', user_confirm_password: '' });
    const [isLoading, setIsLoading] = useState(false);
const didMountRef = useRef(true)
const navigate = useNavigate()
    useEffect(() => {
        if (didMountRef.current) {
            toast.error = (message) => {
                showToastWithOkButton(message);
              };
        }
        didMountRef.current = false;
    });
    const handleInput = (e) => {
        const key = e.target.name;
        const value = e.target.value;
        setRegisterData({ ...registerData, [key]: value })
        if (key == "user_password") {
            checkspecialchar(value);
            checkcapsmall(value);
            checkminchar(value);
          }

    }
    function checkminchar(e) {
        var a = document.getElementById("minchar");
        if (e.length > 8) {
          a.setAttribute("class", "mb-1 tx-11 text-success");
          return true;
        } else {
          a.setAttribute("class", "mb-1 tx-11 text-danger");
          return false;
        }
      }
      function checkcapsmall(e) {
        var cap = document.getElementById("capsmall");
        var pattern = new RegExp("^(?=.*[a-z])(?=.*[A-Z]).+$");
        if (e.match(pattern)) {
          cap.setAttribute("class", "mb-1 tx-11 text-success");
          return true;
        } else {
          cap.setAttribute("class", "mb-1 tx-11 text-danger");
          return false;
        }
      }
      function checkspecialchar(e) {
        var special = document.getElementById("specialchar");
        var pattern2 = new RegExp("^(?=.*[-+_!@#$%^&*.,?]).+$");
        if (e.match(pattern2)) {
          special.setAttribute("class", "mb-1 tx-11 text-success");
          return true;
        } else {
          special.setAttribute("class", "mb-1 tx-11 text-danger");
          return false;
        }
      }
    
    const saveresetpassworddata=()=>{
     
        if(registerData.user_password == "" ){
            toast.error("Pleast Enter Password", {
                position: toast.POSITION.TOP_CENTER
            }); 
            return false
        }
        else if(registerData.user_confirm_password == "" ){
            toast.error("Pleast Enter Confirm Password", {
                position: toast.POSITION.TOP_CENTER
            }); 
            return false
        }

        else if(registerData.user_password !=  registerData.user_confirm_password ){
            toast.error("Password and Confirm Password should be same", {
                position: toast.POSITION.TOP_CENTER
            }); 
            return false
        }
        if (
            checkspecialchar(registerData.user_password) &&
            checkcapsmall(registerData.user_password) &&
            checkminchar(registerData.user_password)
          ) {
            
          }
          
          const queryString = window.location.search;
          const urlParams = new URLSearchParams(queryString);
          const id = urlParams.get('id')   
          console.log(id)
        const dataString={
            password : registerData.user_password,
            confirm_password : registerData.user_confirm_password,
            id : id
        }
      
        apiServices.resetpasswordbymailPostRequest(dataString ).then((res)=>{
            console.log(res)
        if(res.data.status == "success"){
            toast(res.data.data, {
                position: toast.POSITION.TOP_CENTER
            }); 
            setTimeout(() => {
                navigate("/")
            }, 2000);
        }
        
        })

    }

 
    return(
        <>
        <BrowserView>
        <div className="auth-page-wrapper pt-50">
                    <div className="auth-page-content">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-5">
                                <div className="crds">
                                    <div className="crds-body p-50">
                                        <div className="text-center mb-30">
                                        <img src="/img/logo.png" className="wd-150 mb-4"></img>
                                        <h5>Reset Password</h5>
                                        <p>Please Create Your New Password </p>
                                        </div>
                                        <div className="from-group mb-3">
                                <label>Password*</label>
                                <input type="password" name="user_password" onChange={handleInput} ></input>
                            
                            </div>
                            <div className="from-group mb-3">
                                <label>Confirm Password*</label>
                                <input type="password" name="user_confirm_password" onChange={handleInput} ></input>
                               
                            </div>
                            <p id="minchar" className="tx-11 tx-gray mb-1">
                  Minimum 8 characters
                </p>
                <p id="capsmall" className="tx-11 tx-gray mb-1">
                  Have atleast 1 capital and 1 small alphabet
                </p>
                <p id="specialchar" className="tx-11 tx-gray mb-1">
                  Have one of the following special characters @,#,$,-,_,%,&,!,?
                </p>

                                        <button
                                            className="btn btn-primary btn-lg btn-full mb-4"
                                            onClick={saveresetpassworddata}
                                            disabled={isLoading}>
                                            {isLoading ? (
                                                <img src="/img/loader.gif" width="30px" height="30px" />
                                            ) : (
                                                "Continue"
                                            )}
                                        </button>
                                       

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
      
                </div>
      
                </BrowserView>
                <MobileView>
                
                <div className="p25 mt-5">
                    <Link to="/" className="backbutton"><img src="img/back.png" className="wd-20" /></Link>

                    <div className="mb-4 text-center">
                        <img src="https://app.goalstox.com/img/logo.png" className="wd-150 mb-4"></img>
                        <h6>Reset Password</h6>
                    </div>
                    <div className="from-group mb-3">
                        <label>Password*</label>
                        <input type="password" name="user_password" onChange={handleInput}></input>
        
                    </div>
                    <div className="from-group mb-3">
                        <label>Confirm Password*</label>
                        <input type="password" name="user_confirm_password" onChange={handleInput} ></input>
                       
                    </div>

                    <p id="minchar" className="tx-11 tx-gray mb-1">
                  Minimum 8 characters
                </p>
                <p id="capsmall" className="tx-11 tx-gray mb-1">
                  Have atleast 1 capital and 1 small alphabet
                </p>
                <p id="specialchar" className="tx-11 tx-gray mb-1">
                  Have one of the following special characters @,#,$,-,_,%,&,!,?
                </p>
                    <button
                        className="btn btn-primary btn-lg btn-full mb-4"
                        disabled={isLoading} 
                        onClick={saveresetpassworddata}
                    >
                        {isLoading ? (
                            <img src="/img/loader.gif" width="30px" height="30px" />
                        ) : (
                            "Continue"
                        )}
                    </button>
                    <p className="mb-0 text-center">Already have an account?<span className="tx-theme" onClick={() => navigate("/")} style={{ cursor: "pointer" }}>Login</span></p>

                </div>
                </MobileView>
        </>
    )
}
export default ForgetPasswordLink