import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import PlainHeader from "../../Components/Header/PlainHeader";
import { ApiService } from "../../Components/Services/apiServices";
import { ToastContainer, toast } from 'react-toastify';
let apiServices = new ApiService();

function LoanAgainstMutualFund() {
    const loanapplication = async () => {   
      try {
          const result = await apiServices.getGuestUserIntractionTokenpostrequest({type:'LOAN_APPLICATION'});
  
          if (result?.data?.status !== "success") {
              toast(result?.data?.message || "Something went wrong");
              return;
          }
  
          if (!window.ScLoan) {
              console.error("Error: ScLoan is not available in the global scope.");
              return;
          }
  
          try {
              const lasSdkInstance = new window.ScLoan({ gatewayName: "goalstox" });
              console.log("SDK instance:", lasSdkInstance);
              window.debugSdkInstance = lasSdkInstance;
             
              const response = await lasSdkInstance.apply({
                  interactionToken: result.data.token
                  
              });
            
              console.log("Application successful:", response);
          } catch (sdkError) {
              console.error("SDK instantiation or application failed:", sdkError);
          }
      } catch (apiError) {
          console.error("API request failed:", apiError);
      }
  };
  return (
    <>
      <PlainHeader />
      <div className="container my-5">
        <div className="row justify-content-center align-items-center">
          {/* Left Section - Image */}
          <div className="col-md-6 text-center">
            <img
              src="/img/loan-against.webp" // Replace with actual image URL
              alt="Loan Against Mutual Fund"
              className="img-fluid rounded shadow-lg"
            />
          </div>

          {/* Right Section - Content */}
          <div className="col-md-6">
            <h1 className="fw-bold">Get Instant Loan Against Mutual Funds</h1>
            <p className="text-muted">
              Unlock the potential of your mutual fund investments without liquidating them.
              Get hassle-free loans at competitive interest rates with minimal paperwork.
            </p>

            {/* Key Benefits */}
            <div className="mb-4">
              <h5 className="fw-bold">Why Choose Us?</h5>
              <ul className="list-group list-group-flush">
                <li className="list-group-item border-0">
                  <i className="fa fa-check-circle tx-green"></i> Quick Loan Disbursal
                </li>
                <li className="list-group-item border-0">
                  <i className="fa fa-check-circle tx-green"></i> No Need to Sell Mutual Funds
                </li>
                <li className="list-group-item border-0">
                  <i className="fa fa-check-circle tx-green"></i> Minimal Documentation
                </li>
                <li className="list-group-item border-0">
                  <i className="fa fa-check-circle tx-green"></i> Competitive Interest Rates
                </li>
              </ul>
            </div>

            {/* Apply Now Button */}
            <button className="btn btn-primary btn-lg w-100" onClick={loanapplication}>Apply Now</button>

            {/* Contact Section */}
            <p className="text-muted mt-3 text-center">
              Need help? Contact us at <strong>help@goalstox.com</strong>
            </p>
          </div>
        </div>

        {/* Additional Section for More Information */}
        <div className="row mt-5">
          <div className="col-md-12 text-center">
            <h2 className="fw-bold">How It Works?</h2>
            <p className="text-muted">
              Apply online in just a few steps and get the best loan against your mutual funds.
            </p>
          </div>
          <div className="col-md-4 text-center">
            <div className="p-3 shadow rounded">
              <i className="bi bi-pencil-square display-5 text-primary"></i>
              <h5 className="mt-3">Step 1: Apply Online</h5>
              <p className="text-muted">Fill in your basic details to check your loan eligibility.</p>
            </div>
          </div>
          <div className="col-md-4 text-center">
            <div className="p-3 shadow rounded">
              <i className="bi bi-file-earmark-check display-5 text-primary"></i>
              <h5 className="mt-3">Step 2: Submit Documents</h5>
              <p className="text-muted">Upload your required documents for instant verification.</p>
            </div>
          </div>
          <div className="col-md-4 text-center">
            <div className="p-3 shadow rounded">
              <i className="bi bi-cash-stack display-5 text-primary"></i>
              <h5 className="mt-3">Step 3: Get Funds</h5>
              <p className="text-muted">Loan amount is disbursed directly into your bank account.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LoanAgainstMutualFund;
