import React, { useEffect, useRef, useState } from "react";
import { ApiService } from "../../Components/Services/apiServices";
import Header from "../../Components/Header";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import { BrowserView, MobileView } from "react-device-detect";
import { toast } from "react-toastify";


let apiServices = new ApiService();
function LoanDashboard() {
    const [loanData, setLoanData] = useState([])
    const [submitLoader, setSubmitLoader] = useState(false)
    const didMountRef = useRef(true)
    const Navigate = useNavigate()
    useEffect(() => {
        if (didMountRef.current) {
            setSubmitLoader(true)
            apiServices.dashboardloanpostrequest().then(result => {
                if (result.data.status == 'success') {
                     setLoanData(result.data.data)
                  
                    setSubmitLoader(false)
                } else if (result.data.status == 'error') {
                    // toast.error(result.data.status)
                    setSubmitLoader(false)
                }
                else {
                    setSubmitLoader(false)
                }
            })
          
          
        }
        didMountRef.current = false;
    }, []);






    return (
        <>
            {submitLoader ? <>
                <div className={submitLoader == true ? 'parentDisable' : ''} width="100%">
                    <div className='overlay-box'>

                        <TailSpin
                            color="#ffffff"
                            visible={submitLoader}
                        />

                    </div>
                </div>
            </> : ""}
            <Header />
            <BrowserView>
              
                <div className="container py-4">
                    <div className="card shadow-sm mt-4">
                                        <div className="card-body">
                                            <h5 className="card-title border-bottom tx-theme pb-2 mb-3" >Loan Listings</h5>
                                            <div className="table-responsive">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th>Loan ID</th>
                                                            <th>Flow ID</th>
                                                            <th>Product Type</th>
                                                            <th>Lender</th>
                                                            <th>Status</th>
                                                        </tr>
                                                    </thead>
                                                    {loanData.length > 0 ?
                                                    <tbody>
                                                        {loanData.map((value) => (
                                                            <tr onClick={() => Navigate('/loan-detail-another/' + value.lid)}>
                                                                <td > {value.lid}</td>
                                                                <td>{value.flowId}</td>
                                                                <td >{value.productType}</td>
                                                                <td >{value.lender}</td>
                
                                                                <td><span className="badge bg-primary bg-opacity-10 text-primary">{value.status}</span></td>
                
                                                            </tr>))}
                
                                                    </tbody>
                                                    :<tbody>
                                                        <tr>
                                                            <td colSpan={5} style={{textAlign:"center"}}>No Data Found</td>
                                                        </tr>
                                                    </tbody>}
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                </div>
            </BrowserView>
            <MobileView>

                <div className="container py-5">
                    <div className="row">
                        <div className="col-12">
                            <div className="card shadow-sm">
                                <div className="card-header" style={{ backgroundColor: "#304aa6" }}>
                                    <h5 className="mb-0 text-white">Loan Listings</h5>
                                </div>
                                {loanData.length > 0 ? (
                                    <div className="card-body">
                                        {loanData.map((value) => (
                                            <div
                                                key={value.lid}
                                                className="card border rounded mb-3 p-3 w-100"
                                                onClick={() => Navigate('/loan-detail/' + value.lid)}
                                            >
                                                <div className="row g-3 align-items-center">
                                                    <div className="col-12 col-md-4">
                                                        <label className="form-label text-muted small">Loan ID</label>
                                                        <p className="fw-bold mb-0 text-break">{value.lid}</p>
                                                    </div>
                                                    <div className="col-12 col-md-4">
                                                        <label className="form-label text-muted small">Flow ID</label>
                                                        <p className="fw-bold mb-0 text-break">{value.flowId}</p>
                                                    </div>
                                                    <div className="col-12 col-md-4">
                                                        <label className="form-label text-muted small">Product Type</label>
                                                        <p className="fw-bold mb-0">{value.productType}</p>
                                                    </div>
                                                    <div className="col-12 col-md-4">
                                                        <label className="form-label text-muted small">Lender</label>
                                                        <p className="fw-bold mb-0">{value.lender}</p>
                                                    </div>
                                                    <div className="col-12 col-md-4">
                                                        <label className="form-label text-muted small">Date</label>
                                                        <p className="fw-bold mb-0">{moment(value.createdAt).format('D/M/Y')}</p>
                                                    </div>
                                                    <div className="col-12 col-md-4">
                                                        <label className="form-label text-muted small">Status</label>
                                                        <p className="fw-bold mb-0">
                                                            {value.status === 'ACTIVE' ? (
                                                                <span className="badge bg-success">{value.status}</span>
                                                            ) : (
                                                                <span className="badge bg-danger">{value.status}</span>
                                                            )}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <div className="card-body">
                                        <p className="text-center text-muted fw-bold">No Data Found</p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </MobileView>
        </>
    )

}
export default LoanDashboard