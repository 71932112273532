import React from "react";
import { useModal } from "./modal_contex";
import { Modal, Button } from "react-bootstrap"; // Ensure Bootstrap is imported
import { ApiService } from "../Services/apiServices";
import { ToastContainer, toast } from 'react-toastify';
let apiServices = new ApiService();
function LoanApplication() {
  const { showSmallCaseTermsModal, setShowSmallCaseTermsModal, setShowSmallCaseCondition , showSmallCaseCondition } = useModal();

  const loanapplication = async () => {
 
    if(!showSmallCaseCondition && showSmallCaseCondition == false){
        document.getElementById("agreeToTerms").style.outline = "2px solid red"
        return false
    }
    else{
         document.getElementById("agreeToTerms").style.outline = "none"
    }
    try {
        const result = await apiServices.generateIntractionJwtTokenpostrequest({type:'LOAN_APPLICATION'});

        if (result?.data?.status !== "success") {
            toast(result?.data?.message || "Something went wrong");
            return;
        }

        if (!window.ScLoan) {
            console.error("Error: ScLoan is not available in the global scope.");
            return;
        }

        try {
            const lasSdkInstance = new window.ScLoan({ gatewayName: "goalstox" });
            console.log("SDK instance:", lasSdkInstance);
            window.debugSdkInstance = lasSdkInstance;
            setShowSmallCaseTermsModal(false)
            const response = await lasSdkInstance.apply({
                interactionToken: result.data.token
                
            });
          
            console.log("Application successful:", response);
        } catch (sdkError) {
            console.error("SDK instantiation or application failed:", sdkError);
        }
    } catch (apiError) {
        console.error("API request failed:", apiError);
    }
};

  return (
    <Modal show={showSmallCaseTermsModal} onHide={() => setShowSmallCaseTermsModal(false)} centered>
      <Modal.Header closeButton>
        {/* <Modal.Title>Need Funds? Consider a Smarter Option! 💡</Modal.Title> */}
      </Modal.Header>
      <Modal.Body>
        <p>
          By proceeding, you consent to Goalstox sharing your details (PAN, DOB, Mobile number) with Smallcase for account opening. 
          Loan against mutual funds is provided by Smallcase and its associates, Essential Investment Managers Pvt. Ltd. and Nexum Finvest Pvt. Ltd. 
          as per our agreement with them dated 02-01-2024 through registered NBFCs. Smallcase may request additional information for loan approval and disbursal.
        </p>
        <div className="text-center">
          <input type="checkbox" id="agreeToTerms" name="checkbox" onChange={(e) => setShowSmallCaseCondition(e.target.checked)} />
          <label htmlFor="agreeToTerms" className="ms-2">
            I agree to the <a href="https://www.goalstox.com/lamf-terms-and-conditions" target="_blank">terms & conditions</a>
          </label>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={loanapplication}>
          Continue
        </Button>
        <Button variant="secondary" onClick={() => setShowSmallCaseTermsModal(false)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default LoanApplication;
