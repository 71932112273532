import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomProgressBar from '../../Components/Elements/progress_bar';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ApiService } from "../../Components/Services/apiServices";
import { TailSpin } from "react-loader-spinner";
import { showToastWithOkButton } from "../../Components/Elements/toastutils";
let apiServices = new ApiService();
function Bseonboardingstep2() {
  const navigate = useNavigate();
  const [bankForm1, setBankForm1] = useState(true)
  const [isLoading, setIsLoading] = useState(false);
  const [burTaxStatus, setBurTaxStatus] = useState("");
  const [banksData, setBanksData] = useState([]);
  const [spinnerLoading, setSpinnerLoading] = useState(false)
 const [bankDetailsfirst, setBankDetailsFirst] = useState(
    {
      accType: '',
      accNo: '',
      neftIfscCode: '',
      micrNo: '',
      bankName: '',
      bank_name: "",
      bankBranch: '',
    },
  )
  const didMountRef = useRef(true)
  useEffect(() => {
    if (didMountRef.current) {
      toast.error = (message) => {
        showToastWithOkButton(message);
      };
      getbanksData();
      setSpinnerLoading(true)
      apiServices.bsegetuserdataPostRequest({}).then((result) => {
        if (result.data.status === 'success') {
          setSpinnerLoading(false)
          setBurTaxStatus(result.data.bse_register_data.bur_tax_status)
          if (result.data.bse_bank_data[0]) {
            setBankDetailsFirst({ accType: result.data.bse_bank_data[0].ubd_acc_type, bank_name: result.data.bse_bank_data[0].bank.bse_bank_name, accNo: result.data.bse_bank_data[0].ubd_acc_no, neftIfscCode: result.data.bse_bank_data[0].ubd_ifsc, micrNo: result.data.bse_bank_data[0].ubd_micr, bankName: result.data.bse_bank_data[0].ubd_bank_name, bankBranch: result.data.bse_bank_data[0].ubd_bank_branch })
          }
        }
        else if (result.data.status === 'error' && result.data.message === 'Session expired') {
          navigate('/')
          setSpinnerLoading(false)
          localStorage.removeItem('AUTH_TOKEN');
        }
      });
    }
    didMountRef.current = false;
  }, []);
  const handleNextClick = () => {
   if (!bankDetailsfirst.accType) {
      toast.error('Please enter the account type');
      return;
    }
    if (!bankDetailsfirst.accNo) {
      toast.error('Please enter the account number');
      return;
    }
    if (!bankDetailsfirst.neftIfscCode) {
      toast.error('Please enter the IFSC code');
      return;
    }
    if (bankDetailsfirst.neftIfscCode.length !== 11) {
      toast.error('Please enter the 11-digit IFSC code ');
      return;
    }
    const regex = new RegExp("^[A-Z]{4}0[A-Z0-9]{6}$")
    if (!bankDetailsfirst.neftIfscCode.match(regex)) {
      toast.error('IFSC code is not correct');
      return;
    }
    if (!bankDetailsfirst.bankName) {
      toast.error('Please enter the bank name for Bank ');
      return;
    }
    if (!bankDetailsfirst.bankBranch) {
      toast.error('Please enter the bank branch for Bank ');
      return;
    }
    setIsLoading(true)
    const dataString = {
      bankDetails1: bankDetailsfirst,
    }
    apiServices.bsebankdetailUrlPostRequest(dataString)
      .then(result => {
        if (result.data.status == "success") {
          setIsLoading(false)
          toast.success(result.data.message);
          console.log(burTaxStatus , "burTaxStatus")
           if(burTaxStatus == '24' || burTaxStatus == '21'){
            navigate('/bseonboarding/step3forg')
          }
          else{
          navigate('/bseonboarding/step3')
          }
        }
        else {
          toast.success(result.data.message);
          setIsLoading(false)
        }
      })
  };
  const getbanksData = (e) => {
    apiServices.getbsebanksGetRequest().then(result => {
      if (result.data.status == "success") {
        setBanksData(result.data.data);
      }
    })
  }
  const handleBackClick = () => {
    navigate("/bseonboarding/0");
   
  };
  const handleInputChange = (e) => {
    console.log(bankDetailsfirst?.neftIfscCode, "bankDetailsfirst?.neftIfscCodebankDetailsfirst?.neftIfscCode")
    const { name, value } = e.target;
    setBankDetailsFirst({ ...bankDetailsfirst, [name]: value });
    if (name === "neftIfscCode") {
      if (value.length < 11) {
        setBankDetailsFirst({
          ...bankDetailsfirst,
          ['bankName']: "",
          ["bank_name"]: "",
          ['micrNo']: "",
          ["bankBranch"]: "",
          [name]: value,
        });
        return
      }
      if (value.length === 11) {
        const dataString = {
          neftIfscCode: value
        }
        const regex = new RegExp("^[A-Z]{4}0[A-Z0-9]{6}$")
        if (value.match(regex)) {
          apiServices.ifscdataPostRequest(dataString).then(res => {
            console.log(res, "resulthhjhk")
            if (res.status === 200 && res.data.ifsc && res.data.ifsc.bse_b_id) {
              apiServices.getMICRGetRequest(value).then(result => {
                setBankDetailsFirst({
                  ...bankDetailsfirst,
                  ['bankName']: res.data.ifsc.bse_b_id,
                  ["bank_name"]: res.data.ifsc.bse_bank_name,
                  ['micrNo']: result.data.MICR,
                  ["bankBranch"]: result.data.BRANCH,
                  [name]: value,
                });

              })
            } else {
              setBankDetailsFirst({ ...bankDetailsfirst, [name]: value });
            }
          })
        }
        else {
          toast.error("IFSC code is not correct")
          return;
        }
      }
    } else {
      setBankDetailsFirst({ ...bankDetailsfirst, [name]: value });
    }
  };
  return (
    <>
     <div className={spinnerLoading == true ? 'parentDisable' : ''} width="100%">
          <div className='overlay-box'>
            <TailSpin
              color="#ffffff"
              visible={spinnerLoading}
            />
          </div>
          </div>
      <BrowserView>
        <div className="auth-page-wrapper pt-50">
          <div className="auth-page-content">
            <div className="container">
              <div className="row justify-content-center mt-3">
                <div className="col-lg-6">
                  <div className="crds">
                  <CustomProgressBar percent={14} />
                    <div className="crds-body p-50">
                      <div className="text-center mb-30">
                        <img src="/img/logo.png" className="wd-150 mb-4"></img>
                        <p>Step 2 of 7</p>
                        {bankForm1 == true ? <>
                          <h5>Bank Details  (Default Account)</h5>
                        </> : ""}
                      </div>
                      {bankForm1 == true ?
                        <>
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="from-group mb-20">
                                <label>Account Type<span className="tx-red">*</span></label>
                                <select
                                  name="accType"
                                  id="accType"
                                  onChange={(e) => handleInputChange(e)}
                                  className="custom-select">
                                  <option value="">Select</option>
                                  <option value="SB" selected={bankDetailsfirst.accType == "SB" ? true : ""}>Savings Bank</option>
                                  <option value="CB" selected={bankDetailsfirst.accType == "CB" ? true : ""}>Current Bank</option>
                                  <option value="NE" selected={bankDetailsfirst.accType == "NE" ? true : ""}>NRE</option>
                                  <option value="NO" selected={bankDetailsfirst.accType == "NO" ? true : ""}>NRO</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="from-group mb-20">
                                <label>Account Number<span className="tx-red">*</span></label>
                                <input
                                  type="number"
                                  name="accNo"
                                  id="accNo"
                                  defaultValue={bankDetailsfirst.accNo}
                                  onChange={(e) => handleInputChange(e)}
                                />
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="from-group mb-20 ifsc-input">
                                <label>IFSC Code<span className="tx-red">*</span></label>
                                <input
                                  type="text"
                                  name="neftIfscCode"
                                  id="neftIfscCode"
                                  defaultValue={bankDetailsfirst.neftIfscCode}
                                  onChange={(e) => { handleInputChange(e) }}
                                  onInput={(e) => e.target.value = e.target.value.toUpperCase()}
                                  minLength="11"
                                  maxLength="11"
                                  pattern="[A-Z]{4}[0][\d]{6}"
                                />
                                {bankDetailsfirst.neftIfscCode !== "" && bankDetailsfirst?.bank_name && bankDetailsfirst.bankName !== "" && bankDetailsfirst.bankBranch !== "" ? <div className="ifsc-verify"><img src="/img/verify.png" className="wd-20"></img></div> : ""}
                                {bankDetailsfirst.bank_name && bankDetailsfirst.bankBranch ? <p className="mb-0 mt-2 tx-12" style={{color:'var(--color-primary)'}}>{bankDetailsfirst.bank_name} ,   {bankDetailsfirst.bankBranch}</p> : ""}
                              </div>
                            </div>
                            </div>
                        </>
                        : ""}
                      <button className="btn btn-primary btn-lg btn-full mt-1" onClick={handleNextClick} disabled={isLoading}>
                        {isLoading ? (
                          <img src="/img/loder01.gif" width="60px" height="11px" />
                        ) : (
                          "Next"
                        )}
                      </button>
                      <button className="btn btn-outline-primary btn-lg btn-full mt-3" onClick={handleBackClick} >Back</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </BrowserView>
      <MobileView>
        <header className="mheader">
          <div className="container">
            <div className="mheader-left">
              <div className="dflex align-items-center" onClick={handleBackClick}>
                <i class="icon-arrow-left8"></i>
                <div className="ms-3">
                  <h6 className="mb-0">Bank Details</h6>
                  <p className="tx-12 mb-0 line18">Step 2 of 7</p>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="mobile-main">
          <div className="mobile-form">
            <CustomProgressBar percent={14} />
            <div className="p15 pb-5 mb-5">
              {bankForm1 == true ?
                <div >
                  <div className="mb-4">
                    <h6>Bank Details (Default)</h6>
                  </div>
                  <div className="row g-2">
                    <div className="col-lg-6">
                      <div className="from-group mb-2">
                        <label>Account Type<span className="tx-red">*</span></label>
                        <select
                          name="accType"
                          id="accType"
                          onChange={(e) => handleInputChange(e)}
                          className="custom-select"
                        >
                          <option value="">Select</option>
                          <option value="SB" selected={bankDetailsfirst.accType == "SB" ? true : ""}>Savings Bank</option>
                          <option value="CB" selected={bankDetailsfirst.accType == "CB" ? true : ""}>Current Bank</option>
                          <option value="NE" selected={bankDetailsfirst.accType == "NE" ? true : ""}>NRE</option>
                          <option value="NO" selected={bankDetailsfirst.accType == "NO" ? true : ""}>NRO</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="from-group mb-2">
                        <label>Account No<span className="tx-red">*</span></label>
                        <input
                          type="number"
                          name="accNo"
                          id="accNo"
                          defaultValue={bankDetailsfirst.accNo}
                          onChange={(e) => handleInputChange(e)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 col-12">
                      <div className="from-group mb-2 ifsc-input">
                        <label>IFSC Code<span className="tx-red">*</span></label>
                        <input
                          type="text"
                          name="neftIfscCode"
                          id="neftIfscCode"
                          defaultValue={bankDetailsfirst.neftIfscCode}
                          onChange={(e) => { handleInputChange(e) }}
                          onInput={(e) => e.target.value = e.target.value.toUpperCase()}
                          minLength="11"
                          maxLength="11"
                          pattern="[A-Z]{4}[0][\d]{6}"
                        />
                        {bankDetailsfirst.neftIfscCode !== "" && bankDetailsfirst?.bank_name && bankDetailsfirst.bankName !== "" && bankDetailsfirst.bankBranch !== "" ? <div className="ifsc-verify"><img src="/img/verify.png" className="wd-20"></img></div> : ""}
                        {bankDetailsfirst.bank_name && bankDetailsfirst.bankBranch ? <p className="mb-0 tx-12" style={{color:'var(--color-primary)'}}>{bankDetailsfirst.bank_name} ,   {bankDetailsfirst.bankBranch}</p> : ""}
                      </div>
                    </div>
                  </div>
                </div> : ""}
            </div>
          </div>
        </div>
        <div className="footer-button">
          <button className="btn btn-primary btn-lg btn-full me-2 " onClick={handleNextClick} disabled={isLoading}>   {isLoading ? (
            <img src="/img/loder01.gif" width="60px" height="11px" />
          ) : (
            "Next"
          )}</button>
        </div>
      </MobileView>
    </>
  )
}

export default Bseonboardingstep2
