import React from "react";

function PlainHeader(){
    return (
        <>
        <header className="header" style={{padding:'20px'}}>
        <div className="container">
        <div className="header-left">
        <a href="/dashboard" className="logo"><img src="/img/logo.png"></img></a>
        </div>
        </div>
        </header>
        </>
    )
}
export default PlainHeader