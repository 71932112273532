import React, { useEffect, useRef, useState } from "react";
import Header from "../../Components/Header";
import { useNavigate, useParams } from "react-router-dom";
import { ApiService } from "../../Components/Services/apiServices";
import moment from "moment";
import { TailSpin } from "react-loader-spinner";
import { toast } from "react-toastify";

let apiServices = new ApiService();
function LoanDetail() {
    const { lid } = useParams()
    const [loanSummary, setLoanSummary] = useState({})
    const [transectionHistory, setTransectionHistory] = useState([])
    const [pledgeHoldings, setPledgeHoldings] = useState([])
    const [disbursement, setDisbursement] = useState({})
    const [upcomingTransaction, seUpcomingTransaction] = useState({})
    const [bankData, setBankData] = useState({})
    const [soaData, setSoaData] = useState({})
    const [submitLoader, setSubmitLoader] = useState(false)
    const [detailDataScreen, setDetailDataScreen] = useState(false)
    const [journeyStatus, setJourneyStatus] = useState('')

    const didMountRef = useRef(true)
    const Navigate = useNavigate()
    useEffect(() => {
        if (didMountRef.current) {
            setSubmitLoader(true)
            const dataString = {
                "loanid": lid
            }
            apiServices.Loandetailpostrequest(dataString).then(result => {
                if (result.data.status == 'success') {
                    setJourneyStatus(result.data.data.journeyStatus)
                    if (result.data.data.journeyStatus == 'DISBURSED') {
                        loandetaildata()
                        setDetailDataScreen(true)
                    }
                    else {

                    }
                    setSubmitLoader(false)
                } else if (result.data.status == 'error') {
                    setSubmitLoader(false)
                }
                else {
                    setSubmitLoader(false)
                }
            })

        }
        didMountRef.current = false;
    }, []);

    const loandetaildata = () => {
        const dataString = {
            "loanid": lid
        }
        apiServices.loansummarypostrequest(dataString).then(result => {
            if (result.data.status == 'success') {
                setLoanSummary(result.data.data.loanSummary.summary)
                setDisbursement(result.data.data.loanSummary.disbursementTAT)
                setSoaData(result.data.data.loanSummary.disbursementTAT.soa)
                seUpcomingTransaction(result.data.data.loanSummary.transaction.upcoming)
                setBankData(result.data.data.loanSummary.transaction.upcoming.linkedBankDetails)
            } else if (result.data.status == 'error') {

            }
        })
        apiServices.loanhistorypostrequest(dataString).then(result => {
            if (result.data.status == 'success') {
                setTransectionHistory(result.data.data.transactionHistory)
            } else if (result.data.status == 'error') {

            }
        })
        apiServices.pledgeholdingspostrequest(dataString).then(result => {
            if (result.data.status == 'success') {
                setPledgeHoldings(result.data.data.pledgedHoldings)
            } else if (result.data.status == 'error') {

            }
        })
    }
    
    const gotoloandashboard = async () => {
        try {
            const result = await apiServices.generateIntractionJwtTokenpostrequest({});
    
            if (result?.data?.status !== "success") {
                toast(result?.data?.message || "Something went wrong");
                return;
            }
    
            if (!window.ScLoan) {
                console.error("Error: ScLoan is not available in the global scope.");
                return;
            }
           const token = result.data.token
           const element = document.getElementById("nodatascreen");
           console.log(element)
           if (element) {
             element.style.display = "none";
           }
            try {
                const lasSdkInstance = new window.ScLoan({ gatewayName: "goalstox" });
              
                    const dashboard = lasSdkInstance.service({
                      interactionToken: token,
                      target: "completeloan"
                    });
                    const response = await dashboard.task;
                    dashboard.on("close", () => {
                        console.log("ddd")
                        const token = result.data.token
                        const element = document.getElementById("nodatascreen");
                        console.log(element)
                        if (element) {
                          element.style.display = "block";
                        }
                      });
                    
                  
                        console.log(response)
         
        } catch (apiError) {
            console.error("API request failed:", apiError);
        }
        }
     catch (apiError) {
        console.error("API request failed:", apiError);
    }
    }
    return (
        <>
            {submitLoader ? <>
                <div className={submitLoader == true ? 'parentDisable' : ''} width="100%">
                    <div className='overlay-box'>

                        <TailSpin
                            color="#ffffff"
                            visible={submitLoader}
                        />

                    </div>
                </div>
            </> : ""}
            <Header />
            {detailDataScreen == true ?

                <div className="container py-4">
                    {/* <div className="d-flex justify-content-between align-items-center mb-4 pb-2 border-bottom">
                    <div className="fw-bold fs-4" style={{ color: "#304aa6" }}>Loan Against MF Dashboard</div>
                    <div className="text-end">
                        <div className="fw-bold">GURBIR BHATIA</div>
                        <div className="text-secondary small">Loan ID: 6321983553329</div>
                    </div>
                </div> */}

                    <div className="row g-4">
                        <div className="col-md-6">
                            <div className="card shadow-sm h-100">
                                <div className="card-body">
                                    <h5 className="card-title border-bottom pb-2 mb-3" style={{ color: "#304aa6" }}>Loan Summary</h5>
                                    <div className="mb-3">
                                        <div className="text-secondary small">Sanctioned Limit</div>
                                        <div className="fw-bold fs-5">₹ {loanSummary.sanctionedLimit}</div>
                                    </div>
                                    <div className="mb-3">
                                        <div className="text-secondary small">Credit Limit</div>
                                        <div className="fw-bold fs-5">₹ {loanSummary.creditLimit}</div>
                                        <div className="progress mt-1" style={{ height: "8px" }}>
                                            <div className="progress-bar" role="progressbar" style={{ width: "100%" }}></div>
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <div className="text-secondary small">Cash Withdrawn</div>
                                        <div className="fw-bold fs-5">₹ {loanSummary.cashWithdrawn}</div>
                                    </div>
                                    <div className="mb-3">
                                        <div className="text-secondary small">Available Cash</div>
                                        <div className="fw-bold fs-5">₹ {loanSummary.availableCash}</div>
                                    </div>
                                    <div className="mb-3">
                                        <div className="text-secondary small">Interest Rate</div>
                                        <div className="fw-bold fs-5">{loanSummary.rateOfInterest}%</div>
                                    </div>
                                    <div className="mb-3">
                                        <div className="text-secondary small">Lender</div>
                                        <div className="fw-bold fs-5">{loanSummary.lender}</div>
                                    </div>
                                    <div className="mb-3">
                                        <div className="text-secondary small">Loan Account</div>
                                        <div className="fw-bold fs-5">{loanSummary.loanAccountNumber}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="card shadow-sm h-100">
                                <div className="card-body">
                                    <h5 className="card-title border-bottom tx-theme pb-2 mb-3" >Upcoming Payment</h5>
                                    <div className="bg-light p-3 rounded d-flex align-items-center">
                                        <div className="rounded-circle bg-primary bg-opacity-10 text-primary d-flex align-items-center justify-content-center me-3" style={{ width: "40px", height: "40px" }}>₹</div>
                                        <div className="flex-grow-1">
                                            <div className="text-secondary small">{moment(upcomingTransaction.date).format('D MMM Y')}</div>
                                            <div>Interest Payment {upcomingTransaction.description}</div>
                                            <div className="small mt-1">
                                                <strong>Bank:</strong> {bankData.bankName} ({bankData.accountNumber})
                                            </div>
                                        </div>
                                        <div className="text-end">
                                            <div className="small text-secondary">Payment Method</div>
                                            <div>{upcomingTransaction.type}</div>
                                        </div>
                                    </div>

                                    <div className="mt-4">
                                        <h5 className="card-title border-bottom pb-2 mb-3" style={{ color: "#304aa6" }}>Loan Status</h5>
                                        <div className="mb-3">
                                            <div className="text-secondary small">Status</div>
                                            <div className="fw-bold">
                                                <span className="badge bg-success bg-opacity-10 text-success">ACTIVE</span>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <div className="text-secondary small">Created On</div>
                                            <div>22 Feb 2025</div>
                                        </div>
                                        <div className="mb-3">
                                            <div className="text-secondary small">Loan Type</div>
                                            <div>Loan Against Mutual Fund</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card shadow-sm mt-4">
                        <div className="card-body">
                            <h5 className="card-title border-bottom tx-theme pb-2 mb-3" >Recent Transactions</h5>
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Description</th>
                                            <th>Amount</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {transectionHistory.map((value) => (
                                            <tr>
                                                <td className="text-secondary small"> {moment(value.date).format('D MMM Y')}</td>
                                                <td>{value.description}</td>
                                                <td className="fw-bold">{value.amount}</td>

                                                <td><span className="badge bg-primary bg-opacity-10 text-primary">{value.status}</span></td>

                                            </tr>))}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className="card shadow-sm mt-4">
                        <div className="card-body">
                            <h5 className="card-title border-bottom tx-theme pb-2 mb-3">Pledged Mutual Funds</h5>
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Mutual Fund Name</th>
                                            <th>Units</th>
                                            <th>Credit Limit</th>
                                            <th>Pledge Type</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {pledgeHoldings.map((value) => (
                                            <tr>
                                                <td>{value.scripName}</td>
                                                <td>{value.units}</td>
                                                <td>₹ {value.creditLimit}</td>
                                                <td>{value.pledgeType}</td>
                                            </tr>))}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className="card shadow-sm mt-4">
                        <div className="card-body">
                            <h5 className="card-title tx-theme border-bottom pb-2 mb-3">Disbursement Details</h5>
                            <div className="mb-3">
                                <div className="text-secondary small">Demat Pledging Time</div>
                                <div>{moment(disbursement.dematPledgingTime).format('D MMM Y , H:mm')} </div>
                            </div>
                            <div className="mb-3">
                                <div className="text-secondary small">SOA Submission Time</div>
                                <div> {moment(soaData.time).format('D MMM Y , H:mm')}</div>
                            </div>
                            <div className="mb-3">
                                <div className="text-secondary small">SOA Amount</div>
                                <div className="fw-bold fs-5">₹ {soaData.amount}</div>
                            </div>
                            <div className="mb-3">
                                <div className="text-secondary small">Disbursement Status</div>
                                <div>
                                    <span className="badge bg-primary bg-opacity-10 text-primary">{soaData.disbursementStatus}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="text-center text-secondary small mt-4 pt-3 border-top">
                        Last updated: 11 March 2025
                    </div>
                </div>
                :
                <>
                <div id="completeloan"></div>
                <div className="justify-content-center align-items-center" style={{marginTop:"310px"}} id="nodatascreen">
    <div className="row w-100">
        <div className="col-md-6 mx-auto">
            <div className="card shadow-sm text-center">
                <div className="card-body">
                    <p className="mb-4"> 
                        Your status for this loan is <strong>{journeyStatus}</strong>. 
                        To complete the loan process, please click on the <strong>Complete Process</strong> button.
                    </p>
                    <button className="btn btn-primary btn-sm" onClick={gotoloandashboard}>Complete Process</button>
                </div>
            </div>
        </div>
    </div>
</div></>

            }
        </>
    )
}
export default LoanDetail