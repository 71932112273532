import React, { useEffect, useRef } from "react";
import { ApiService } from "../../Components/Services/apiServices";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { TailSpin } from "react-loader-spinner";
import { toast } from "react-toastify";
import Header from "../../Components/Header";
let apiServices = new ApiService();

function LoanDetailAnother(){
const didmountref = useRef(true);
const navigate = useNavigate()
    useEffect(()=>{
if(didmountref.current){
    gotoloandashboard()
}
didmountref.current = false;
    })

       const gotoloandashboard = async () => {
            try {
                const result = await apiServices.generateIntractionJwtTokenpostrequest({type:'SERVICE'});
        
                if (result?.data?.status !== "success") {
                    toast(result?.data?.message || "Something went wrong");
                    return;
                }
        
                if (!window.ScLoan) {
                    console.error("Error: ScLoan is not available in the global scope.");
                    return;
                }
               const token = result.data.token
               const element = document.getElementById("nodatascreen");
               console.log(element)
               if (element) {
                 element.style.display = "none";
               }
                try {
                    const lasSdkInstance = new window.ScLoan({ gatewayName: "goalstox" });
                  
                        const dashboard = lasSdkInstance.service({
                          interactionToken: token,
                          target: "completeloan"
                        });
                        const response = await dashboard.task;
                        dashboard.on("close", () => {
                          navigate('/dashboard')
                          });
                        
                        dashboard.on("error", (err) => {
                            toast("Dashboard error:", err);
                          
                        
                          });
             
            } catch (apiError) {
                console.log("API request failed:", apiError);
                const element = document.getElementById("nodatascreen");
                navigate('/dashboard')
                console.log(element)
                if (element) {
                  element.style.display = "block";
                }
            }
            }
         catch (apiError) {
            console.log("API request failed:", apiError);
    
        }
        }
return (
    <>
    <Header/>
<div id="completeloan"></div>
    </>
)
}
export default LoanDetailAnother