import { toast } from "react-toastify";

export const showToastWithOkButton = (message) => {
  toast(
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <p style={{ marginBottom: "10px" }}>{message}</p>
      <button
        onClick={() => toast.dismiss()}
        style={{
          padding: "0px 16px",
          background: "#fff",
          color: "#e74c3c",
          border: "none",
          cursor: "pointer",
          borderRadius: "5px",
        }}
      >
        OK
      </button>
    </div>,
    {
      autoClose: false,
      closeOnClick: false,
      type: "error",
         theme: "colored"
    }
  );
};
