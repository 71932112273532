import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BrowserView, MobileView, } from 'react-device-detect';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import CustomProgressBar from '../../Components/Elements/progress_bar';
import { ApiService } from "../../Components/Services/apiServices";
import { TailSpin } from "react-loader-spinner";
import { showToastWithOkButton } from "../../Components/Elements/toastutils";
let apiServices = new ApiService();
function BseOnboarding() {
  const navigate = useNavigate();
  const [taxData, setTaxData] = useState({ tstatus:localStorage.getItem('TAX_STATUS'), occode: "02", kyctype: "K", ckycno: "", wealth_source: "", income_source: "", tax_resistance_country: "IN", politically_expose: "N" });
  const [occupationData, setOccupationData] = useState([])
  const [Incomedata, setIncomedata] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const [countryData, setCountryData] = useState([]);
  const [Wealthdata, setWealthdata] = useState([])
  const [kycTypeData, setKycTypeData] = useState([])
  const [taxStatus, setTaxStatus] = useState([])
  const [spinnerLoading, setSpinnerLoading] = useState(false)
  const didMountRef = useRef(true)
  useEffect(() => {
    if (didMountRef.current) {
      toast.error = (message) => {
        showToastWithOkButton(message);
      };
      setSpinnerLoading(true)
 console.log(localStorage.getItem('TAX_STATUS'),"ffgghhhhjjj")
      apiServices.bsegetuserdataPostRequest({})
        .then(result => {
          if (result.data.status === 'success') {
            setSpinnerLoading(false)
            // if(result.data.data.gs_isminor == 1){
            //   console.log("sss")
            //   setTaxData({ tstatus:'02'})
            // }
            if (result.data.bse_register_data && result.data.bse_register_data.bur_tax_status) {
              console.log(result.data, "result.data.registration")
             
              setTaxData({
                tstatus: localStorage.getItem('TAX_STATUS'),
                occode: result.data.bse_register_data.bur_occ_code,
                kyctype: result.data.bse_register_data.bur_kyc_type,
                ckycno: result.data.bse_register_data.bur_ckyc_no,
                wealth_source: result.data.bse_register_data.bur_wealth_source,
                income_source: result.data.bse_register_data.bur_income_source,
                politically_expose: result.data.bse_register_data.bur_politically_expose,
                tax_resistance_country: result.data.bse_register_data.bur_tax_resistance_country
              });
            } else {
            }
          } else if (result.data.status === 'error' && result.data.message === 'Session expired') {
            navigate('/')
            localStorage.removeItem('AUTH_TOKEN');
            setSpinnerLoading(false)

          }
        });
      apiServices.getBseFirstStepDataGetRequest().then((result) => {
        if (result.data.status === 'success') {
          setOccupationData(result.data.occupationdata)
          setKycTypeData(result.data.kyctypedata)


        } else {
        }
      })
      getIncomesourcedata()
      getWealthsourcedata()
      getCountryData()
    }
    didMountRef.current = false;
  }, [taxData]);
  const getIncomesourcedata = (e) => {
    apiServices.getIncomesourcegetrequest().then(result => {
      console.log(result, "jkjkk")
      if (result.data.status == "success") {
        setIncomedata(result.data.data);
      }
    })
  }
  const getCountryData = (e) => {
    apiServices.getbsecountryGetRequest().then(result => {
      if (result.data.status == "success") {
        setCountryData(result.data.data);
      }
    })
  }
  const getWealthsourcedata = (e) => {
    apiServices.getwealthsourcegetrequest().then(result => {
      if (result.data.status == "success") {
        setWealthdata(result.data.data);
      }
    })
  }

  const handleNextClick = () => {

  console.log(taxData.tstatus)
    if (taxData.occode === "") {
      toast.error("Please fill occupation code field");
      return;
    }
    if (taxData.wealth_source == "" || taxData.wealth_source == null) {
      toast.error("Please fill wealth source field");
      return;
    }
    if (taxData.income_source == "" || taxData.income_source == null) {
      toast.error("Please fill income source field");
      return;
    }
    if (taxData.tax_resistance_country == "" || taxData.tax_resistance_country == null) {
      toast.error("Please fill country of tax residence field");
      return;
    }
    if (taxData.politically_expose == "" || taxData.politically_expose == null) {
      toast.error("Please fill politically exposed field");
      return;
    }
    setIsLoading(true)

    const dataString = {
      tstatus: taxData.tstatus,
      occode: taxData.occode,
      kyctype: taxData.kyctype,
      ckycno: taxData.ckycno,
      income_source: taxData.income_source,
      wealth_source: taxData.wealth_source,
      politically_expose: taxData.politically_expose,
      tax_resistance_country: taxData.tax_resistance_country
    };
    apiServices.bseuserregisterUrlPostRequest(dataString).then((result) => {
      if (result.data.status === 'success') {
        setIsLoading(false)
     
          navigate('/bseonboarding/step2')
          // window.location.href = "/bseonboarding/step2";
        
      } else if (result.data.status === "error" && result.data.message === "Session expired") {
        setIsLoading(false)
        navigate("/");
        setIsLoading(false)
        localStorage.removeItem('AUTH_TOKEN');
      } else {
        toast(result.data.message, {
          position: toast.POSITION.TOP_CENTER
        });
        setIsLoading(false)
      }
    })
      .catch(error => {
      })

  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setTaxData({
      ...taxData,
      [name]: value,
    });
  };
  return (
    <>
     <div className={spinnerLoading == true ? 'parentDisable' : ''} width="100%">
          <div className='overlay-box'>
            <TailSpin
              color="#ffffff"
              visible={spinnerLoading}
            />
          </div>
          </div>
     
      <BrowserView>
        <div className="auth-page-wrapper pt-50">
          <div className="auth-page-content">
            <div className="container">

              <div className="row justify-content-center mt-3">
                <div className="col-lg-6">
                  <div className="crds">
                    <CustomProgressBar percent={0} />
                    <div className="crds-body p-50">
                      <div className="text-center mb-30">
                        <img src="/img/logo.png" alt="img" className="wd-150 mb-4"></img>
                        <p>Step 1 of 7</p>
                        <h5>Client Master Maintenance</h5>
                        <p className="tx-gray">Client Details</p>
                      </div>
                      <div className="row">
                        {/* <div className="col-lg-12">
                          <div className="from-group mb-20">
                            <label>Tax Status<span className="tx-red">*</span></label>
                            <select
                              name="tstatus"  
                              disabled   
                              onChange={handleInputChange}
                              className="custom-select"
                              
                            >
                              <option selected={taxData.tstatus == "01" ? true : false} value="01">Individual</option>
                              <option selected={taxData.tstatus == "21" ? true : false} value="21">NRE</option>
                              <option selected={taxData.tstatus == "24" ? true : false} value="24">NRO</option>
                              <option selected={taxData.tstatus == "02" ? true : false} value="02">On behalf of minor </option>
                             
                            </select>
                          </div>

                        </div> */}

                        <div className="col-lg-12">
                          <div className="from-group mb-20">
                            <label>Occupation Code<span className="tx-red">*</span></label>
                            <select
                              name="occode"
                              onChange={handleInputChange}
                              className="custom-select"
                              
                            >
                              {occupationData.map((value) => (
                                <option selected={value.op_code == taxData.occode ? true : false} value={value.op_code}>{value.op_name}</option>))}
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="from-group mb-20">
                            <label>Wealth Source
                              <span className="tx-red">*</span></label>
                            <select
                              name="wealth_source"

                              onChange={handleInputChange}
                              className="custom-select"
                            >
                              <option value="">Select</option>
                              {Wealthdata.map((value) => (
                                <option selected={value.sow_code == taxData.wealth_source ? true : false} value={value.sow_code}>{value.sow_name}</option>))}
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="from-group mb-20">
                            <label>Income Source
                              <span className="tx-red">*</span></label>
                            <select
                              name="income_source"

                              onChange={handleInputChange}
                              className="custom-select"
                            >
                              <option value="">Select</option>
                              {Incomedata.map((value) => (
                                <option selected={value.is_code == taxData.income_source ? true : false} value={value.is_code}>{value.is_name}</option>))}
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="from-group mb-20">
                            <label>Politically Exposed
                              <span className="tx-red">*</span></label>
                            <select
                              name="politically_expose"
                              value={taxData?.politically_expose}
                              onChange={handleInputChange}
                              className="custom-select"
                            >
                              <option value="">Select</option>
                              <option value="Y">The invester is politically exposed person</option>
                              <option value="N">The invester is not politically exposed person</option>
                              <option value="R">If The invester is a relative of the politically exposed person</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="from-group mb-20">
                            <label>Country of Tax Residence
                              <span className="tx-red">*</span></label>
                            <select
                              name="tax_resistance_country"
                              value={taxData?.tax_resistance_country}
                              onChange={handleInputChange}
                              className="custom-select"
                            >
                              <option value="">Select</option>
                              {countryData.map((value) => (
                                <option
                                  key={value.country_code}
                                  value={value.country_code}

                                  selected={value.country_code === taxData?.tax_resistance_country}
                                >
                                  {value.country_name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="from-group mb-20">
                            <label>Primary Holder KYC Type
                              <span className="tx-red">*</span></label>
                            <select
                              name="kyctype"
                              onChange={handleInputChange}
                              className="custom-select"
                            >
                              {kycTypeData.map((value) => (
                                <option selected={value.kyc_code == taxData.kyctype ? true : false} value={value.kyc_code}>{value.kyc_name}</option>))}
                            </select>
                          </div>
                        </div>
                        {taxData.kyctype === 'C' && (
                          <div className="col-lg-12">
                            <div className="from-group mb-20">
                              <label>Primary Holder CKYC Number</label>
                              <input
                                type="number"
                                name="ckycno"
                                value={taxData.ckycno}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                      <button className="btn btn-primary btn-lg btn-full mb-20 mt-10" onClick={handleNextClick} disabled={isLoading}>  {isLoading ? (
                        <img src="/img/loder01.gif" alt="img" width="60px" height="11px" />
                      ) : (
                        "Next"
                      )}</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </BrowserView>
      <MobileView>
        <header className="mheader">
          <div className="container">
            <div className="mheader-left">
              <div>
                <h6 className="mb-0 mt-1">Client Master Maintenance</h6>
                <p className="tx-12 mb-0">Step 1 of 7</p>
              </div>
            </div>
          </div>
        </header>
        <div className="mobile-main">
          <div className="mobile-form">
            <CustomProgressBar percent={0} />
            <div className="p15 pb-5 mb-5">
              <div className="row g-2">
                <div className="col-12">
                  <div className="from-group mb-2">
                    <label>Tax Status<span className="tx-red">*</span></label>
                    <select
                      name="tstatus"
                      value={taxData.tstatus}
                         readonly     
                      onChange={handleInputChange}
                      className="custom-select"
                    >
                      <option selected={taxData.tstatus == "01" ? true : false} value="01">Individual</option>
                      {/* <option selected={taxData.tstatus == "21" ? true : false} value="21">NRE</option>
                      <option selected={taxData.tstatus == "24" ? true : false} value="24">NRO</option>
                      <option selected={taxData.tstatus == "02" ? true : false} value="02">On behalf of minor </option> */}
                             
                    </select>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="from-group mb-2">
                    <label>Occupation Code<span className="tx-red">*</span></label>
                    <select
                      name="occode"
                      onChange={handleInputChange}
                      className="custom-select"
                    >
                      {occupationData.map((value) => (
                        <option selected={value.op_code == taxData.occode ? true : false} value={value.op_code}>{value.op_name}</option>
                      ))}

                    </select>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="from-group mb-3">
                    <label>Wealth Source
                      <span className="tx-red">*</span></label>
                    <select
                      name="wealth_source"

                      onChange={handleInputChange}
                      className="custom-select"
                    >
                      <option value="">Select</option>
                      {Wealthdata.map((value) => (
                        <option selected={value.sow_code == taxData.wealth_source ? true : false} value={value.sow_code}>{value.sow_name}</option>))}
                    </select>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="from-group mb-3">
                    <label>Income Source
                      <span className="tx-red">*</span></label>
                    <select
                      name="income_source"
                      onChange={handleInputChange}
                      className="custom-select"
                    >
                      <option value="">Select</option>
                      {Incomedata.map((value) => (
                        <option selected={value.is_code == taxData.income_source ? true : false} value={value.is_code}>{value.is_name}</option>))}
                    </select>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="from-group mb-3">
                    <label>Country of Tax Residence
                      <span className="tx-red">*</span></label>
                    <select
                      name="tax_resistance_country"
                      onChange={handleInputChange}
                      className="custom-select"
                    >
                      <option value="">Select</option>
                      {countryData.map((value) => (
                        <option
                          key={value.country_code}
                          value={value.country_code}
                          selected={value.country_code === taxData?.tax_resistance_country}
                        >
                          {value.country_name}
                        </option>
                      ))}
                   </select>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="from-group mb-3">
                    <label>Politically Exposed
                      <span className="tx-red">*</span></label>
                    <select
                      name="politically_expose"
                      value={taxData?.politically_expose}
                      onChange={handleInputChange}
                      className="custom-select"
                    >
                      <option value="">Select</option>
                      <option value="Y">The invester is politically exposed person</option>
                      <option value="N">The invester is not politically exposed person</option>
                      <option value="R">If The invester is a relative of the politically exposed person</option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="from-group mb-1">
                    <label>Primary Holder KYC Type
                      <span className="tx-red">*</span></label>
                    <select
                      name="kyctype"
                     onChange={handleInputChange}
                      className="custom-select"
                    >
                      {kycTypeData.map((value) => (
                        <option selected={value.kyc_code == taxData.kyctype ? true : false} value={value.kyc_code}>{value.kyc_name}</option>))}
                    </select>
                  </div>
                </div>
               {taxData.kyctype === 'C' && (
                  <div className="col-lg-12">
                    <div className="from-group mb-3">
                      <label>Primary Holder CKYC Number</label>
                      <input
                        type="text"
                        name="ckycno"
                        value={taxData.ckycno}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="footer-button">
          <button className="btn btn-primary btn-lg btn-full" disabled={isLoading} onClick={handleNextClick}>
            {isLoading ? (
              <img src="/img/loder01.gif" alt="img" width="60" height="11px" />
            ) : (
              "Next"
            )}

          </button>
        </div>
      </MobileView>
    </>
  )
}
export default BseOnboarding;