import React, { useEffect, useRef, useState } from "react";
import { ApiService } from "../../Components/Services/apiServices";
import { useNavigate } from "react-router-dom";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TailSpin } from "react-loader-spinner";
import CustomProgressBar from '../../Components/Elements/progress_bar';
import { showToastWithOkButton } from "../../Components/Elements/toastutils";
let apiServices = new ApiService();
function Bseonboardingstep3forg() {
  const navigate = useNavigate();
  const [countryData, setCountryData] = useState([]);
  const [statesData, setStatesData] = useState([]);
  const [isLoading , setIsLoading] = useState(false)
  const[spinnerLoading , setSpinnerLoading] = useState(false)
  const didMountRef = useRef(true)
  useEffect(() => {
    if (didMountRef.current) {
      toast.error = (message) => {
        showToastWithOkButton(message);
      };
      getCountryData();
      getstateData();
      setSpinnerLoading(true)
      apiServices.bsegetuserdataPostRequest({}).then(result => {
        if (result.data.status == 'success') {
          setSpinnerLoading(false)
          if(result.data.bseNRIDataRegister){
          setFormData({
            ...formData,
            address1: result.data.bseNRIDataRegister.gs_nri_address1,
            address2: result.data.bseNRIDataRegister.gs_nri_address2,
            city: result.data.bseNRIDataRegister.gs_nri_city,
            pinCode: result.data.bseNRIDataRegister.gs_nri_pincode,
            state: result.data.bseNRIDataRegister.gs_nri_state,
            country: result.data.bseNRIDataRegister.gs_nri_country,
          });
        }
        } else if (result.data.status == 'error' && result.data.message == 'Session expired') {
          navigate("/");
          setSpinnerLoading(false)
        }
      })
    }
    didMountRef.current = false;
  }, []);
  const handleNextClick = () => {
    console.log(formData,'formData')
    if (
      !formData.address1 ||
      !formData.state ||
      !formData.country ||
      !formData.pinCode ||
      !formData.city
    ) {
      if (!formData.address1) {
        toast.error('Please enter your address.');
      } else if (!formData.city) {
        toast.error('Please enter your city.');
      } else if (!formData.pinCode) {
        toast.error('Please enter your pincode.');
      }
      else if (!formData.state) {
        toast.error('Please enter your state.');
      }
      else if (!formData.country) {
        toast.error('Please enter your country.');
      }
      return;
    }
    const dataString = {
      addr1: formData.address1,
      addr2: formData.address2,
      city: formData.city,
      pin: formData.pinCode,
      state: formData.state,
      country: formData.country,
    };
    apiServices.bsenriuserPostRequest(dataString).then((result) => {
      if (result.data.status === 'success') {
        navigate('/bseonboarding/step3cont');
      } else if (result.data.status === "error" && result.data.message === "Session expired") {
        navigate("/");
        localStorage.removeItem('AUTH_TOKEN');
      }
    })
      .catch(error => {
        console.error("API Request Error:", error);
      })

  };
  const getCountryData = (e) => {
    apiServices.getbsecountryGetRequest().then(result => {
      if (result.data.status == "success") {
        setCountryData(result.data.data);
      }
    })
  }
  const getstateData = (e) => {
    apiServices.getbsestatesGetRequest().then(result => {

      if (result.data.status == "success") {
        setStatesData(result.data.data);
      }
    })
  }
  const handleBackClick = () => {
    navigate("/bseonboarding/step2");
  };
  const [formData, setFormData] = useState({
    address1: '',
    address2: '',
    city: '',
    pinCode: '',
    state: '',
    country: '',
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
  };
  return (
    <>
  <div className={spinnerLoading == true ? 'parentDisable' : ''} width="100%">
          <div className='overlay-box'>
            <TailSpin
              color="#ffffff"
              visible={spinnerLoading}
            />
          </div>
          </div>
      <BrowserView>

       

        <div className="auth-page-wrapper pt-50">
          <div className="auth-page-content">
            <div className="container">

              <div className="row justify-content-center mt-3">
                <div className="col-lg-6">
                  <div className="crds">
                    <CustomProgressBar percent={28} />
                    <div className="crds-body p-50">
                      <div className="text-center mb-30">
                        <img src="/img/logo.png" className="wd-150 mb-4"></img>
                        <p>Step 3 of 7</p>
                        <h5>Client Master Maintenance</h5>
                        <p className="tx-gray">Communication Details (Foreign)</p>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="from-group mb-20">
                          <label>Address 1<span className="tx-red">*</span></label>
                    <input
                      type="text"
                      name="address1"
                      value={formData.address1}
                      onChange={handleInputChange}
                    />
                          </div>

                        </div>

                        <div className="col-lg-6">
                          <div className="from-group mb-20">
                          <label>Address 2</label>
                    <input
                      type="text"
                      name="address2"
                      value={formData.address2}
                      onChange={handleInputChange}
                    />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="from-group mb-20">
                          <label>City<span className="tx-red">*</span></label>
                    <input
                      type="text"
                      name="city"
                      value={formData.city}
                      onChange={handleInputChange}
                    />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="from-group mb-20">
                          <label>Pin Code<span className="tx-red">*</span></label>
                    <input
                      type="number"
                      name="pinCode"
                      value={formData.pinCode}
                      onChange={handleInputChange}
                    />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="from-group mb-20">
                          <label>State<span className="tx-red">*</span></label>
                    <input
                      name="state"
                      value={formData.state}
                      onChange={handleInputChange}
                    />
                          </div>
                        </div>
                      
                        <div className="col-lg-6">
                          <div className="from-group mb-20">
                          <label>Country<span className="tx-red">*</span></label>
                    <select name="country" value={formData.country}    className="custom-select"  onChange={handleInputChange}>
                      {countryData.map((value) => (
                        <option
                          key={value.country_code}
                          value={value.country_code}
                          selected={value.country_code === ""}
                        >
                          {value.country_name}
                        </option>
                      ))}
                    </select>
                          </div>
                        </div>
                       
                      </div>
                      <button className="btn btn-primary btn-lg btn-full mb-20 mt-10" onClick={handleNextClick} disabled={isLoading}>  {isLoading ? (
                        <img src="/img/loder01.gif" width="60px" height="11px" />
                      ) : (
                        "Next"
                      )}</button>
                      <button className="btn btn-outline-primary btn-lg btn-full mt-3" onClick={handleBackClick} >Back</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </BrowserView>
      <MobileView>
      <ToastContainer position={toast.POSITION.BOTTOM_CENTER} />
      <header className="mheader">
          <div className="container">
            <div className="mheader-left">
            <div className="dflex align-items-center" onClick={handleBackClick}>
            <i class="icon-arrow-left8"></i>
            <div className="ms-3">
            <h6 className="mb-0">Communication Details (Foreign)</h6>
            <p className="tx-12 mb-0 line18">Step 3 of 7</p>
            </div>
            </div>
            </div>
          </div>
      </header>
     <div className="mobile-main">
     <div className="mobile-form">
          <CustomProgressBar percent={50} />
          <div className="p15 mt-3 mb-5 pb-5">
            <div className="row g-2">
              <div className="col-lg-6">
                <div className="from-group mb-3">
                  <label>Address 1<span className="tx-red">*</span></label>
                  <input
                    type="text"
                    name="address1"
                    value={formData.address1}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="from-group mb-3">
                  <label>Address 2</label>
                  <input
                    type="text"
                    name="address2"
                    value={formData.address2}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>
            <div className="row g-2">
              <div className="col-lg-6">
                <div className="from-group mb-3">
                  <label>City<span className="tx-red">*</span></label>
                  <input
                    type="text"
                    name="city"
                    value={formData.city}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="from-group mb-3">
                  <label>Pin Code<span className="tx-red">*</span></label>
                  <input
                    type="number"
                    name="pinCode"
                    value={formData.pinCode}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>
            <div className="row g-2">
              <div className="col-lg-6">
                <div className="from-group mb-3">
                  <label>State<span className="tx-red">*</span></label>
                  <input
                    name="state"
                    value={formData.state}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="from-group mb-3">
                  <label>Country<span className="tx-red">*</span></label>
                  <select name="country" value={formData.country}    className="custom-select" onChange={handleInputChange}>
                    {countryData.map((value) => (
                      <option
                        key={value.country_code}
                        value={value.country_code}
                        selected={value.country_code === ""}
                      >
                        {value.country_name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          
          </div>
        </div>

     </div>
     <div className="footer-button">
             
                <button className="btn btn-primary btn-lg btn-full " onClick={handleNextClick}>
                  Next
                </button>
              
    </div>
      </MobileView>
    </>

  )
}
export default Bseonboardingstep3forg
