import { createContext, useContext, useState } from "react";

// Create context
const ModalContext = createContext();

// Provider component
export const ModalProvider = ({ children }) => {
  const [showSmallCaseTermsModal, setShowSmallCaseTermsModal] = useState(false);
  const [showSmallCaseCondition, setShowSmallCaseCondition] = useState(false);

  return (
    <ModalContext.Provider value={{ showSmallCaseTermsModal, setShowSmallCaseTermsModal, showSmallCaseCondition, setShowSmallCaseCondition }}>
      {children}
    </ModalContext.Provider>
  );
};

// Custom hook for using the context
export const useModal = () => useContext(ModalContext);
