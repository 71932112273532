import React, { useEffect, useRef, useState } from "react";
import CustomProgressBar from "../../Components/Elements/progress_bar";
import { ApiService } from "../../Components/Services/apiServices";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { BrowserView, MobileView } from "react-device-detect";
import { TailSpin } from "react-loader-spinner";
import { showToastWithOkButton } from "../../Components/Elements/toastutils";
let apiServices = new ApiService();
function Bseonboardingstep4() {
  const navigate = useNavigate();
  const [nomineeselect, setnomineeselect] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const [spinnerLoading, setSpinnerLoading] = useState(false)
  const [nomineeDetails, setNomineeDetails] = useState(
    {
      nomineeName: "",
      nomineeRelationship: "",
      nomineeApplicable: "",
      nomineeSelected: "N",
      nomineeOption: "Y",
    },
  );
  const [nomineeDetails1, setNomineeDetails1] = useState(
    {
      nomineeName1: "",
      nomineeRelationship1: "",
      nomineeApplicable1: "",
      nomineeSelected1: "N",
      nomineeOption1: "Y",
    },
  );
  const [nomineeDetails2, setNomineeDetails2] = useState(
    {
      nomineeName2: "",
      nomineeRelationship2: "",
      nomineeApplicable2: "",
      nomineeSelected2: "N",
      nomineeOption2: "Y",
    },
  );
  const [nomineeOption, setNomineeOption] = useState("Y")
  const [nomineeFirst, setNomineeFirst] = useState(true)
  const [nomineeSecond, setNomineeSecond] = useState(false)
  const [nomineeThird, setNomineeThird] = useState(false)
  const didMountRef = useRef(true);
  useEffect(() => {
    if (didMountRef.current) {
      toast.error = (message) => {
        showToastWithOkButton(message);
      };
      setSpinnerLoading(true)
      const dataString = {
      };
      apiServices.bsegetuserdataPostRequest(dataString).then((result) => {
        if (result.data.status === "success") {
          setSpinnerLoading(false)
          if (result.data.bse_nominee_data[0]) {
            setNomineeDetails({ ...nomineeDetails, nomineeName: result.data.bse_nominee_data[0].und_nominee_name, nomineeRelationship: result.data.bse_nominee_data[0].und_nominee_relationship, nomineeApplicable: result.data.bse_nominee_data[0].und_nominee_applicable })
          }
          if (result.data.bse_nominee_data[1]) {
            setNomineeDetails1({ ...nomineeDetails1, nomineeName1: result.data.bse_nominee_data[1].und_nominee_name, nomineeRelationship1: result.data.bse_nominee_data[1].und_nominee_relationship, nomineeApplicable1: result.data.bse_nominee_data[1].und_nominee_applicable })
            setNomineeSecond(true)
          }
          if (result.data.bse_nominee_data[2]) {
            setNomineeDetails2({ ...nomineeDetails2, nomineeName2: result.data.bse_nominee_data[2].und_nominee_name, nomineeRelationship2: result.data.bse_nominee_data[2].und_nominee_relationship, nomineeApplicable2: result.data.bse_nominee_data[2].und_nominee_applicable })
            setNomineeThird(true)
          }
          if (result.data.bse_register_data?.bur_nominee_opt == "N") {
            setNomineeOption(result.data.bse_register_data?.bur_nominee_opt)
            setNomineeSecond(false)
            setNomineeThird(false)
            setNomineeFirst(false)
          }
        }
        else if (result.data.status == "error" && result.data.message == "Session expired") {
          navigate('/')
          localStorage.removeItem('AUTH_TOKEN');
          setSpinnerLoading(false)
        }
      });

      apiServices.nomineerelationshipgetrequest().then((result)=>{
        if(result.data.status == "success"){
setnomineeselect(result.data.data)
        }
        else{

        }
      })
    }
    didMountRef.current = false;
  }, []);
  const handleBackClick = () => {
    navigate("/bseonboarding/step3cont");
  };
  const handleSubmit = (e) => {
    if (!nomineeDetails.nomineeName && nomineeOption == "Y") {
      toast.error('Please enter the Nominee Name 1 ');
      return;
    }
    if (!nomineeDetails.nomineeRelationship && nomineeOption == "Y") {
      toast.error('Please enter the Nominee Relation 1');
      return;
    }
    if (!nomineeDetails.nomineeApplicable && nomineeOption == "Y") {
      toast.error('Please enter the Nominee Applicable 1');
      return
    }
    if (nomineeDetails.nomineeApplicable && !nomineeDetails1.nomineeApplicable1 && !nomineeDetails2.nomineeApplicable2 && nomineeOption == "Y") {
      let nomenumber = Number(nomineeDetails.nomineeApplicable)
      if (nomenumber < 100 || nomenumber > 100) {
        toast.error(' Total Nominee Applicable percent ratio should be 100');
        return
      }
    }
    if (nomineeDetails1.nomineeName1) {
      if (!nomineeDetails1.nomineeName1 && nomineeOption == "Y") {
        toast.error('Please enter the Nominee Name 2 ');
      }
      if (!nomineeDetails1.nomineeRelationship1 && nomineeOption == "Y") {
        toast.error('Please enter the Nominee Relation 2');
        return
      }
      if (!nomineeDetails1.nomineeApplicable1 && nomineeOption == "Y") {
        toast.error('Please enter the Nominee Applicable 2');
        return
      }
      if (nomineeDetails1.nomineeApplicable1 && nomineeDetails.nomineeApplicable && !nomineeDetails2.nomineeApplicable2 && nomineeOption == "Y") {
        if (Number(nomineeDetails.nomineeApplicable) + Number(nomineeDetails1.nomineeApplicable1) < 100 || Number(nomineeDetails.nomineeApplicable) + Number(nomineeDetails1.nomineeApplicable1) > 100) {
          toast.error('Total Nominee Applicable percent ratio should be 100');
          return
        }
      }
      if (nomineeDetails2.nomineeName2 && nomineeOption == "Y") {
        if (!nomineeDetails2.nomineeName2 && nomineeOption == "Y") {
          toast.error('Please enter the Nominee Name 3 ');
          return
        }
        if (!nomineeDetails2.nomineeRelationship2 && nomineeOption == "Y") {
          toast.error('Please enter the Nominee Relation 3');
          return
        }
        if (!nomineeDetails2.nomineeApplicable2 && nomineeOption == "Y") {
          toast.error('Please enter the Nominee Applicable 3');
          return
        }
        if (nomineeDetails2.nomineeApplicable2 && nomineeDetails1.nomineeApplicable1 && nomineeDetails.nomineeApplicable && nomineeOption == "Y") {
          if (Number(nomineeDetails.nomineeApplicable) + Number(nomineeDetails1.nomineeApplicable1) + Number(nomineeDetails2.nomineeApplicable2) < 100 || Number(nomineeDetails.nomineeApplicable) + Number(nomineeDetails1.nomineeApplicable1) + Number(nomineeDetails2.nomineeApplicable2) > 100) {
            toast.error('Total Nominee Applicable percent ratio should be 100');
            return
          }
        }

      }
    }
    setIsLoading(true)
    const dataString = {
      nomineeOpt: nomineeOption,
      nomineeDetails: nomineeDetails,
      nomineeDetails1: nomineeDetails1,
      nomineeDetails2: nomineeDetails2
    };
    apiServices.bseusernomineePostRequest(dataString).then((result) => {
      if (result.data.status === "success") {
        setIsLoading(false)
        if (nomineeOption == 'N') {
          navigate("/bseonboarding/step4/upload_signature")
        }
        else {
          navigate("/bseonboarding/nomineeauth");
        }
      } else if (
        result.data.status === "error" &&
        result.data.message === "Session expired"
      ) {
        setIsLoading(false)
        navigate("/");
        localStorage.removeItem("AUTH_TOKEN");
      } else {
        setIsLoading(false)
        toast.error(result.data.message);
      }
    })
      .catch((error) => {
        console.error("API Error:", error);
      });
  };
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    setNomineeDetails({ ...nomineeDetails, [name]: value });
  };
  const handleInputChange1 = (e, index) => {
    const { name, value } = e.target;
    setNomineeDetails1({ ...nomineeDetails1, [name]: value });
  };
  const handleInputChange2 = (e, index) => {
    const { name, value } = e.target;
    setNomineeDetails2({ ...nomineeDetails2, [name]: value });
  };
  const showSecondNominee = () => {
    setNomineeSecond(true)
  }
  const showThirdNominee = () => {
    setNomineeThird(true)
  }
  const clickOnCloseSecondButton = () => {
    setNomineeSecond(false)
    setNomineeDetails1({ ...nomineeDetails1, nomineeName1: "", nomineeRelationship1: "", nomineeApplicable: "" })
  }
  const clickOnCloseThirdButton = () => {
    setNomineeThird(false)
    setNomineeDetails2({ ...nomineeDetails, nomineeName: "", nomineeRelationship: "", nomineeApplicable: "" })
  }
  console.log(nomineeOption, nomineeFirst, "nomineedetailsss")
  return (
    <>
     <div className={spinnerLoading == true ? 'parentDisable' : ''} width="100%">
          <div className='overlay-box'>
            <TailSpin
              color="#ffffff"
              visible={spinnerLoading}
            />
          </div>
          </div>
      <BrowserView>
        <div className="auth-page-wrapper pt-50">
          <div className="auth-page-content">
            <div className="container">
              <div className="row justify-content-center mt-3">
                <div className="col-lg-6">
                  <div className="crds">
                    <CustomProgressBar percent={70} />
                    <div className="crds-body p-50">
                      <div className="text-center mb-30">
                        <img src="/img/logo.png" className="wd-150 mb-4"></img>
                        <p>Step 5 of 7</p>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="from-group mb-20">
                            <label>Nominee Opt</label>
                            <select
                              name="nomineeOption"
                              className="custom-select"
                              id="nomineeSelected"
                              onChange={(e) => { setNomineeOption(e.target.value); e.target.value == "N" ? setNomineeFirst(false) : setNomineeFirst(true) }}
                            >
                              <option selected={nomineeOption == 'Y' ? true : false} value="Y" >Yes</option>
                              <option selected={nomineeOption == 'N' ? true : false} value="N" >No</option>
                            </select>
                          </div>
                        </div>
                        {nomineeFirst == true ?
                          <div>
                            {nomineeOption === "Y" ? (
                              <>
                                <div className="mb-2">
                                  <div className="row">
                                    <div className="col-lg-6">
                                      <div className="from-group mb-20">
                                        <label>Nominee Name<span className="tx-red">*</span></label>
                                        <input
                                          type="text"
                                          name="nomineeName"
                                          id="nomineeName"
                                          defaultValue={nomineeDetails.nomineeName}
                                          onChange={(e) => handleInputChange(e)}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-lg-6">
                                      <div className="from-group mb-20">
                                        <label>Nominee Relationship<span className="tx-red">*</span></label>
                                        {/* <input
                                          type="text"
                                          name="nomineeRelationship"
                                          id="nomineeRelationship"
                                          defaultValue={nomineeDetails.nomineeRelationship}
                                          onChange={(e) => handleInputChange(e)}
                                        /> */}

<select  value={nomineeDetails.nomineeRelationship}    className="custom-select" name="nomineeRelationship"  onChange={(e) => handleInputChange(e)}>
  <option value="">Select Nominee Relation</option>
                      {nomineeselect.map((value) => (
                        <option
                          key={value.nr_bse_code}
                          value={value.nr_bse_code}
                          selected={value.nr_bse_code === ""}
                        >
                          {value.nr_name}
                        </option>
                      ))}
                    </select>
                                      </div>
                                    </div>
                                    <div className="col-lg-12">
                                      <div className="from-group mb-20">
                                        <label>Nominee % Applicable<span className="tx-red">*</span></label>
                                        <input
                                          type="number"
                                          name="nomineeApplicable"
                                          id="nomineeApplicable"
                                          defaultValue={nomineeDetails.nomineeApplicable}
                                          onChange={(e) => handleInputChange(e)}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {nomineeSecond !== true ?
                                  <button
                                    className="btn btn-secondary btn-lg btn-full mb-4"
                                    onClick={showSecondNominee}
                                  >
                                    Add More
                                  </button> : ""}
                              </>
                            ) : null}
                          </div>
                          : ""}
                        {nomineeSecond == true ?
                          <div>
                            {nomineeOption === "Y" ? (
                              <>
                                <div className="mb-2">
                                  <hr></hr>
                                  <div className="d-flex align-items-center justify-content-between">
                                    <h6 className="mb-0">
                                      Nominee Details 02
                                    </h6>
                                    {nomineeThird == false ?
                                      <span ><i className="icon-cross" onClick={() => { clickOnCloseSecondButton() }}></i></span> : ""}
                                  </div>
                                  <hr></hr>
                                  <div className="row">
                                    <div className="col-lg-6">
                                      <div className="from-group mb-20">
                                        <label>Nominee Name<span className="tx-red">*</span></label>
                                        <input
                                          type="text"
                                          name="nomineeName1"
                                          id="nomineeName"
                                          defaultValue={nomineeDetails1.nomineeName1}
                                          onChange={(e) => handleInputChange1(e)}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-lg-6">
                                      <div className="from-group mb-20">
                                        <label>Nominee Relationship<span className="tx-red">*</span></label>
                                        {/* <input
                                          type="text"
                                          name="nomineeRelationship1"
                                          id="nomineeRelationship"
                                          defaultValue={nomineeDetails1.nomineeRelationship1}
                                          onChange={(e) => handleInputChange1(e)}
                                        /> */}
                                        <select  value={nomineeDetails1.nomineeRelationship1}    className="custom-select" name="nomineeRelationship1"  onChange={(e) => handleInputChange1(e)}>
  <option value="">Select Nominee Relation</option>
                      {nomineeselect.map((value) => (
                        <option
                          key={value.nr_bse_code}
                          value={value.nr_bse_code}
                          selected={value.nr_bse_code === ""}
                        >
                          {value.nr_name}
                        </option>
                      ))}
                    </select>
                                      </div>
                                    </div>
                                    <div className="col-lg-12">
                                      <div className="from-group mb-20">
                                        <label>Nominee % Applicable<span className="tx-red">*</span></label>
                                        <input
                                          type="number"
                                          name="nomineeApplicable1"
                                          id="nomineeApplicable"
                                          defaultValue={nomineeDetails1.nomineeApplicable1}
                                          onChange={(e) => handleInputChange1(e)}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {nomineeThird !== true ?
                                  <button
                                    className="btn btn-secondary btn-lg btn-full mb-4"
                                    onClick={showThirdNominee}
                                  >
                                    Add More
                                  </button> : ""}
                              </>

                            ) : ""}
                          </div> : ""}
                        {nomineeThird == true ?
                          <div>
                            {nomineeOption === "Y" ? (
                              <>
                                <div className="mb-2">
                                  <hr></hr>
                                  <div className="d-flex align-items-center justify-content-between">
                                    <h6 className="mb-0">
                                      Nominee Details 03
                                    </h6>
                                    <span ><i className="icon-cross" onClick={() => { clickOnCloseThirdButton() }}></i></span>
                                  </div>
                                  <hr></hr>
                                  <div className="row">
                                    <div className="col-lg-6">
                                      <div className="from-group mb-3">
                                        <label>Nominee Name<span className="tx-red">*</span></label>
                                        <input
                                          type="text"
                                          name="nomineeName2"
                                          id="nomineeName"
                                          defaultValue={nomineeDetails2.nomineeName2}
                                          onChange={(e) => handleInputChange2(e)}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-lg-6">
                                      <div className="from-group mb-3">
                                        <label>Nominee Relationship<span className="tx-red">*</span></label>
                                        {/* <input
                                          type="text"
                                          name="nomineeRelationship2"
                                          id="nomineeRelationship"
                                          defaultValue={nomineeDetails2.nomineeRelationship2}
                                          onChange={(e) => handleInputChange2(e)}
                                        /> */}
                                          <select  value={nomineeDetails2.nomineeRelationship2}    className="custom-select" name="nomineeRelationship2"  onChange={(e) => handleInputChange2(e)}>
  <option value="">Select Nominee Relation</option>
                      {nomineeselect.map((value) => (
                        <option
                          key={value.nr_bse_code}
                          value={value.nr_bse_code}
                          selected={value.nr_bse_code === ""}
                        >
                          {value.nr_name}
                        </option>
                      ))}
                    </select>
                                      </div>
                                    </div>
                                    <div className="col-lg-12">
                                      <div className="from-group mb-3">
                                        <label>Nominee % Applicable<span className="tx-red">*</span></label>
                                        <input
                                          type="number"
                                          name="nomineeApplicable2"
                                          id="nomineeApplicable"
                                          defaultValue={nomineeDetails2.nomineeApplicable2}
                                          onChange={(e) => handleInputChange2(e)}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>

                            ) : ""}
                          </div> : ""}
                      </div>
                      <button
                        className="btn btn-primary btn-lg btn-full mt-1"
                        onClick={handleSubmit}
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <img src="/img/loder01.gif" width="60px" height="11px" />
                        ) : (
                          "Continue"
                        )}

                      </button>
                      <button className="btn btn-outline-primary btn-lg btn-full mt-3" onClick={handleBackClick} >Back</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </BrowserView>
      <MobileView>
        <header className="mheader">
          <div className="container">
            <div className="mheader-left">
              <div className="dflex align-items-center" onClick={handleBackClick}>
                <i class="icon-arrow-left8"></i>
                <div className="ms-3">
                  <h6 className="mb-0">Nominee Details (India)</h6>
                  <p className="tx-12 mb-0 line18">Step 5 of 7</p>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="mobile-main">
          <div className="mobile-form">
            <CustomProgressBar percent={70} />
            <div className="p15 pb-5 mb-5">
              <div className="row g-2">
                <div className="col-lg-12">
                  <div className="from-group mb-2">
                    <label>Nominee Opt</label>
                    <select
                      name={"nomineeOption"}
                      value={nomineeOption}
                      className="custom-select"
                      id="nomineeSelected"
                      onChange={(e) => { setNomineeOption(e.target.value); e.target.value == "N" ? setNomineeFirst(false) : setNomineeFirst(true) }}
                    >
                      <option value="Y">Yes</option>
                      <option value="N">No</option>
                    </select>
                  </div>
                </div>
              </div>
              {nomineeFirst == true ?
                <div>
                  {nomineeOption === "Y" ? (
                    <div className="mb-2">
                      <div className="row g-2">
                        <div className="col-lg-6">
                          <div className="from-group mb-2">
                            <label>Nominee Name<span className="tx-red">*</span></label>
                            <input
                              type="text"
                              name={"nomineeName"}
                              id="nomineeName"
                              value={nomineeDetails.nomineeName}
                              onChange={(e) => handleInputChange(e)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="from-group mb-2">
                            <label>Nominee Relationship<span className="tx-red">*</span></label>
                          
                            
<select  value={nomineeDetails.nomineeRelationship}    className="custom-select" name="nomineeRelationship"  onChange={(e) => handleInputChange(e)}>
  <option value="">Select Nominee Relation</option>
                      {nomineeselect.map((value) => (
                        <option
                          key={value.nr_bse_code}
                          value={value.nr_bse_code}
                          selected={value.nr_bse_code === ""}
                        >
                          {value.nr_name}
                        </option>
                      ))}
                    </select>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="from-group mb-3">
                            <label>Nominee % Applicable<span className="tx-red">*</span></label>
                            <input
                              type="number"
                              name="nomineeApplicable"
                              id="nomineeApplicable"
                              value={nomineeDetails.nomineeApplicable}
                              onChange={(e) => handleInputChange(e)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {nomineeSecond !== true ?
                    <div className="dflex align-items-center tx-theme" onClick={showSecondNominee}><i className="icon-plus2 me-2"></i>Add More Nominee</div> : ""}
                </div>

                : ""}
              {nomineeSecond == true ?
                <div>
                  {nomineeOption === "Y" ? (
                    <div className="mb-2">
                      <hr></hr>
                      <div className="d-flex align-items-center justify-content-between">
                        <h6>
                          Nominee Details 02
                        </h6>
                        {nomineeThird == false ?
                          <span ><i className="icon-cross" onClick={() => { clickOnCloseSecondButton() }}></i></span> : ""}
                      </div>
                      <hr></hr>
                      <div className="row g-2">
                        <div className="col-lg-6">
                          <div className="from-group mb-2">
                            <label>Nominee Name<span className="tx-red">*</span></label>
                            <input
                              type="text"
                              name="nomineeName1"
                              id="nomineeName"
                              defaultValue={nomineeDetails1.nomineeName1}
                              onChange={(e) => handleInputChange1(e)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="from-group mb-2">
                            <label>Nominee Relationship<span className="tx-red">*</span></label>
                            <input
                              type="text"
                              name="nomineeRelationship1"
                              id="nomineeRelationship"
                              defaultValue={nomineeDetails1.nomineeRelationship1}
                              onChange={(e) => handleInputChange1(e)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="from-group mb-3">
                            <label>Nominee % Applicable<span className="tx-red">*</span></label>
                            <input
                              type="number"
                              name="nomineeApplicable1"
                              id="nomineeApplicable"
                              defaultValue={nomineeDetails1.nomineeApplicable1}
                              onChange={(e) => handleInputChange1(e)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {nomineeThird !== true ?
                    <div className="dflex align-items-center tx-theme" onClick={showThirdNominee}><i className="icon-plus2 me-2"></i>Add More Nominee</div> : ""}
                </div> : ""}
              {nomineeThird == true ?
                <div>
                  {nomineeOption === "Y" ? (
                    <div className="mb-2">
                      <hr></hr>
                      <div className="d-flex align-items-center justify-content-between">
                        <h6>
                          Nominee Details 3
                        </h6>
                        <span ><i className="icon-cross" onClick={() => { clickOnCloseThirdButton() }}></i></span>
                      </div>
                      <hr></hr>
                      <div className="row g-2">
                        <div className="col-lg-6">
                          <div className="from-group mb-2">
                            <label>Nominee Name<span className="tx-red">*</span></label>
                            <input
                              type="text"
                              name="nomineeName2"
                              id="nomineeName"
                              defaultValue={nomineeDetails2.nomineeName2}
                              onChange={(e) => handleInputChange2(e)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="from-group mb-2">
                            <label>Nominee Relationship<span className="tx-red">*</span></label>
                            <input
                              type="text"
                              name="nomineeRelationship2"
                              id="nomineeRelationship"
                              defaultValue={nomineeDetails2.nomineeRelationship2}
                              onChange={(e) => handleInputChange2(e)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="from-group mb-3">
                            <label>Nominee % Applicable<span className="tx-red">*</span></label>
                            <input
                              type="number"
                              name="nomineeApplicable2"
                              id="nomineeApplicable"
                              defaultValue={nomineeDetails2.nomineeApplicable2}
                              onChange={(e) => handleInputChange2(e)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div> : ""}
            </div>
          </div>
        </div>
        <div className="footer-button">
          <button
            className="btn btn-primary btn-lg btn-full "
            onClick={handleSubmit}
            disabled={isLoading}
          >
            {isLoading ? (
              <img src="/img/loder01.gif" width="60px" height="11px" />
            ) : (
              "Continue"
            )}
          </button>
        </div>
      </MobileView>
    </>
  );
}
export default Bseonboardingstep4;
