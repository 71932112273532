import React from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { useEffect, Component, useState, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import CustomProgressBar from '../../Components/Elements/progress_bar';
import { BrowserView, MobileView } from "react-device-detect";
import CustomcircularProgressBar from "../../Components/Elements/circular_process_bar";
import { showToastWithOkButton } from "../../Components/Elements/toastutils";
import { ApiService } from "../../Components/Services/apiServices";
const apiServices = new ApiService()
function Creategoals() {
  const didMountRef = useRef(true);
  let history = useNavigate();
  const [dob, setdob] = useState('');
  const [annuitydob, setAnnuityDob] = useState('');
  const [pensionage, setPensionAge] = useState('65');
  const [annuityage, setAnnuityAge] = useState('65');
  // const [userCurrentAge, setUserCurrentAge] = useState('');
  const [ageStatus, setAgeStatus] = useState(0);
  const [ageannuityStatus, setAnnuityAgeStatus] = useState(0);
  const [spinnerLoading, setSpinnerLoading] = useState()
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [n, setn] = useState();
  const [an, setan] = useState();
  const [t, setT] = useState('');
  const [at, setAt] = useState();
  const [p, setp] = useState('');
  const [ap, setap] = useState();
  const [x, setx] = useState();
  const [ax, setax] = useState();
  const [i, seti] = useState();
  const [ai, setai] = useState();
  const [r, setr] = useState();
  const [ar, setar] = useState();
  const [cn, setcn] = useState();
  const [manualInput, setManualInput] = useState(false);
  const [r3, setr3] = useState(10);
  const [la ,  setla] = useState(0);
  const [i3, seti3] = useState(r3+2);
  const [e, sete] = useState(9);
  const [ae, setae] = useState(9);
  const [q1, setq1] = useState(2);
  const [aq1, setAq1] = useState(2);
  const [s, sets] = useState();
  const [as, setas] = useState();
  const [sip, setsip] = useState();
  const [asip, setasip] = useState();
  const [sip3, setasip3] = useState();
  const [q, setq] = useState();
  const [n3, setn3] = useState();
  const [y, setY] = useState();
  const [needfor, setNeedfor] = useState('');
  const [anydetails4, setAnydetailsf4] = useState('');
  const [anydetails1, setAnydetailsf1] = useState('');
  const [anydetails2, setAnydetailsf2] = useState('');
  // const [flow3array, setFlow3Array] = useState(['2','3','4','5']);
  const [goal_type, setGoalType] = useState(0);
  const [customize_type, setCustomizeType] = useState('');
  const [activestep, setactivestep] = useState("")
  const [customize, setCustomize] = useState(0);
  const [calcerror, setCalcError] = useState(0);
  const [annuitycalcerror, setAnnuityCalcError] = useState(0);
  const [calcerrorflow3, setcalcerrorflow3] = useState(0);
  const [q1msg, setq1msg] = useState(0);
  const [aq1msg, setAq1msg] = useState(0);
  const [sipsalaryerror, setSipSalaryError] = useState(0);
  const [sipannuitysalaryerror, setAnnuitySipSalaryError] = useState(0);
  const [portfolioname, setPortfolioname] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [increment, setIncrement] = useState(1);
  const [processbarflow3, setprocessbarflow3] = useState(0);
  useEffect(() => {
    if (!manualInput) {
      seti3(r3 + 2);
      console.log("hello")
    }
  }, [r3, manualInput]);

  useEffect(() => {
    if (didMountRef.current) {
      toast.error = (message) => {
        showToastWithOkButton(message);
      };
      apiServices.themeGetRequest().then(result => {
        if (result.data.status == 'success') {
          setar(eval(result.data.data.gs_default_ror));
          setr(eval(result.data.data.gs_default_ror));
          // setr3(eval(result.data.data.gs_default_ror));
          setai(eval(result.data.data.gs_default_roi));
          seti(eval(result.data.data.gs_default_roi));
          // seti3(eval(result.data.data.gs_default_roi));
        }
      })
    }
    // a.classList.remove("completed");
    // a.setAttribute("class", "");
    agehandleChange(pensionage)
    annuityagehandleChange(annuityage)

    didMountRef.current = false;
  }, [annuityage][pensionage])

  const handleR3Change = (e) => {
    setr3(parseInt(e.target.value) || 0);
    setManualInput(true);
  };

  const handleI3Change = (e) => {
    seti3(parseInt(e.target.value) || 0);
    setManualInput(true);
  };

  const dobhandleChange = (event) => {

    if (event.target.name == 'dob') {
      var GivenDate = new Date(event.target.value);
      var CurrentDate = new Date();
      if (GivenDate > CurrentDate) {
        setdob(event.target.value);
        return false;

      }
      else {
        setdob(event.target.value);

        checkAge()
      }
    }
    else {
      var GivenDate = new Date(event.target.value);
      var CurrentDate = new Date();
      if (GivenDate > CurrentDate) {
        return false;
      }
      else {
        setAnnuityDob(event.target.value);
        AnnuityAge()
      }
    }
  }
  const agehandleChange = event => {
    setPensionAge(event);
    checkAge()
  }
  const annuityagehandleChange = event => {
    setAnnuityAge(event);
    AnnuityAge()
  }
  const checkAge = () => {
    let currentAge = 0;
    if (dob != '' && (pensionage >= 45 && pensionage <= 75)) {
      currentAge = calculate_age(dob)
      if (pensionage - currentAge > 5) {
        let yearsfromnow = pensionage - currentAge;
        setn(yearsfromnow)
        setAgeStatus(1)
      } else {
        setAgeStatus(2)
      }
    }
  }
  console.log(step, "onstep")
  const changestep16 = () => {
    if (goal_type == 0) {
      setStep(6)
      return
    }
    else if (goal_type == 2 || goal_type == 4 || goal_type == 3 || goal_type == 5) {
      setStep(15)
      return
    }
    else if (goal_type == 7) {
      setStep(12)
      return
    }
  }
  const AnnuityAge = () => {
    let currentAge = 0;
    if (annuitydob != '' && (annuityage >= 45 && annuityage <= 75)) {
      currentAge = calculate_age(annuitydob)
      if (annuityage - currentAge > 5) {
        let yearsfromnow = annuityage - currentAge;
        setan(yearsfromnow)
        setAnnuityAgeStatus(1)
      } else {
        setAnnuityAgeStatus(2)
      }
    }
  }
  const calcformulax = () => {
    processstep()
    let finalcalc = formulaamountcalc1(i, p, n)
    setx(finalcalc)
  }

  const onClosebox = () => {
    history("/dashboard")
  }
  const calcformulaS = () => {
    processstep()
    let formulacalc2 = x * ((12 * 100) / (e - q1))
    sets(Math.round(formulacalc2))
    let SIP = formulaSIProundoff(r, n, formulacalc2, t, 1)
    if (SIP == 'calc_error') {
      setCalcError(1);
    }
    else if (SIP == 'sip_salary_error') {
      setSipSalaryError(1)
    }
    else {
      setSipSalaryError(0)
      setCalcError(0);
      setsip(SIP)
    }
  }
  const calcformulaAnnuityx = () => {
    processstep()
    let finalcalc = formulaamountcalc1(ai, ap, an)
    setax(finalcalc)
  }
  const calcformulaAnnuityS = () => {
    processstep()
    let formulacalc2 = ax * ((12 * 100) / ae - aq1)
    setas(Math.round(formulacalc2))
    let SIP = formulaSIProundoff(ar, an, formulacalc2, at, 2)
    if (SIP == 'calc_error') {
      setAnnuityCalcError(1);

    }
    else if (SIP == 'sip_salary_error') {
      setAnnuitySipSalaryError(1)

    }
    else {
      setAnnuitySipSalaryError(0)
      setAnnuityCalcError(0);
      setasip(SIP)

    }
  }
  const calculateYflow3 = () => {
    processstep()
    let finalcalc = formulaamountcalc1(i3, q, n3)
    setY(finalcalc)
    let SIP = formulaSIProundoff(r3, n3, finalcalc, 0, 3)
    if (SIP == 'calc_error') {
      setcalcerrorflow3(1)
    }
    else {
      setcalcerrorflow3(0)
      setasip3(SIP)
    }
  }
  const formulaamountcalc1 = (i, p, n) => {
    let formulacalc1 = (1 + (i / 1200))
    let finalcalc = Math.round(p * Math.pow(formulacalc1, 12 * n));
    return finalcalc;
  }
  const formulaSIProundoff = (r, n, amount, t, flow) => {
    let m = (r / 1200);
    let calc1 = (1 + m);
    let calc2 = Math.pow(calc1, 12 * n);
    let SIP1 = (amount * m) / (calc2 - 1)
    let SIP2 = Math.ceil(SIP1 / 500) * 500
    if (SIP2 <= 0 || SIP2 == null || SIP2 == undefined || SIP2 == '' || SIP2 == Infinity || isNaN(SIP2)) {
      return 'calc_error';
    }
    else if (SIP2 > 0.5 * t && flow != 3) {
      if (flow == 1) {
        setsip(SIP2)
      }
      if (flow == 2) {
        setasip(SIP2)
      }
      return 'sip_salary_error';
    }
    else {
      return SIP2;
    }
  }
  const restartprocess = () => {
    setStep(1);
  }
  const restartprocessflow2 = () => {
    setStep(7);
  }
  const restartprocessflow3 = () => {
    setStep(13);
  }
  console.log(goal_type, "gpa;typeeklk")
  const setportfolioname = () => {
    console.log("step16")
    var a = document.getElementById("fow_bar" + increment);
    if (a != null) {
      a.setAttribute("class", "stepper-item completed");
    }
    setStep(16);
  }
  const processstep = () => {
    console.log(pensionage, "")
    if (step == 1) {
      if (dob == null || dob == '') {
        toast.error('Date of Birth field is required .')
        return false;
      }

      let getdate = new Date().toISOString().split("T")[0];
      if (dob >= getdate) {
        toast.error('Date of Birth is not correct .')
        return false;
      }
      if (pensionage == null || pensionage == '') {
        toast.error('Age at which you need pension field is required .')
        return false;
      }
      if (!(pensionage >= 45 && pensionage <= 75)) {
        toast.error('Age doesnt seem right, keep it between 45 years and 75 years')
        return false;
      }
    }
    else {
      setErrorMessage('')
    }
    if (step == 2) {
      if (t == null || t == '') {

        toast.error('Current monthly take home salary field is required .')
        return false;
      }
      if (p == null || p == '') {
        toast.error('How much monthly pension would you need field is required .')
        return false;
      }
    }
    else {
      setErrorMessage('')
    }
    if (step == 3) {
      if (r == null || r == '') {
        toast.error('Expected rate of return for accumulating for pension fund field is required .')
        return false;
      }
      else if (!(r >= 5 && r <= 25)) {
        toast.error('Return seems too low / too high. Keep between 5% and 25%')
        return false;
      }
      if (i == null || i == '') {
        toast.error('Expected rate of inflation field is required .')
        return false;
      }
      else if (!(i >= 2 && i <= 15)) {
        toast.error('Assumption seems to low. / That assumption seems to high Keep between 2% to 15%')
        return false;
      }
    }
    else {
      setErrorMessage('')
    }
    if (step == 5) {

      if (e == null || e == '') {
        toast.error('Expected rate of return on accumulated funds field is required .')
        return false;
      }
      if (!(e >= 6 && e <= 25)) {
        toast.error('Return seems too low / too high. Keep between 6% and 25%')
        return false;
      }
      if (q1 == null || q1 == '') {
        toast.error('Your pension will increase at the rate of % every year to counter inflation is required .')
        return false;
      }
      if (q1 > e - 6) {
        setq1msg(1)
        return false;
      }
      else {
        setq1msg(0)
      }
    }
    else {
      setErrorMessage('')
    }
    if ((step == 7)) {
      if (portfolioname == null || portfolioname == '') {
        toast.error('Portfolio Name field is required .')
        return false;
      }
      if (annuitydob == null || annuitydob == '') {
        toast.error('Date of Birth field is required .')
        return false;
      }
      if (annuityage == null || annuityage == '') {
        toast.error('Age at which you need annuity field is required .')
        return false;
      }
      else if (!(annuityage >= 45 && annuityage <= 75)) {
        toast.error('Age doesnt seem right, keep it between 45 years and 75 years')
        return false;
      }
    }
    else {
      setErrorMessage('')
    }
    if ((step == 8) && (ap == null || ap == '')) {
      toast.error('How much monthly annuity would you need field is required .')
      return false;
    }
    else {
      setErrorMessage('')
    }
    if ((step == 9)) {
      if (ar == null || ar == '') {
        toast.error('Expected rate of return for accumulating for annuity fund field is required .')
        return false;
      }
      else if (!(ar >= 5 && ar <= 25)) {
        toast.error('Return seems too low / too high. Keep between 5% and 25%')
        return false;
      }
      if (ai == null || ai == '') {
        toast.error('Expected rate of inflation field is required .')
        return false;
      }
      else if (!(ai >= 2 && ai <= 15)) {
        toast.error('Assumption seems to low. / That assumption seems to high Keep between 2% to 15%')
        return false;
      }
    }
    else {
      setErrorMessage('')
    }
    if (step == 11) {
      if (ae == null || ae == '') {
        toast.error('Expected rate of return on accumulated funds field is required .')
        return false;
      }
      if (!(ae >= 6 && ae <= 25)) {
        toast.error('Return seems too low / too high. Keep between 6% and 25%')
        return false;
      }
      if (aq1 > ae - 6) {
        setAq1msg(1)
        return false;
      }
      else {
        setAq1msg(0)
      }
    }
    else {
      setErrorMessage('')
    }
    if ((step == 13)) {
      if (cn == null || cn == '') {
        toast.error('Child name field is required .')
        return false;
      }
      if (q == null || q == '') {
        toast.error('Money  field is required .')
        return false;
      }
      if (q < 10000) {
        toast.error("Whoops! Cost can't be negative or too small. Even our calculators refuse to handle negativity. Keep it on the sunny side, please, between 100,000 to 10 Crs.")
        return false;
      }
      if (q > 100000000) {
        toast.error("Hold the fort! That amount is reaching for the stars. Connect with us at invest@goalstox.com, or speak to your advisor. Planning rocket launches requires a pro. YOu can consider a cost between 100000 to 10 Crs here")
        return false;
      }
      if (n3 == null || n3 == '') {
        toast.error('Years field is required .')
        return false;
      }
      if (n3 <= 0 ) {
        toast.error("Hold your horses! Give it at least a year to plan. Pick a number between 1 and 30 – that's the magic range.")
        return false;
      }
      if (n3 > 30) {
        toast.error("Let's be realistic. Try a number between 1 and 30 for a solid plan. Marathon, not a sprint!")
        return false;
      }
    }
    else {
      setErrorMessage('')
    }
    if ((step == 14)) {
      if (r3 == null || r3 == '') {
        toast.error('Rate of return field is required .')
        return false;
      }
      if(r3 < 3){
        toast.error("That's too low! Low inflation can throw a spanner in your planning. Try a number between 3% and 22% – it's the Goldilocks zone for financial assumptions.")
        return false;
      }
      if(r3 > 22){
        toast.error("Hold your horses! That's too high. Aim for a number between 3% and 12% – the sweet spot for realistic expectations")
        return false;
      }
      if (i3 == null || i3 == '') {
        toast.error('Rate of inflation field is required .')
        return false;
      }
      if (i3 < r3+2 ) {
        toast.error(` Oops, investments can't be negative! Keep it positive, between ${r3+2} and 24 – let's keep those financial vibes positive.`);
        return false;
      }
      if (i3 > 24 ) {
        toast.error(`Hold your horses! Your investment is aiming for the moon. Try a value between ${r3+2} and 24 finding the right orbit is key to financial success`);
        return false;
      }
    }
    else {
      setErrorMessage('')
    }
    if ((step == 15)) {
      // if (la == null || la == '') {
      //   toast.error('lumpsum Amount is required .')
      //   return false;
      // }
      if(la <= 0){
        toast.error(` Oops, investments can't be negative! Keep it positive, between 0 and ${y} – let's keep those financial vibes positive.`)
          return false; 
      }
      if(la >= y){
        toast.error(`Hold your horses! Your investment is aiming for the moon. Try a value between 0 and ${y} finding the right orbit is key to financial success.`)
          return false; 
      }
    }
    else {
      setErrorMessage('')
    }
    var a = document.getElementById("fow_bar" + increment);

    if (a != null) {
      a.setAttribute("class", "stepper-item completed");
    }
    setStep(step + 1);
    setIncrement(increment + 1);
  }

  const processback = () => {

    setErrorMessage('')
    setp("")
    setIncrement(increment - 1);
    setStep(step - 1);
  }
  const skipflow = () => {
    if (goal_type == 8) {
      history("/mutual-funds/sip")
    }
    else if (goal_type == 9) {
      history("/mutual-funds/lumpsum")
    } else {
      history("/mutual-funds")
    }
  }
  console.log(dob, "date of birthlll")
  const submitgoalsettingsdata = () => {
    let flow = 0;
    let goaltype = '';
    let goaltypekeyword = ""
    let customize_type = 0;
    if (goal_type == '2' || goal_type == '3' || goal_type == '4' || goal_type == '5') {
      flow = 3;
    }
    else if (goal_type == '7') {
      if (customize_type == 13) {
        flow = 3;
      }
      else {
        flow = 2;
      }
    }
    else if (goal_type == '6') {
      flow = 0;
    }
    else {
      flow = 1;
    }
    if (goal_type == '2') {
      goaltype = 'Plan for buying a new house';
      goaltypekeyword = 'HOUSE';
    }
    else if (goal_type == '3') {
      goaltype = 'Plan for Child’s education';
      goaltypekeyword = 'EDUCATION';
    }
    else if (goal_type == '4') {
      goaltype = 'Plan for Child’s Marriage';
      goaltypekeyword = 'MARRIAGE';
    }
    else if (goal_type == '5') {
      goaltype = 'Plan for a vacation';
      goaltypekeyword = "VACATION";
    }
    else if (goal_type == '6') {
      goaltype = 'Don’t have a goal, but want to invest';
      goaltypekeyword = "NOGOAL";
    }
    else if (goal_type == '7') {
      customize_type = 1;
      if (customize_type == 13) {
        goaltype = 'Plan to accumulate (e.g for new Car)';
        goaltypekeyword = 'ACCUMULATE';
      }
      else {
        goaltype = 'Plan an annuity goal (e.g Monthly Pension)';
        goaltypekeyword = 'ANNUITY';
      }
    }
    else {
      goaltype = 'Plan for pension after retirement';
      goaltypekeyword = 'RETIREMENT'
    }
    if (portfolioname == "") {
      toast.error("Portfolio Name is Required")
      return false
    }
    

    setIsLoading(true)
    const dataString = {
      "gs_flow": flow,
      "gs_goal_type": goaltype,
      "gs_customize_goal": customize_type,
      "gs_any_details_f1": anydetails1,
      "gs_any_details_f4": anydetails4,
      "gs_any_details_f2": anydetails2,
      "gs_dob_f1": dob,
      "gs_age_f1": pensionage,
      "gs_years_f1": n,
      "gs_pension_f1": p,
      "gs_salary_f1": t,
      "gs_ror_f1": r,
      "gs_roi_f1": i,
      "gs_calculated_x_f1": x,
      "gs_ror_accumulated_funds_f1": e,
      "gs_pension_inc_rate_f1": q1,
      "gs_pension_corpus_f1": s,
      "gs_sip_f1": sip,
      "gs_dob_f2": annuitydob,
      "gs_age_f2": annuityage,
      "gs_years_f2": an,
      "gs_annuity_f2": ap,
      "gs_salary_f2": at,
      "gs_ror_f2": ar,
      "gs_roi_f2": ai,
      "gs_calculated_x_f2": ax,
      "gs_ror_accumulated_funds_f2": ae,
      "gs_pension_inc_rate_f2": aq1,
      "gs_pension_corpus_f2": as,
      "gs_sip_f2": asip,
      "gs_amount_need_for_f3": needfor,
      "gs_money_req_f3": q,
      "gs_after_years_f3": n3,
      "gs_ror_f3": r3,
      "gs_roi_f3": i3,
      "gs_calculated_y_f3": y,
      "gs_sip_f3": sip3,
      "gs_portfolio_name_f4": portfolioname,
      "gs_child_name": cn,
      "gs_lumpsum_price":la,
      "gs_goal_type_keyword": goaltypekeyword
    }
    apiServices.submitgoalsettingsdata(dataString).then(res => {
      if (res.data.status == 'success') {
        setIsLoading(false)
        history("/dashboard");
      }
      else if (res.data.status == "error" && res.data.message == "Session expired") {
        localStorage.removeItem('AUTH_TOKEN');
        // alert(res.data.message)
        setIsLoading(false)
        // setSpinnerLoading(false)
        history("/login")
      }
      else {
        alert(res.data.message)
        setIsLoading(false)
        // setSpinnerLoading(false)
        return false
      }
    })
      .catch(error => {
      })
  }
  // console.log(step,"STEPE OF STEPPER")
  const calculate_age = (dob1) => {
    var today = new Date();
    var birthDate = new Date(dob1);
    var age_now = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age_now--;
    }
    return age_now;
  }
  const customizegoal = (val) => {
    if (val == 7) {
      setGoalType(7)
      setStep(7)
      setCustomize(1);
    }
    else if (val == 6 || val == 8 || val == 9) {
      setGoalType(val);
      setStep(50);
      setCustomize(0);
    }
    else {
      setCustomize(0);
    }
  }
  // const activeprocessbar=()=>{
  //   document.getElementsByClassName()
  // }
  const maxvalue = (object) => {
    const minValue = 3; // Set your minimum value
  const maxValue = 25; // Set your maximum value

  let newValue = parseInt(object.target.value, 10); // Parse the entered value as an integer

  // Ensure the value is within the specified range
  newValue = Math.min(Math.max(newValue, minValue), maxValue);

  object.target.value = newValue.toString();
  };

  return (
    <>
      <BrowserView>
        <Header />
        <section className='sec-pad-md'>
          <div className="container mt-3">

            <div className="row g-3 justify-content-md-center">
              <div className="col-lg-8">
                <div className="goalcbox">
                  <div className="goalcboxClose" onClick={() => { onClosebox() }}><i class="icon-cross2"></i></div>
                  <div className="goalcbox-header">
                    <h4>Let’s set a goal for you</h4>
                    <p className="mb-0">Find out the future value of your Goal & your Monthly Investment to achieve it</p>
                  </div>



                  <div className="goalcbox-body">
                    {
                      step == 1 ?

                        <div id="step1" className="">
                          <CustomProgressBar percent={10} />
                          <div className='row g-3 mt-3'>
                            <div className='col-lg-12'>
                              <div className="from-group mb-1">
                                <label>Goal Type <span className="text-danger fs-5">*</span></label>
                                <select name="goal_type" className="custom-select" value={goal_type} onChange={(e) => { if (e.target.value != '6' && e.target.value != '8' && e.target.value != '9' && e.target.value != '7') { setCustomize(0); setGoalType(e.target.value); if (['2', '3', '4', '5'].includes(e.target.value)) { setStep(13); setprocessbarflow3(1); } else { setprocessbarflow3(0); setStep(eval(e.target.value)) } } else { customizegoal(e.target.value) } }}>
                                  <option value="1">Plan for pension after retirement</option>
                                  <option value="2">Plan for buying a new house</option>
                                  <option value="3">Plan for Child’s education</option>
                                  <option value="4">Plan for Child’s Marriage</option>
                                  <option value="5">Plan for a vacation</option>
                                  <option value="7">Plan my own customised Goal</option>
                                  <option value="6">Don’t have a goal, but want to invest</option>
                                  <option value="8">Start SIP</option>
                                  <option value="9">Start Lumpsum Investment</option>
                                </select>
                              </div>
                            </div>
                            {customize == 1 ?
                              <div className='col-lg-12'>
                                <div className="from-group mb-1">
                                  <label>Select Customize Goal<span className="text-danger fs-5">*</span></label>
                                  <select name="goal_type" className="custom-select" value={customize_type} onChange={(e) => { if (e.target.value != '13') { setprocessbarflow3(0) } else { setprocessbarflow3(1) } setCustomizeType(e.target.value); setGoalType('7'); setStep(eval(e.target.value)) }}>
                                    <option value="7">Plan an annuity goal (e.g Monthly Pension)</option>
                                    <option value="13">Plan to accumulate (e.g for new Car)</option>
                                  </select>
                                </div>
                              </div> : ''}
                            <div className='col-lg-6'>
                              <div className="from-group mb-3">
                                <label>Date Of Birth <span className="text-danger fs-5">*</span></label>
                                <input type="date" name="dob" value={dob} max={new Date().toISOString().split("T")[0]} onChange={dobhandleChange} className="form-control" required /> </div>
                            </div>
                            <div className='col-lg-6'>
                              <div className="from-group mb-1">
                                <label>Age at which you need pension <span className="text-danger fs-5">*</span></label>
                                <input type="number" name="pensionage" defaultValue={pensionage} onChange={(e) => { setPensionAge(e.target.value) }} className="form-control" /> </div>
                            </div>
                            {
                              ageStatus == 0 ? '' : ageStatus == 1 ? <p className="ms-1 text-dark"> 'Good, let’s estimate how much pension you need' : 'Seems you don’t have a long time horizon, let’s see if it works out well' </p> : ''
                            }
                            <div className='col-lg-12'>
                              <div className="from-group mb-1">
                                <label>Any details</label>
                                <textarea name="anydetails1" rows={6} onChange={(e) => { setAnydetailsf1(e.target.value) }} placeholder="Add any details that you would like to retain for future, like assumptions, thought process behind it or anything else that is relevant to this goal"></textarea>
                                {
                                  errorMessage != '' ?
                                    <div style={{ color: 'red' }}>{errorMessage}</div> : ''
                                }
                              </div>
                            </div>
                          </div>
                          <button type="button" onClick={processstep} className="btn btn-primary mt-4 btn-full btn-lg button01">Continue</button>
                        </div> : ''
                    }
                    {
                      step == 2 ?
                        <div id="step2" className="">
                          <CustomProgressBar percent={20} />
                          <h5 className="mt-5 mb-4 text-center">Let’s now try and estimate how much do you need</h5>
                          {/* <div className="backbutton-desktop mb-3" onClick={processback} ><i class="icon-arrow-left8 wd-20 mb-5 "></i></div> */}
                          <div className='row g-3'>
                            <div className='col-lg-12'>
                              <div className="from-group mb-1">
                                <label className="tx-gray">What is your current monthly take home salary approximately ?<span className="text-danger fs-5">*</span></label>
                                <input type="number" name="monthysal" value={t} onChange={(e) => { setT(e.target.value); setp(Math.round((0.6 * e.target.value) / 1000) * 1000) }} className="form-control" /> </div>
                            </div>
                            <div className='col-lg-12'>
                              <div className="from-group mb-1">
                                <label className="tx-gray">How much monthly pension would you need Rs <span className="text-danger fs-5">*</span></label>
                                <input type="number" name="pension" value={p} onChange={(e) => { setp(e.target.value) }} className="form-control" /> </div>
                            </div>
                            <div className='col-lg-12'>
                              <div className="from-group ">
                                <label className="tx-gray">Don’t worry about inflation as yet. Just estimate what your expenses are today. Remove expenses like kid’s education, housing EMIs, loan EMIs and add some extra for medical expenses. As a ballpark, this should be around 50-60% of your current expenses. </label>
                                {
                                  errorMessage != '' ?
                                    <div style={{ color: 'red' }}>{errorMessage}</div> : ''
                                }
                              </div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            <button type="button" onClick={() => { setStep(1) }} className="btn btn-primary mt-4 btn-full btn-lg button01">Previous</button>
                            <button type="button" onClick={processstep} className="btn btn-primary mt-4 btn-full btn-lg button01 ms-3">Continue</button>

                          </div>

                        </div> : ''
                    }
                    {
                      step == 3 ?
                        <div id="step3" className="">
                          <CustomProgressBar percent={30} />
                          {/* <div className="backbutton-desktop" onClick={processback} ><i class="icon-arrow-left8 wd-20 mb-5 "></i></div> */}
                          <h5 className="mt-5 mb-4 text-center ">Let’s get down to some assumptions...</h5>
                          <div className='row g-3'>
                            <div className='col-lg-12'>
                              <div className="from-group mb-1">
                                <label className="tx-gray">What is your expected rate of return for accumulating for pension fund<span className="text-danger fs-5">*</span></label>
                                <input type="number" name="rateofreturn" defaultValue={r} onChange={(e) => { setr(e.target.value) }} className="form-control" /> </div>
                            </div>
                            <div className='col-lg-12'>
                              <div className="from-group mb-1">
                                <label className="tx-gray">What is your expected rate of inflation<span className="text-danger fs-5">*</span></label>
                                <input type="number" name="number" defaultValue={i} onChange={(e) => { seti(e.target.value) }} className="form-control" />
                              </div>
                            </div>
                            <div className='col-lg-12'>
                              <div className="from-group mb-1">
                                <label className="tx-gray">Your expected rate of return depends upon your risk profile, your need for risk, you capacity and behavioural aspects. In general, including equities in higher percentage increases risk and also increases your rate of return.</label>
                                {
                                  errorMessage != '' ?
                                    <div style={{ color: 'red' }}>{errorMessage}</div> : ''
                                }
                              </div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            <button type="button" onClick={() => { setStep(2) }} className="btn btn-primary mt-4 btn-full btn-lg button01">Previous</button>
                            <button type="button" onClick={calcformulax} className="btn btn-primary mt-4 ms-3 btn-full btn-lg button01">Continue</button>
                          </div>

                        </div> : ''
                    }
                    {/* goal type option 1 select  step 4  */}
                    {
                      step == 4 ?
                        <div id="step4" className="">
                          <CustomProgressBar percent={40} />
                          {/* <div className="backbutton-desktop" onClick={processback} ><i class="icon-arrow-left8 wd-20 mb-5 "></i></div> */}
                          <h3 className="mt-5 mb-4 text-center">Here we go</h3>

                          <div className='row g-3'>
                            <div className='col-lg-12'>
                              <div className="gform-box mg-b-20">
                                <label>You need a pension of <span className="tx-theme fw600">₹{p}</span> per month, start {n} years from now. However due to inflation, value of this money will reduce in {n} years. You will actually need <span className="tx-theme fw600">₹{x}</span> per month after 10 years, and this should increase every year by inflation, i.e {i}</label>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            <button type="button" onClick={() => { setStep(3) }} className="btn btn-primary mt-4 btn-full btn-lg button01">Previous</button>
                            <button type="button" onClick={calcformulax} className="btn btn-primary mt-4 ms-3 btn-full btn-lg button01">Continue</button>
                          </div>



                        </div> : ''
                    }
                    {/* goal type option 1 select  step 5  */}
                    {
                      step == 5 ?
                        <div id="step5" className="">
                          <CustomProgressBar percent={60} />
                          {/* <div className="backbutton-desktop" onClick={processback} ><i class="icon-arrow-left8 wd-20 mb-5 "></i></div> */}
                          <h5 className="mt-5 mb-4 text-center" >Let’s estimate return on accumulated pension fund</h5>

                          <div className="row g-3">
                            <div className='col-lg-12'>
                              <div className="gform-box mg-b-20">
                                <label>Once you invest monthly and accumulate a pension corpus, you will then need to invest this corpus somewhere so that you can get monthly pension. What rate of return should you expect for this return ?</label>
                              </div>
                            </div>
                            <div className='col-lg-12'>
                              <div className="gform-box mg-b-20">
                                <label>In general, you can buy this monthly pension plan from pension/ insurance companies. The return expected is slightly less than 10 year Government of India securities, and is normally not able to beat inflation.</label>
                              </div>
                            </div>
                            <div className='col-lg-12'>
                              <div className="gform-box mg-b-20">
                                <label>Or you can generate a higher return by investing in mutual funds, with systematic withdrawal plans.</label>
                              </div>
                            </div>

                            <div className='col-lg-12'>
                              <div className="from-group mb-1">
                                <label>What is your expected rate of return on accumulated funds ?<span className="text-danger fs-5">*</span></label>
                                <input type="number" name="roraccuumulatefund" value={e} onChange={(e) => { sete(e.target.value) }} className="form-control" />
                              </div>
                            </div>
                            <div className='col-lg-12'>
                              <div className="from-group mb-1">
                                <label>Your pension will increase at the rate of {q1}% every year to counter inflation<span className="text-danger fs-5">*</span></label>
                                <input type="number" name="roraccuumulatefund" value={q1} onChange={(e) => { setq1(e.target.value) }} className="form-control" />
                              </div>
                            </div>
                          </div>
                          {
                            errorMessage != '' ?
                              <div style={{ color: 'red' }}>{errorMessage}</div> : ''
                          }
                          {
                            q1msg == 1 ?
                              <div style={{ color: 'red' }}>At your expected rate of return {e} %, increasing pension more than {q1} % will be not feasible. Increase expected rate, or reduce increase in monthly pension</div> : ''
                          }
                          <div className="d-flex align-items-center">
                            <button type="button" onClick={() => { setStep(4) }} className="btn btn-primary mt-4 btn-full btn-lg button01">Previous</button>
                            <button type="button" onClick={calcformulaS} className="btn btn-primary mt-4 ms-3 btn-full btn-lg button01">Continue</button>
                          </div>



                        </div> : ''
                    }
                    {/* goal type option 1 select  step 6 then move to step 16  */}
                    {
                      step == 6 ?
                        <div id="step6" className="">
                          <CustomProgressBar percent={80} />
                          {/* <div className="backbutton-desktop" onClick={processback} ><i class="icon-arrow-left8 wd-20 "></i></div> */}
                          <h3 className="mt-5 mb-4 text-center">Ok, so here we have it</h3>
                          {
                            calcerror == 1 ?
                              <div style={{ color: 'red' }}>“Oh ! Looks like the numbers are not working somehow. Try with different numbers ?”</div> : ''
                          }
                          {
                            sipsalaryerror == 1 ?
                              <ul>
                                <li>Your SIP required is more than half your salary <span className="tx-theme fw600">₹{sip}</span>.</li>
                                <li>You should start the SIP of at least <span className="tx-theme fw600">₹{Math.round(0.4 * t)}</span> and revise every year. Take forward for investment amount, allow changing of this amount if required</li>
                                <li>Or you can change your expectations and reduce pension amount</li>
                                <button
                                  className="btn btn-secondary btn-lg btn-half p-2 mt-1"
                                  onClick={() => { setStep(2) }}
                                >
                                  Change Pension Amount<i class="icon-arrow-right8 ms-3" aria-hidden="true"></i>
                                </button>

                                <li className='mt-3'>Or postpone your retirement by few years</li>
                                <button
                                  className="btn btn-secondary btn-lg btn-half p-2 mt-1"
                                  onClick={() => { setStep(1) }}
                                >
                                  Change retirement Age<i class="icon-arrow-left8 ms-3" aria-hidden="true"></i>
                                </button>

                                <div className='col-lg-12 mt-3'>
                                  <div className="from-group  mt-3  mb-3">
                                    <label>Or invest 40% of your amount</label>
                                    <input type="number" name="sipeoor" value={0.4 * sip} onChange={(e) => { setsip(e.target.value) }} className="form-control" />
                                  </div>
                                </div>
                                {/* <input type="number" name="errorsipamount" value={errorsipamount} onChange={(e) => {if(e.target.value == null){setsip(errorsipamount)}else{setsip(e.target.value)}}} className="form-control" />  */}
                                {/* <button type="button" onClick={setportfolioname} className="btn btn-primary mt-3 button01">Continue</button> */}
                                <div className="d-flex">
                                  <button type="button" onClick={() => { setStep(5) }} className="btn btn-primary mt-4 btn-full btn-lg button01">Previous</button>
                                  <button className="btn btn-primary mt-4 ms-3 btn-full btn-lg button01" onClick={setportfolioname}>
                                    Continue
                                  </button>
                                </div>

                              </ul> :
                              <div className="gform mt-20">
                                <div className='col-lg-12'>
                                  <div className="gform-box mg-b-20">
                                    <label>Your accumulated pension corpus needs to be <span className="tx-theme fw600">₹{s}</span></label>
                                  </div>
                                </div>
                                <div className='col-lg-12'>
                                  <div className="gform-box mg-b-20">
                                    <label>And you need to invest monthly Rs <span className="tx-theme fw600">₹{sip}</span></label>
                                  </div>
                                </div>
                                <div className='row'>
                                  <div>
                                    <button
                                      className="btn btn-secondary btn-lg btn-full mb-4 mt-4"
                                      onClick={restartprocess}
                                    >
                                      Try with diffrent number
                                    </button>
                                  </div>


                                  {calcerror == 0 ?
                                    <div className="d-flex align-items-center">
                                      <button type="button" onClick={() => { setStep(5) }} className="btn btn-primary  btn-full btn-lg button01">Previous</button>
                                      <button className="btn btn-primary btn-lg btn-full ms-3 " onClick={setportfolioname}>
                                        Continue
                                      </button>
                                    </div> : ""}
                                </div>


                              </div>
                          }
                        </div> : ''
                    }
                    {/* goal type option 6 select step 1 */}
                    {
                      step == 7 ?
                        <div id="step7" className="">
                          <CustomProgressBar percent={10} />
                          <div className='row g-3 mt-3'>
                            <div className='col-lg-12'>
                              <div className="from-group mb-1">

                                <label className="tx-gray">Goal Type<span className="text-danger fs-5">*</span></label>
                                <select name="goal_type" className="custom-select" value={goal_type} onChange={(e) => { if (e.target.value != '6' && e.target.value != '8' && e.target.value != '9' && e.target.value != '7') { setCustomize(0); setGoalType(e.target.value); if (['2', '3', '4', '5'].includes(e.target.value)) { setprocessbarflow3(1); setStep(13) } else { setprocessbarflow3(0); setStep(eval(e.target.value)) } } else { customizegoal(e.target.value) } }}>
                                  <option value="1">Plan for pension after retirement</option>
                                  <option value="2">Plan for buying a new house</option>
                                  <option value="3">Plan for Child’s education</option>
                                  <option value="4">Plan for Child’s Marriage</option>
                                  <option value="5">Plan for a vacation</option>
                                  <option value="7">Plan my own customised Goal</option>
                                  <option value="6">Don’t have a goal, but want to invest</option>
                                  <option value="8">Start SIP</option>
                                  <option value="9">Start Lumpsum Investment</option>                                </select>

                              </div>
                              {customize == 1 ?
                                <div>
                                  <div className='col-lg-12'>
                                    <div className="from-group mb-1">
                                      <label className="tx-gray">Select Customize Goal<span className="text-danger fs-5">*</span></label>
                                      <select name="goal_type" className="custom-select" defaultValue={customize_type} onChange={(e) => { if (e.target.value != '13') { setprocessbarflow3(0) } else { setprocessbarflow3(1) } setCustomizeType(e.target.value); setGoalType('7'); setStep(eval(e.target.value)) }}>
                                        <option value="7">Plan an annuity goal (e.g Monthly Pension)</option>
                                        <option value="13">Plan to accumulate (e.g for new Car)</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className='col-lg-12'>
                                    <div className="from-group mb-1">
                                      <label className="tx-gray">Set Portfolio Name<span className="text-danger fs-5">*</span></label>
                                      <input type="text" name="goalname" value={portfolioname} onChange={(e) => { setPortfolioname(e.target.value) }} className="form-control" />
                                    </div>
                                  </div>
                                </div>
                                : ''}

                              <div className="row g-2">
                                <div className='col-lg-6'>
                                  <div className="from-group mb-1">
                                    <label className="tx-gray">Date Of Birth<span className="text-danger fs-5">*</span></label>
                                    <input type="date" name="annuitydob" max={new Date().toISOString().split("T")[0]} value={annuitydob} onChange={dobhandleChange} className="form-control" required /> </div>
                                </div>
                                <div className='col-lg-6'>
                                  <div className="from-group mb-1">
                                    <label className="tx-gray">Age at which you need Annuity<span className="text-danger fs-5">*</span></label>
                                    <input type="number" name="annuityage" value={annuityage} onChange={(e) => { setAnnuityAge(e.target.value) }} className="form-control" /> </div>
                                </div>

                              </div>

                              {
                                ageannuityStatus == 0 ? '' : ageannuityStatus == 1 ? 'Good, let’s estimate how much Annuity you need' : 'Seems you don’t have a long time horizon, let’s see if it works out wel'
                              }
                              <div className='col-lg-12'>
                                <div className="from-group mb-1">
                                  <label className="tx-gray">Any details</label>
                                  <textarea name="anydetails2" rows={6} className="form-control" value={anydetails2} onChange={(e) => { setAnydetailsf2(e.target.value) }} placeholder="Add any details that you would like to retain for future, like assumptions, thought process behind it or anything else that is relevant to this goal"></textarea>
                                  {
                                    errorMessage != '' ?
                                      <div style={{ color: 'red' }}>{errorMessage}</div> : ''
                                  }
                                </div>
                              </div>
                            </div>
                            {customize == 1 ? <div style={{ color: 'red' }}>
                              <strong>Note :-</strong>
                              <p className='tx-11 mb-1'>- Annuity is regular flow of money on monthly, quarter, annual or any frequency basis like Pension.</p>
                              <p className='tx-11'>- Accumulation is simply saving every month to get large amount at a future date, like saving to buy a Car in future.</p>
                            </div> : ''}
                            <div className="d-flex align-items-center">
                              {/* <button type="button" onClick={processback} className="btn btn-primary mt-4 btn-full btn-lg button01">Previous</button> */}
                              <button type="button" onClick={processstep} className="btn btn-primary mt-4  btn-full btn-lg button01">Continue</button>
                            </div>


                          </div>
                        </div> : ''
                    }
                    {/* goal type option 6 select step 2 */}
                    {
                      step == 8 ?
                        <div id="step8" className="">
                          <CustomProgressBar percent={20} />
                          {/* <div className="backbutton-desktop" onClick={processback} ><i class="icon-arrow-left8 wd-20 "></i></div> */}
                          <h5 className="text-center mt-5 mb-3">Let’s now try and estimate how much do you need</h5>

                          <div className='row g-3'>
                            <div className='col-lg-12'>
                              <div className="from-group mb-1">
                                <label className="tx-gray">What is your current monthly take home salary approximately ?<span className="text-danger fs-5">*</span></label>
                                <input type="number" name="monthysal" value={at} onChange={(e) => { setAt(e.target.value); setap(Math.round((0.6 * e.target.value) / 1000) * 1000) }} className="form-control" /> </div>
                            </div>
                            <div className='col-lg-12'>
                              <div className="from-group mb-1">
                                <label className="tx-gray">How much monthly Annuity would you need Rs<span className="text-danger fs-5">*</span></label>
                                <input type="number" name="annuity" value={ap} onChange={(e) => { setap(e.target.value) }} className="form-control" /> </div>
                            </div>
                            <div className='col-lg-12'>
                              <div className="from-group mb-1">
                                <label>Don’t worry about inflation as yet. Just estimate what your expenses are today. Remove expenses like kid’s education, housing EMIs, loan EMIs and add some extra for medical expenses. As a ballpark, this should be around 50-60% of your current expenses. </label>
                                {
                                  errorMessage != '' ?
                                    <div style={{ color: 'red' }}>{errorMessage}</div> : ''
                                }
                              </div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            <button type="button" onClick={() => { setStep(7) }} className="btn btn-primary mt-4 btn-full btn-lg button01">Previous</button>
                            <button type="button" onClick={processstep} className="btn btn-primary mt-4 ms-3 btn-full btn-lg button01">Continue</button>
                          </div>


                        </div> : ''
                    }
                    {/* goal type option 6 select step 3 */}
                    {
                      step == 9 ?
                        <div id="step9" className="goalset">
                          <CustomProgressBar percent={30} />
                          {/* <div className="backbutton-desktop" onClick={processback} ><i class="icon-arrow-left8 wd-20 "></i></div> */}
                          <h5 className="text-center mt-5 mb-3">Let’s get down to some assumptions...</h5>

                          <div className='row'>
                            <div className='col-lg-12'>
                              <div className="from-group mb-1">
                                <label className="tx-gray">What is your expected rate of return for accumulating for Annuity fund<span className="text-danger fs-5">*</span></label>
                                <input type="number" name="rateofreturn" value={ar} onChange={(e) => { setar(e.target.value) }} className="form-control" /> </div>
                            </div>
                            <div className='col-lg-12'>
                              <div className="from-group mb-1">
                                <label className="tx-gray">What is your expected rate of inflation<span className="text-danger fs-5">*</span></label>
                                <input type="number" name="number" value={ai} onChange={(e) => { setai(e.target.value) }} className="form-control" />
                              </div>
                            </div>
                            <div className='col-lg-12'>
                              <div className="gform-box mg-b-20">
                                <label>Your expected rate of return depends upon your risk profile, your need for risk, you capacity and behavioural aspects. In general, including equities in higher percentage increases risk and also increases your rate of return.</label>
                                {
                                  errorMessage != '' ?
                                    <div style={{ color: 'red' }}>{errorMessage}</div> : ''
                                }
                              </div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            <button type="button" onClick={() => { setStep(8) }} className="btn btn-primary mt-4 btn-full btn-lg button01">Previous</button>
                            <button type="button" onClick={calcformulaAnnuityx} className="btn btn-primary mt-4 ms-3 btn-full btn-lg button01">Continue</button>
                          </div>

                        </div> : ''
                    }
                    {/* goal type option 6 select step 4 */}
                    {
                      step == 10 ?
                        <div id="step10" className="">
                          <CustomProgressBar percent={40} />
                          {/* <div className="backbutton-desktop" onClick={processback} ><i class="icon-arrow-left8 wd-20 "></i></div> */}
                          <h3 className="text-center mt-5 mb-3">Here we go</h3>
                          <div className="gform mt-20">
                            <div className='row'>
                              <div className='col-lg-12'>
                                <div className="gform-box mg-b-20">
                                  <label>You need a Annuity of <span className="tx-theme fw600">₹{ap}</span> per month, start {an} years from now. However due to inflation, value of this money will reduce in {an} years. You will actually need <span className="tx-theme fw600">₹{ax}</span> per month after 10 years, and this should increase every year by inflation, i.e {ai}</label>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex align-items-center">
                              <button type="button" onClick={() => { setStep(9) }} className="btn btn-primary mt-4 btn-full btn-lg button01">Previous</button>
                              <button type="button" onClick={processstep} className="btn btn-primary mt-4 ms-3 btn-full btn-lg button01">Continue</button>
                            </div>


                          </div>
                        </div> : ''
                    }
                    {/* goal type option 6 select step 5 */}
                    {
                      step == 11 ?
                        <div id="step11" className="">
                          <CustomProgressBar percent={60} />
                          {/* <div className="backbutton-desktop" onClick={processback} ><i class="icon-arrow-left8 wd-20 "></i></div> */}
                          <h5 className="text-center mt-5 mb-4">Let’s estimate return on accumulated Annuity fund</h5>
                          <div className="row g-3">
                            <div className='col-lg-12'>
                              <div className="gform-box mg-b-20">
                                <label>Once you invest monthly and accumulate a Annuity corpus, you will then need to invest this corpus somewhere so that you can get monthly annuity. What rate of return should you expect for this return ?</label>
                              </div>
                            </div>
                            <div className='col-lg-12'>
                              <div className="gform-box mg-b-20">
                                <label>In general, you can buy this monthly annuity plan from annuity/ insurance companies. The return expected is slightly less than 10 year Government of India securities, and is normally not able to beat inflation.</label>
                              </div>
                            </div>
                            <div className='col-lg-12'>
                              <div className="gform-box mg-b-20">
                                <label>Or you can generate a higher return by investing in mutual funds, with systematic withdrawal plans.</label>
                              </div>
                            </div>

                            <div className='col-lg-12'>
                              <div className="from-group mb-1">
                                <label className="tx-gray">What is your expected rate of return on accumulated funds ?<span className="text-danger fs-5">*</span></label>
                                <input type="number" name="roraccuumulatefund" value={ae} onChange={(e) => { setae(e.target.value) }} className="form-control" />
                              </div>
                            </div>
                            <div className='col-lg-12'>
                              <div className="from-group mb-1">
                                <label className="tx-gray">Your annuity will increase at the rate of {aq1}% every year to counter inflation<span className="text-danger fs-5">*</span></label>
                                <input type="number" name="roraccuumulatefund" value={aq1} onChange={(e) => { setAq1(e.target.value) }} className="form-control" />
                                {
                                  errorMessage != '' ?
                                    <div style={{ color: 'red' }}>{errorMessage}</div> : ''
                                }
                                {
                                  aq1msg == 1 ?
                                    <div style={{ color: 'red' }}>At your expected rate of return {ae} %, increasing pension more than {aq1} % will be not feasible. Increase expected rate, or reduce increase in monthly pension</div> : ''
                                }
                              </div>
                            </div>
                            <div className="d-flex align-items-center">
                              <button type="button" onClick={() => { setStep(10) }} className="btn btn-primary mt-4 btn-full btn-lg button01">Previous</button>
                              <button type="button" onClick={calcformulaAnnuityS} className="btn btn-primary mt-4 ms-3 btn-full btn-lg button01">Continue</button>
                            </div>


                          </div>
                        </div> : ''
                    }
                    {/* goal type option 6 select step 6 move to step 16 */}
                    {
                      step == 12 ?
                        <div id="step12" className="">
                          <CustomProgressBar percent={80} />

                          <h3 className="text-center mt-5 mb-3">Ok, so here we have it</h3>
                          {
                            annuitycalcerror == 1 ?
                              <div style={{ color: 'red' }}>“Oh ! Looks like the numbers are not working somehow. Try with different numbers ?”</div> : ''
                          }
                          {
                            sipannuitysalaryerror == 1 ?
                              <ul>
                                <li>Your SIP required is more than half your salary <span className="tx-theme fw600">₹{asip}</span>.</li>
                                <li>You should start the SIP of at least <span className="tx-theme fw600">₹{Math.round(0.4 * at)}</span> and revise every year. Take forward for investment amount, allow changing of this amount if required</li>
                                <li>Or you can change your expectations and reduce annuity amount</li>
                                <button type="button" className="mt-10 buttonab-line" onClick={(e) => { setStep(8) }}>Change Annuity Amount<i class="fa fa-long-arrow-right mg-l-10" aria-hidden="true"></i></button>
                                <li className='mt-3'>Or postpone your retirement by few years</li>
                                <button type="button" className="mt-10 buttonab-line" onClick={(e) => { setStep(7) }}>Change retirement Age<i class="fa fa-long-arrow-right mg-l-10" aria-hidden="true"></i></button>
                                <div className='col-lg-12 mt-3'>
                                  <div className="from-group mb-1">
                                    <label className="tx-gray">Or invest 40% of your amount<span className="text-danger fs-5">*</span></label>
                                    <input type="number" name="asipeoor" value={0.4 * asip} onChange={(e) => { setasip(e.target.value) }} className="form-control" />
                                  </div>
                                </div>
                                <div className="d-flex align-items-center">
                                  <button type="button" onClick={() => { setStep(11) }} className="btn btn-primary mt-4 btn-full btn-lg button01">Previous</button>
                                  <button type="button" onClick={setportfolioname} className="btn btn-primary mt-4 ms-3 btn-full btn-lg button01">Continue</button>
                                </div>
                                {/* <button className="btn btn-primary btn-lg btn-full mt-4 " onClick={setportfolioname}>
                                  Continue
                                </button> */}

                              </ul> :
                              <div className="gform mt-20">
                                <div className='col-lg-12'>
                                  <div className="gform-box mg-b-20">
                                    <label>Your accumulated annuity corpus needs to be Rs <span className="tx-theme">{as}</span></label>
                                  </div>
                                </div>
                                <div className='col-lg-12'>
                                  <div className="gform-box mg-b-20">
                                    <label>And you need to invest monthly Rs <span className="tx-theme">{asip}</span></label>
                                  </div>
                                </div>

                                <div>
                                  <button className="btn btn-secondary btn-lg btn-full mt-4 " onClick={restartprocessflow2}>
                                    Try with diffrent numbers
                                  </button>
                                </div>

                                {annuitycalcerror == 0 ?
                                  <>

                                    <div className="d-flex align-items-center">
                                      <button type="button" onClick={() => { setStep(11) }} className="btn btn-primary mt-4 btn-full btn-lg button01">Previous</button>
                                      <button type="button" onClick={setportfolioname} className="btn btn-primary mt-4 ms-3 btn-full btn-lg button01">Continue</button>
                                    </div>
                                  </>
                                  // <button className="btn btn-primary btn-lg btn-full mt-4 " onClick={setportfolioname}>
                                  //   Continue
                                  // </button>
                                  : ""}

                                {/* <div className='col-lg-3'>
                              <div className="gform-box mg-b-20">
                                <button type="button" onClick={processback} className="btn-primary mt-10 button03">Previous</button>
                              </div>
                            </div>
                            <div className='col-lg-6'>
                              <div className="gform-box mg-b-20">
                                <button type="button" onClick={restartprocessflow2} className="btn-primary mt-10 button03">Try with diffrent numbers</button>
                              </div>
                            </div>
                            {annuitycalcerror == 0 ?
                              <div className='col-lg-3'>
                                <div className="gform-box mg-b-20">
                                  <button type="button" onClick={setportfolioname} className="btn-primary mt-10 button03">Continue</button>
                                </div>
                              </div> : ''
                            } */}

                              </div>
                          }
                        </div> : ''


                    }

                    {/* select option2 in goal type step 1 */}
                    {
                      step == 13 ?
                        <div id="step13" className="">
                          <CustomProgressBar percent={25} />
                          <div className='row g-3 mt-3'>
                            <div className='col-lg-12'>
                              <div className="from-group mb-1">
                                <label>Goal Type<span className="text-danger fs-5">*</span></label>
                                <select name="goal_type" className="custom-select" value={goal_type} onChange={(e) => { if (e.target.value != '6' && e.target.value != '8' && e.target.value != '9' && e.target.value != '7') { setCustomize(0); setGoalType(e.target.value); if (['2', '3', '4', '5'].includes(e.target.value)) { setStep(13); setprocessbarflow3(1); } else { setprocessbarflow3(0); setStep(eval(e.target.value)) } } else { customizegoal(e.target.value) } }}>
                                  <option value="1">Plan for pension after retirement</option>
                                  <option value="2">Plan for buying a new house</option>
                                  <option value="3">Plan for Child’s education</option>
                                  <option value="4">Plan for Child’s Marriage</option>
                                  <option value="5">Plan for a vacation</option>
                                  <option value="7">Plan my own customised Goal</option>
                                  <option value="6">Don’t have a goal, but want to invest</option>
                                  <option value="8">Start SIP</option>
                                  <option value="9">Start Lumpsum Investment</option>
                                </select>
                              </div>
                            </div>
                            {customize == 1 ?
                              <div className='col-lg-12'>
                                <div className="from-group mb-1">
                                  <label className="tx-gray">Select Customize Goal<span className="text-danger fs-5">*</span></label>
                                  <select name="goal_type" className="custom-select" value={customize_type} onChange={(e) => { if (e.target.value != '13') { setprocessbarflow3(0) } else { setprocessbarflow3(1) } setCustomizeType(e.target.value); setGoalType('7'); setStep(eval(e.target.value)) }}>
                                    <option value="7">Plan an annuity goal (e.g Monthly Pension)</option>
                                    <option value="13">Plan to accumulate (e.g for new Car)</option>
                                  </select>
                                </div>
                                <div className='col-lg-12'>
                                  <div className="from-group mt-1">
                                    <label className="tx-gray">Set Portfolio Name</label>
                                    <input type="text" name="goalname" value={portfolioname} onChange={(e) => { setPortfolioname(e.target.value) }} className="form-control" />
                                  </div>
                                </div>
                              </div>
                              : ''}
                            {/* <div className="gform-box mg-b-20">
                            <select className="form-control" value={needfor} onChange={(e) => {setNeedfor(e.target.value)}}>
                            <option value="House">Your House</option>
                            <option value="Vacation">Your Vacation</option>
                            <option value="Child Marrige">Your Child’s Marriage</option>
                            </select>
                          </div> */}
                            <div className='col-lg-12'>
                              <div className="gform-box mg-b-20">
                               <label>Child Name<span className="text-danger fs-5">*</span></label>
                               <p className="inpfeed mb-0">What's the adorable name of your future scholar? We're all ears for the next Ivy League prodigy in the making.</p>
                               <input type="text" value={cn} onChange={(e) => { setcn(e.target.value) }} className="form-control" />
                                
                              </div>
                            </div>
                            <div className='col-lg-12'>
                              <div className="gform-box mg-b-20">
                                <label>How much money is required ? Rs<span className="text-danger fs-5">*</span></label>
                                <p className="inpfeed  mb-0">Forget inflation for a moment, what's the cold, hard rupee amount for molding the young genius in today.s value ? </p>
                                <input type="number" name="moneyreq" value={q} onChange={(e) => { setq(e.target.value) }} className="form-control" />
                              </div>
                            </div>
                            <div className='col-lg-12'>
                              <div className="gform-box mg-b-20">
                                <label>And in how many years from now ?<span className="text-danger fs-5">*</span> </label>
                                <p className="inpfeed  mb-0">How many years until your mini-me dons the backpack and heads to his education you are planning for? Let's set the countdown.</p>
                                <input type="number" name="yearsfromnow3" value={n3} onChange={(e) => { setn3(e.target.value) }} className="form-control" />
                              </div>
                            </div>
                            <div className='col-lg-12'>
                              <div className="gform-box mg-b-20 mt-2">
                                <label>Don’t worry about inflation as yet. Just estimate how much do you need in today’s value. </label>
                                {
                                  errorMessage != '' ?
                                    <div style={{ color: 'red' }}>{errorMessage}</div> : ''
                                }
                              </div>
                            </div>
                            {/* <div className='col-lg-6'>
                          <div className="gform-box mg-b-20">
                      <button type="button" onClick={processback} className="btn btn-primary mt-10 button01">Previous</button>
                      </div>
                        </div> */}
                            {customize == 1 ? <div style={{ color: 'red' }}>
                              <strong>Note :-</strong>
                              <p className='tx-11 mb-1'>- Annuity is regular flow of money on monthly, quarter, annual or any frequency basis like Pension.</p>
                              <p className='tx-11'>- Accumulation is simply saving every month to get large amount at a future date, like saving to buy a Car in future.</p>
                            </div> : ''}
                            <div className="d-flex align-items-center">
                              {/* <button type="button" onClick={processback} className="btn btn-primary  btn-full btn-lg button01">Previous</button> */}
                              <button className="btn btn-primary btn-lg btn-full mt-3 " onClick={processstep}>
                                Continue
                              </button>
                            </div>


                          </div>
                        </div> : ''
                    }
                    {/* select option2 in goal type step 2 */}
                    {
                      step == 14 ?
                        <div id="step14" className="">
                          <CustomProgressBar percent={50} />
                          {/* <div className="backbutton-desktop" onClick={processback} ><i class="icon-arrow-left8 wd-20 "></i></div> */}
                          <h5 className="text-center mt-5 mb-4">Let’s make some estimates and assumptions ...</h5>
                          <div className="row g-3 mt-3">
                          <div className='col-lg-12'>
        <div className="form-group mb-1">
          <label className="tx-gray">What is your expected rate of inflation<span className="text-danger fs-5">*</span></label>
          <p className="inpfeed mb-0"> Meet Inflation, the mischief maker in the world of costs. Guess high to outsmart this sneaky foe – the troublemaker in your financial story!
</p>
          <input
            type="number"
            name="ror3"
         defaultValue={r3}
            onChange={(e) => {
              const value = parseInt(e.target.value);
              setr3(value);
            }}
            className="form-control"
          />


          {r3 < 3 ? <p style={{ fontSize: "12px", color: "red" }}>If less than 3%: That's too low! Low inflation can throw a spanner in your planning. Try a number between 3% and 15% – it's the Goldilocks zone for financial assumptions.</p> : null}
          { r3 > 22 ? <p style={{ fontSize: "12px", color: "red" }}>Hold your horses! That's too high. Aim for a number between 3% and 12% – the sweet spot for realistic expectations.
</p> : null}
        </div>
      </div>

      <div className='col-lg-12'>
        <div className="form-group mb-1">
          <label className="tx-gray">What is your expected rate of return<span className="text-danger fs-5">*</span></label>
          <p className="inpfeed mb-0">Estimate Returns on your investments: What's your return forecast for these investments? Remember, aiming lower means dodging the risk bullet. It's the financial limbo – how low can you go without risking your goal? Financial highway at a comfortable speed.
</p>
          <input
            type="number"
            name="roi3"
          
            value={i3}
            onChange={(e) => {
              const value = parseInt(e.target.value);
              seti3(value);
            }}
            className="form-control"
          />
        
           {i3 < r3+2 ? <p style={{ fontSize: "12px", color: "red" }}>Oops! Your assumed return is hardly beating inflation. Shoot for a number between {r3+2} and 24% – the balanced path.</p> : null}
           {i3 > 24 ? <p style={{ fontSize: "12px", color: "red" }}>Slow down there! A very high assumption brings very high risk. Opt for a number between {r3+2} and 24% – finding the sweet spot is key.</p> : null}
        </div>
      </div>
                           <div className='col-lg-6'>
                              <div className="from-group mb-1">
                                <label className="tx-gray">Your expected rate of return depends upon your risk profile, your need for risk, you capacity and behavioural aspects. In general, including equities in higher percentage increases risk and also increases your rate of return.</label>
                              </div>
                            </div>
                            <div className='col-lg-6'>
                              <div className="from-group mb-1">
                                <label className="tx-gray">Inflation can slowly erode your wealth, and can make your plans go haywire. A correct estimate, and timely revision and rebalancing is important for you to succeed in your goals</label>
                              </div>
                            </div>

                            {
                              errorMessage != '' ?
                                <div style={{ color: 'red' }}>{errorMessage}</div> : ''
                            }
                            <div className="d-flex align-items-center">
                              <button type="button" onClick={() => { setStep(13) }} className="btn btn-primary  btn-full btn-lg button01">Previous</button>
                              <button className="btn btn-primary btn-lg btn-full ms-3 " onClick={calculateYflow3}>
                                Continue
                              </button>
                            </div>


                          </div>
                        </div> : ''
                    }
                    {/* select option2 in goal type step 3 move to step 16 */}
                    {
                      step == 15 ?
                        <div id="step15" className="">
                          <CustomProgressBar percent={75} />

                          <h3 className="text-center mt-5 mb-4">Here is what you need ...</h3>
                          {
                            calcerrorflow3 == 1 ?
                              <div style={{ color: 'red' }}>“Oh ! Looks like the numbers are not working somehow. Try with different numbers ?”</div> : ''
                          }
                          <div className="gform mt-20">
                            <div className='col-lg-12'>
                              <div className="gform-box mg-b-20">
                                <h6>At an inflation rate of {i3} you will need Rs <span className="tx-theme fw600">₹{y}</span> in {n3} years .</h6>
                              </div>
                            </div>
                            
      <div className='col-lg-12'>
        <div className="form-group mb-1">
          <label className="tx-gray">Any Lumpsum amount you want to invest today for this goal</label>
          <p className="inpfeed mb-0"> You want to start with some Lumpsum investment today? Got some extra funds to spare? Investing a lump sum now can make your monthly savings more manageable. It's like taking a shortcut to your financial goals – a little bump today for a smoother ride tomorrow.
</p>
          <input
            type="number"
            className="form-control"
            onChange={(e) => {
              const value = parseInt(e.target.value);
              setla(value);
            }}
      
          />
         {la <= 0 ? <p className="tx-11" style={{color:"red"}}>  Oops, investments can't be negative! Keep it positive, between 0 and {y} – let's keep those financial vibes positive.</p>:""}
         {la >= y ? <p className="tx-11" style={{color:"red"}}>Hold your horses! Your investment is aiming for the moon. Try a value between 0 and {y} finding the right orbit is key to financial success.</p>:""}

        </div>
      </div>
                            <div className='col-lg-12'>
                              <div className="gform-box mg-b-20">
                                <h6>And you need a monthly investment of Rs <span className="tx-theme fw600">₹{sip3}</span></h6>
                              </div>
                            </div>

                            {calcerrorflow3 == 1 ?
                              <button className="btn btn-secondary btn-lg btn-full mt-4 " onClick={restartprocessflow3}>
                                Try with different number

                              </button> :
                              <>
                                <div className="d-flex align-items-center mt-4">
                                  <button type="button" onClick={() => { setStep(14) }} className="btn btn-primary  btn-full btn-lg button01">Previous</button>
                                  <button className="btn btn-primary btn-lg btn-full ms-3 " onClick={calculateYflow3}>
                                    Continue
                                  </button>
                                </div>

                              </>
                            }

                            {/* <button className="btn btn-primary btn-lg btn-full mt-4 " onClick={calculateYflow3}>
                              Continue
                            </button> */}
                            {/* <button className="btn btn-primary btn-lg btn-full mt-4 " onClick={calculateYflow3}>
                                Continue
                              </button> */}
                            {/* <div className='col-lg-3'>
                          <div className="gform-box mg-b-20">
                            <button type="button" onClick={processback} className="btn-primary mt-10 button03">Previous</button>
                          </div>
                        </div>
                        <div className='col-lg-6'>
                          <div className="gform-box mg-b-20">
                            <button type="button" onClick={restartprocessflow3} className="btn-primary mt-10 button03">Try with diffrent numbers</button>
                          </div>
                        </div>
                        {calcerrorflow3 == 0 ?
                          <div className='col-lg-3'>
                            <div className="gform-box mg-b-20">
                              <button type="button" onClick={processstep} className="btn-primary mt-10 button03">Continue</button>
                            </div>
                          </div> : ''
                        } */}

                          </div>
                        </div> : ''
                    }

                    {/* goal type option 1 and option 2 and option 6 last step  */}

                    {
                      step == 16 ?
                        <div id="step16" className="">
                          <CustomProgressBar percent={100} />
                          {/* <div className="backbutton-desktop" onClick={processback} ><i class="icon-arrow-left8 wd-20 "></i></div> */}
                          <div className='row g-3 mt-3'>
                            <div className='col-lg-12'>
                              <div className="from-group mb-1">
                                <label className="tx-gray">Give some name to this portfolio<span className="text-danger fs-5">*</span></label>
                                <input type="text" name="portfolio_name" defaultValue={portfolioname} onChange={(e) => { setPortfolioname(e.target.value) }} className="form-control " /> </div>
                              {
                                errorMessage != '' ?
                                  <div style={{ color: 'red' }}>{errorMessage}</div> : ''
                              }
                            </div>

                          </div>
                          <div className="d-flex align-items-center mt-4">
                            <button type="button" onClick={() => { changestep16() }} className="btn btn-primary  btn-full btn-lg button01">Previous</button>
                            <button className="btn btn-primary btn-lg btn-full ms-3 " onClick={submitgoalsettingsdata} disabled={isLoading}>
                              {isLoading ? (
                                <img src="/img/loder01.gif" width="60px" height="11px" />
                              ) : (
                                "Submit"
                              )}
                              {/* Continue */}
                            </button>
                          </div>
                          {/* <button type="button" onClick={submitgoalsettingsdata} className="btn btn-primary mt-10 button01">Continue</button> */}

                        </div> : ''
                    }
                    {/* goal type option 7,8,9 select */}
                    {
                      step == 50 ?
                        <div id="step50" className="">
                          <div className='row g-3 mt-3'>
                            <div className='col-lg-12'>
                              <div className="from-group mb-1">
                                <label>Goal Type<span className="text-danger fs-5">*</span></label>
                                <select name="goal_type" className="custom-select" value={goal_type} onChange={(e) => { if (e.target.value != '6' && e.target.value != '8' && e.target.value != '9' && e.target.value != '7') { setCustomize(0); setGoalType(e.target.value); if (['2', '3', '4', '5'].includes(e.target.value)) { setStep(13); setprocessbarflow3(1) } else { setprocessbarflow3(0); setStep(eval(e.target.value)) } } else { customizegoal(e.target.value) } }}>
                                  <option value="1">Plan for pension after retirement</option>
                                  <option value="2">Plan for buying a new house</option>
                                  <option value="3">Plan for Child’s education</option>
                                  <option value="4">Plan for Child’s Marriage</option>
                                  <option value="5">Plan for a vacation</option>
                                  <option value="7">Plan my own customised Goal</option>
                                  <option value="6">Don’t have a goal, but want to invest</option>
                                  <option value="8">Start SIP</option>
                                  <option value="9">Start Lumpsum Investment</option>
                                </select>
                              </div>
                            </div>
                            {/* {customize == 1?
                          <div className='col-lg-12'>
                          <div className="gform-box mg-b-20">
                            <label>Select Customize Goal</label>
                            <select name = "goal_type" value={customize_type} onChange={(e) => { setCustomizeType(e.target.value); setGoalType('7'); setStep(eval(e.target.value))} }>
                            <option value="7">Plan an annuity goal (e.g Monthly Pension)</option>
                            <option value="13">Plan to accumulate (e.g for new Car)</option>
                            </select>
                          </div>
                        </div>:''} */}
                          </div>
                          <button className="btn btn-primary btn-lg btn-full mt-4 " onClick={skipflow}>
                            Continue
                          </button>
                        </div> : ''
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </BrowserView>
      <MobileView>
        <header className="mheader">
          <div className="container">
            <div className="mheader-left">
              <div className="dflex align-items-center" >
                <i class="d-icon-arrow-left tx-22" onClick={() => { history("/dashboard") }}></i>
                <div className="ms-3">
                  <h6 className="mb-0">Create Goals</h6>
                </div>
              </div>
            </div>
          </div>
        </header>
        {step == 1 ?
          <>
            <div className="mobile-main">
              <div className="mobile-form">

                <div className="p15 mt-3">
                  <div className="row g-2 mt-3">
                    <CustomProgressBar percent={10} />
                    <div className="col-12">
                      <div className="from-group mb-2">
                        <label className="tx-gray">Goal Type<span className="text-danger fs-5">*</span></label>
                        <select name="goal_type" className="custom-select" value={goal_type} onChange={(e) => { if (e.target.value != '6' && e.target.value != '8' && e.target.value != '9' && e.target.value != '7') { setCustomize(0); setGoalType(e.target.value); if (['2', '3', '4', '5'].includes(e.target.value)) { setStep(13); setprocessbarflow3(1); } else { setprocessbarflow3(0); setStep(eval(e.target.value)) } } else { customizegoal(e.target.value) } }}>
                          <option value="1">Plan for pension after retirement</option>
                          <option value="2">Plan for buying a new house</option>
                          <option value="3">Plan for Child’s education</option>
                          <option value="4">Plan for Child’s Marriage</option>
                          <option value="5">Plan for a vacation</option>
                          <option value="7">Plan my own customised Goal</option>
                          <option value="6">Don’t have a goal, but want to invest</option>
                          <option value="8">Start SIP</option>
                          <option value="9">Start Lumpsum Investment</option>
                        </select>
                      </div>
                    </div>
                    {customize == 1 ?
                      <div className="col-12">
                        <div className="from-group mb-2">
                          <label className="tx-gray">Select Customize Goal<span className="text-danger fs-5">*</span></label>
                          <select name="goal_type" className="custom-select" value={customize_type} onChange={(e) => { if (e.target.value != '13') { setprocessbarflow3(0) } else { setprocessbarflow3(1) } setCustomizeType(e.target.value); setGoalType('7'); setStep(eval(e.target.value)) }}>
                            <option value="7">Plan an annuity goal (e.g Monthly Pension)</option>
                            <option value="13">Plan to accumulate (e.g for new Car)</option>
                          </select>
                        </div>
                      </div> : ""}
                    <div className="col-12">
                      <div className="from-group mb-2">
                        <label className="tx-gray">Date Of Birth<span className="text-danger fs-5">*</span></label>
                        <input type="date" name="dob" value={dob} max={new Date().toISOString().split("T")[0]} onChange={dobhandleChange} className="form-control" required />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="from-group mb-2">
                        <label className="tx-gray">Age at which you need pension<span className="text-danger fs-5">*</span></label>
                        <input type="number" name="pensionage" defaultValue={pensionage} onChange={(e) => { setPensionAge(e.target.value) }} className="form-control" />
                      </div>
                    </div>
                    {ageStatus == 0 ? '' : ageStatus == 1 ? <p className="ms-1 text-dark"> 'Good, let’s estimate how much pension you need' : 'Seems you don’t have a long time horizon, let’s see if it works out well' </p> : ''}
                    <div className="col-12">
                      <div className="from-group mb-2">
                        <label className="tx-gray">Any details</label>
                        <textarea name="anydetails1" rows={6} onChange={(e) => { setAnydetailsf1(e.target.value) }} placeholder="Add any details that you would like to retain for future, like assumptions, thought process behind it or anything else that is relevant to this goal"></textarea>

                      </div>
                    </div>


                  </div>
                </div>
              </div>
            </div>
            <div className="footer-button">
              <button className="btn btn-primary btn-lg btn-full" onClick={processstep}>Continue</button>
            </div>
          </>
          : ""}

        {step == 2 ?
          <>
            <div className="mobile-main">
              {/* <div className="dflex align-items-center ms-2 pt-3" onClick={()=>{setStep(1)}}>  <i class="icon-arrow-left8"></i></div> */}
              <div className="mobile-form">

                <div className="p15 mt-3">
                  <div className="row g-2">
                    <CustomProgressBar percent={20} />
                    <div className="col-12">
                      <div className="from-group mb-2">
                        <label className="tx-gray">What is your current monthly take home salary approximately ?<span className="text-danger fs-5">*</span></label>
                        <input type="number" name="monthysal" value={t} onChange={(e) => { setT(e.target.value); setp(Math.round((0.6 * e.target.value) / 1000) * 1000) }} className="form-control" />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="from-group mb-2">
                        <label className="tx-gray">How much monthly pension would you need Rs<span className="text-danger fs-5">*</span></label>
                        <input type="number" name="pension" value={p} onChange={(e) => { setp(e.target.value) }} className="form-control" />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="from-group mb-2">
                        <label className="tx-gray">Don’t worry about inflation as yet. Just estimate what your expenses are today. Remove expenses like kid’s education, housing EMIs, loan EMIs and add some extra for medical expenses. As a ballpark, this should be around 50-60% of your current expenses. </label>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div className='footer-button d-flex'>
              <button className='btn btn-secondary btn-lg btn-full me-2' onClick={() => { setStep(1) }}>Previous</button>

              <button className="btn btn-primary btn-lg btn-full" onClick={processstep}>Continue</button>

            </div> </> : ""}

        {step == 3 ?
          <>
            <div className="mobile-main">

              <div className="mobile-form">
                <div className="p15 mt-3">
                  <div className="row g-2">
                    <CustomProgressBar percent={30} />
                    <div className="col-12">
                      <div className="from-group mb-2">
                        <label className="tx-gray">What is your expected rate of return for accumulating for pension fund<span className="text-danger fs-5">*</span></label>
                        <input type="number" name="rateofreturn" defaultValue={r} onChange={(e) => { setr(e.target.value) }} className="form-control" />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="from-group mb-2">
                        <label className="tx-gray">What is your expected rate of inflation<span className="text-danger fs-5">*</span></label>
                        <input type="number" name="number" defaultValue={i} onChange={(e) => { seti(e.target.value) }} className="form-control" />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="from-group mb-2">
                        <label className="tx-gray">Your expected rate of return depends upon your risk profile, your need for risk, you capacity and behavioural aspects. In general, including equities in higher percentage increases risk and also increases your rate of return.</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="footer-button">
              <button className="btn btn-primary btn-lg btn-full" onClick={calcformulax}>Continue</button>
            </div> */}
            <div className='footer-button d-flex'>
              <button className='btn btn-secondary btn-lg btn-full me-2' onClick={() => { setStep(2) }}>Previous</button>

              <button className="btn btn-primary btn-lg btn-full" onClick={calcformulax}>Continue</button>

            </div> </> : ""}

        {step == 4 ?
          <>
            <div className="mobile-main">

              <div className="mobile-form">
                <div className="p15 mt-3">
                  <div className="row g-2">
                    <CustomProgressBar percent={40} />
                    <div className="col-12">

                      <label>You need a pension of <span className="tx-theme fw600">₹{p}</span> per month, start {n} years from now. However due to inflation, value of this money will reduce in {n} years. You will actually need <span className="tx-theme fw600">₹{x}</span> per month after 10 years, and this should increase every year by inflation, i.e {i}</label>
                    </div>

                  </div>
                </div>
              </div>
            </div>


            <div className='footer-button d-flex'>
              <button className='btn btn-secondary btn-lg btn-full me-2' onClick={() => { setStep(3) }}>Previous</button>

              <button className="btn btn-primary btn-lg btn-full" onClick={calcformulax}>Continue</button>

            </div>
          </>
          : ""}

        {step == 5 ?
          <>
            <div className="mobile-main">

              <div className="mobile-form">
                <div className="p15 mt-3">
                  <div className="row g-2">
                    <CustomProgressBar percent={60} />
                    <div className="col-12">
                      <label>Once you invest monthly and accumulate a pension corpus, you will then need to invest this corpus somewhere so that you can get monthly pension. What rate of return should you expect for this return ?</label>
                    </div>
                    <div className="col-12">
                      <label>In general, you can buy this monthly pension plan from pension/ insurance companies. The return expected is slightly less than 10 year Government of India securities, and is normally not able to beat inflation.</label>
                    </div>
                    <div className="col-12">
                      <label>Or you can generate a higher return by investing in mutual funds, with systematic withdrawal plans.</label>
                    </div>
                    <div className="col-12">
                      <label>What is your expected rate of return on accumulated funds ?<span className="text-danger fs-5">*</span></label>
                      <input type="number" name="roraccuumulatefund" value={e} onChange={(e) => { sete(e.target.value) }} className="form-control" />
                    </div>
                    <div className="col-12">
                      <label>Your pension will increase at the rate of {q1}% every year to counter inflation<span className="text-danger fs-5">*</span></label>
                      <input type="number" name="roraccuumulatefund" value={q1} onChange={(e) => { setq1(e.target.value) }} className="form-control" />
                    </div>

                  </div>
                </div>
              </div>
            </div>
            {/* <div className="footer-button">
              <button className="btn btn-primary btn-lg btn-full" onClick={calcformulaS}>Continue</button>
            </div> */}

            <div className='footer-button d-flex'>
              <button className='btn btn-secondary btn-lg btn-full me-2' onClick={() => { setStep(4) }}>Previous</button>

              <button className="btn btn-primary btn-lg btn-full" onClick={calcformulaS}>Continue</button>

            </div>
          </>
          : ""}

        {step == 6 ?
          <>
            <div className="mobile-main">

              <div className="mobile-form">
                <div className="p15 mt-3">
                  <div className="row g-2">
                    <CustomProgressBar percent={80} />
                    <div className="col-12">
                      {
                        calcerror == 1 ?
                          <div style={{ color: 'red' }}>“Oh ! Looks like the numbers are not working somehow. Try with different numbers ?”</div> : ''
                      }
                      {
                        sipsalaryerror == 1 ?
                          <ul>
                            <li>Your SIP required is more than half your salary <span className="tx-theme fw600">₹{sip}</span>.</li>
                            <li>You should start the SIP of at least <span className="tx-theme fw600">₹{Math.round(0.4 * t)}</span> and revise every year. Take forward for investment amount, allow changing of this amount if required</li>
                            <li>Or you can change your expectations and reduce pension amount</li>
                            <button
                              className="btn btn-secondary btn-lg btn-half p-2 mt-1"
                              onClick={() => { setStep(2) }}
                            >
                              Change Pension Amount<i class="icon-arrow-right8 ms-3" aria-hidden="true"></i>
                            </button>
                            <li className='mt-3'>Or postpone your retirement by few years</li>
                            <button
                              className="btn btn-secondary btn-lg btn-half p-2 mt-1"
                              onClick={() => { setStep(1) }}
                            >
                              Change retirement Age<i class="icon-arrow-left8 ms-3" aria-hidden="true"></i>
                            </button>
                            <div className='col-lg-12 mt-3'>
                              <div className="from-group  mt-3  mb-3">
                                <label className="tx-gray">Or invest 40% of your amount<span className="text-danger fs-5">*</span></label>
                                <input type="number" name="sipeoor" value={0.4 * sip} onChange={(e) => { setsip(e.target.value) }} className="form-control" />
                              </div>
                            </div>
                            {/* <input type="number" name="errorsipamount" value={errorsipamount} onChange={(e) => {if(e.target.value == null){setsip(errorsipamount)}else{setsip(e.target.value)}}} className="form-control" />  */}

                            {/* <button type="button" onClick={setportfolioname} className="btn btn-primary mt-3 button01">Continue</button> */}
                            <div className="footer-button d-flex">
                              <button className='btn btn-secondary btn-lg btn-full me-2' onClick={() => { setStep(5) }}>Previous</button>
                              <button className="btn btn-primary btn-lg btn-full " onClick={setportfolioname}>
                                Continue
                              </button>
                            </div>

                          </ul> :
                          <div className="gform mt-20">
                            <div className='col-lg-12'>
                              <div className="gform-box mg-b-20">
                                <label>Your accumulated pension corpus needs to be <span className="tx-theme fw600">₹{s}</span></label>
                              </div>
                            </div>
                            <div className='col-lg-12'>
                              <div className="gform-box mg-b-20">
                                <label>And you need to invest monthly Rs <span className="tx-theme fw600">₹{sip}</span></label>
                              </div>
                            </div>
                          </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-button">
              <button
                className="btn btn-secondary btn-lg btn-full mb-4"
                onClick={restartprocess}
              >
                Try with diffrent number
              </button>
              {calcerror == 0 ?
                <>
                  <div className="d-flex ">
                    <button className='btn btn-secondary btn-lg btn-full me-2' onClick={() => { setStep(5) }}>Previous</button>
                    <button className="btn btn-primary btn-lg btn-full" onClick={setportfolioname}>Continue</button>
                  </div>
                </>


                : ""}
            </div>
          </>
          : ""}
        {step == 7 ?
          <>
            <div className="mobile-main">
              <div className="mobile-form">
                <CustomProgressBar percent={10} />
                <div className="p15 mt-3">
                  <div className="row g-2 mt-3">
                    <div className="col-12 from-group">
                      <label className="tx-gray">Goal Type<span className="text-danger fs-5">*</span></label>
                      <select name="goal_type" className="custom-select" value={goal_type} onChange={(e) => { if (e.target.value != '6' && e.target.value != '8' && e.target.value != '9' && e.target.value != '7') { setCustomize(0); setGoalType(e.target.value); if (['2', '3', '4', '5'].includes(e.target.value)) { setprocessbarflow3(1); setStep(13) } else { setprocessbarflow3(0); setStep(eval(e.target.value)) } } else { customizegoal(e.target.value) } }}>
                        <option value="1">Plan for pension after retirement</option>
                        <option value="2">Plan for buying a new house</option>
                        <option value="3">Plan for Child’s education</option>
                        <option value="4">Plan for Child’s Marriage</option>
                        <option value="5">Plan for a vacation</option>
                        <option value="7">Plan my own customised Goal</option>
                        <option value="6">Don’t have a goal, but want to invest</option>
                        <option value="8">Start SIP</option>
                        <option value="9">Start Lumpsum Investment</option>
                      </select>
                    </div>
                    {customize == 1 ?
                      <>
                        <div className="col-12">
                          <div className="from-group mb-2">
                            <label className="tx-gray">Select Customize Goal<span className="text-danger fs-5">*</span></label>
                            <select name="goal_type" className="custom-select" defaultValue={customize_type} onChange={(e) => { if (e.target.value != '13') { setprocessbarflow3(0) } else { setprocessbarflow3(1) } setCustomizeType(e.target.value); setGoalType('7'); setStep(eval(e.target.value)) }}>
                              <option value="7">Plan an annuity goal (e.g Monthly Pension)</option>
                              <option value="13">Plan to accumulate (e.g for new Car)</option>
                            </select>
                          </div>

                        </div>
                        <div className="col-12">
                          <div className="from-group mb-2">
                            <label className="tx-gray">Set Portfolio Name<span className="text-danger fs-5">*</span></label>
                            <input type="text" name="goalname" value={portfolioname} onChange={(e) => { setPortfolioname(e.target.value) }} className="form-control" />

                          </div>

                        </div>
                      </>
                      : ""}
                    <div className="col-12">
                      <div className="from-group mb-2">
                        <label className="tx-gray">Date Of Birth<span className="text-danger fs-5">*</span></label>
                        <input type="date" name="annuitydob" max={new Date().toISOString().split("T")[0]} value={annuitydob} onChange={dobhandleChange} className="form-control" required />
                      </div>

                    </div>
                    <div className="col-12">
                      <div className="from-group mb-2">
                        <label className="tx-gray">Age at which you need Annuity<span className="text-danger fs-5">*</span></label>
                        <input type="number" name="annuityage" value={annuityage} onChange={(e) => { setAnnuityAge(e.target.value) }} className="form-control" />
                      </div>

                    </div>
                    {
                      ageannuityStatus == 0 ? '' : ageannuityStatus == 1 ? 'Good, let’s estimate how much Annuity you need' : 'Seems you don’t have a long time horizon, let’s see if it works out wel'
                    }
                    <div className="col-12">
                      <div className="from-group mb-2">
                        <label className="tx-gray">Any details</label>
                        <textarea name="anydetails2" rows={6} className="form-control" value={anydetails2} onChange={(e) => { setAnydetailsf2(e.target.value) }} placeholder="Add any details that you would like to retain for future, like assumptions, thought process behind it or anything else that is relevant to this goal"></textarea>
                      </div>

                    </div>
                    {customize == 1 ? <div style={{ color: 'red' }}>
                      <strong>Note :-</strong>
                      <p className='tx-11 mb-1'>- Annuity is regular flow of money on monthly, quarter, annual or any frequency basis like Pension.</p>
                      <p className='tx-11'>- Accumulation is simply saving every month to get large amount at a future date, like saving to buy a Car in future.</p>
                    </div> : ''}
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-button">
              <button className="btn btn-primary btn-lg btn-full" onClick={processstep}>Continue</button>
            </div>
          </>
          : ""}
        {step == 8 ?
          <>
            <div className="mobile-main">

              <div className="mobile-form">
                <CustomProgressBar percent={20} />
                <div className="p15 mt-3">
                  <div className="row g-2">
                    <div className="col-12">
                      <div className="from-group mb-3">
                        <label className="tx-gray">What is your current monthly take home salary approximately ?<span className="text-danger fs-5">*</span></label>
                        <input type="number" name="monthysal" value={at} onChange={(e) => { setAt(e.target.value); setap(Math.round((0.6 * e.target.value) / 1000) * 1000) }} />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="from-group mb-3">
                        <label className="tx-gray">How much monthly Annuity would you need Rs<span className="text-danger fs-5">*</span></label>
                        <input type="number" name="annuity" value={ap} onChange={(e) => { setap(e.target.value) }} className="form-control" />
                      </div>

                    </div>
                    <div className="col-12">
                      <div className="from-group mb-3">
                        <label className="tx-gray">Set Portfolio Name<span className="text-danger fs-5">*</span></label>
                        <input type="text" name="goalname" value={portfolioname} onChange={(e) => { setPortfolioname(e.target.value) }} className="form-control" />
                      </div>

                    </div>
                    <div className="col-12">
                      <label>Don’t worry about inflation as yet. Just estimate what your expenses are today. Remove expenses like kid’s education, housing EMIs, loan EMIs and add some extra for medical expenses. As a ballpark, this should be around 50-60% of your current expenses. </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-button d-flex">
              <button className='btn btn-secondary btn-lg btn-full me-2' onClick={() => { setStep(7) }}>Previous</button>
              <button className="btn btn-primary btn-lg btn-full" onClick={processstep}>Continue</button>
            </div>
          </>
          : ""}
        {step == 9 ?
          <>
            <div className="mobile-main">

              <div className="mobile-form">
                <CustomProgressBar percent={30} />
                <div className="p15 mt-3">
                  <div className="row g-2">
                    <div className="col-12">
                      <div className="from-group mb-3">
                        <label className="tx-gray">What is your expected rate of return for accumulating for Annuity fund<span className="text-danger fs-5">*</span></label>
                        <input type="number" name="rateofreturn" value={ar} onChange={(e) => { setar(e.target.value) }} className="form-control" />
                      </div>

                    </div>
                    <div className="col-12">
                      <div className="from-group mb-3">
                        <label className="tx-gray">What is your expected rate of inflation<span className="text-danger fs-5">*</span></label>
                        <input type="number" name="number" value={ai} onChange={(e) => { setai(e.target.value) }} className="form-control" />
                      </div>

                    </div>
                    <div className="col-12">
                      <div className="from-group mb-3">
                        <label className="tx-gray">Set Portfolio Name<span className="text-danger fs-5">*</span></label>
                        <input type="text" name="goalname" value={portfolioname} onChange={(e) => { setPortfolioname(e.target.value) }} className="form-control" />
                      </div>

                    </div>

                    <div className="col-12">
                      <label>Your expected rate of return depends upon your risk profile, your need for risk, you capacity and behavioural aspects. In general, including equities in higher percentage increases risk and also increases your rate of return.</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-button d-flex">
              <button className='btn btn-secondary btn-lg btn-full me-2' onClick={() => { setStep(8) }}>Previous</button>
              <button className="btn btn-primary btn-lg btn-full" onClick={calcformulaAnnuityx}>Continue</button>
            </div>
          </>
          : ""}
        {step == 10 ?
          <>
            <div className="mobile-main">

              <div className="mobile-form">
                <CustomProgressBar percent={40} />
                <div className="p15 mt-3">
                  <div className="row g-2">
                    <div className="col-12">
                      <label>You need a Annuity of <span className="tx-theme fw600">₹{ap}</span> per month, start {an} years from now. However due to inflation, value of this money will reduce in {an} years. You will actually need <span className="tx-theme fw600">₹{ax}</span> per month after 10 years, and this should increase every year by inflation, i.e {ai}</label>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div className="footer-button d-flex">
              <button className='btn btn-secondary btn-lg btn-full me-2' onClick={() => { setStep(9) }}>Previous</button>
              <button className="btn btn-primary btn-lg btn-full" onClick={processstep}>Continue</button>
            </div>
          </>
          : ""}
        {step == 11 ?
          <>
            <div className="mobile-main">

              <div className="mobile-form">
                <CustomProgressBar percent={60} />
                <div className="p15 mt-3">
                  <div className="row g-2">
                    <div className="col-12">
                      <label>Once you invest monthly and accumulate a Annuity corpus, you will then need to invest this corpus somewhere so that you can get monthly annuity. What rate of return should you expect for this return ?</label>
                    </div>
                    <div className="col-12">
                      <label>In general, you can buy this monthly annuity plan from annuity/ insurance companies. The return expected is slightly less than 10 year Government of India securities, and is normally not able to beat inflation.</label>
                    </div>
                    <div className="col-12">
                      <label>Or you can generate a higher return by investing in mutual funds, with systematic withdrawal plans.</label>
                    </div>
                    <div className="col-12">
                      <div className="from-group mb-3">
                        <label className="tx-gray">What is your expected rate of return on accumulated funds ?<span className="text-danger fs-5">*</span></label>
                        <input type="number" name="roraccuumulatefund" value={ae} onChange={(e) => { setae(e.target.value) }} className="form-control" />
                      </div>

                    </div>
                    <div className="col-12">
                      <div className="from-group mb-3">
                        <label className="tx-gray">Your annuity will increase at the rate of {aq1}% every year to counter inflation<span className="text-danger fs-5">*</span></label>
                        <input type="number" name="roraccuumulatefund" value={aq1} onChange={(e) => { setAq1(e.target.value) }} className="form-control" />
                      </div>

                    </div>
                    {
                      aq1msg == 1 ?
                        <div style={{ color: 'red' }}>At your expected rate of return {ae} %, increasing pension more than {aq1} % will be not feasible. Increase expected rate, or reduce increase in monthly pension</div> : ''
                    }

                  </div>
                </div>
              </div>
            </div>
            <div className="footer-button d-flex">
              <button className='btn btn-secondary btn-lg btn-full me-2' onClick={() => { setStep(10) }}>Previous</button>
              <button className="btn btn-primary btn-lg btn-full" onClick={calcformulaAnnuityS}>Continue</button>
            </div>
          </>
          : ""}
        {step == 12 ?
          <>
            <div className="mobile-main">
              {/* <div className="dflex align-items-center ms-2 pt-3" onClick={()=>{setStep(11)}}>  <i class="icon-arrow-left8"></i></div> */}
              <div className="mobile-form">
                <CustomProgressBar percent={80} />
                <div className="p15 mt-3">

                  <div className="row g-2">
                    {
                      annuitycalcerror == 1 ?
                        <div style={{ color: 'red' }}>“Oh ! Looks like the numbers are not working somehow. Try with different numbers ?”</div> : ''
                    }
                    {sipannuitysalaryerror == 1 ?
                      <ul>
                        <li>Your SIP required is more than half your salary <span className="tx-theme fw600">₹{asip}</span>.</li>
                        <li>You should start the SIP of at least <span className="tx-theme fw600">₹{Math.round(0.4 * at)}</span> and revise every year. Take forward for investment amount, allow changing of this amount if required</li>
                        <li>Or you can change your expectations and reduce annuity amount</li>
                        <button type="button" className="mt-10 buttonab-line" onClick={(e) => { setStep(8) }}>Change Annuity Amount<i class="fa fa-long-arrow-right mg-l-10" aria-hidden="true"></i></button>
                        <li className='mt-3'>Or postpone your retirement by few years</li>
                        <button type="button" className="mt-10 buttonab-line" onClick={(e) => { setStep(7) }}>Change retirement Age<i class="fa fa-long-arrow-right mg-l-10" aria-hidden="true"></i></button>
                        <div className='col-lg-12'>
                          <div className="from-group mb-3">
                            <label className="tx-gray">Or invest 40% of your amount<span className="text-danger fs-5">*</span></label>
                            <input type="number" name="asipeoor" value={0.4 * asip} onChange={(e) => { setasip(e.target.value) }} className="form-control" />
                          </div>



                        </div>
                        <div className="d-flex">
                          <button className=' btn btn-secondary btn-lg btn-full me-2 ' onClick={() => { setStep(11) }}>Previous</button>
                          <button className="btn btn-primary btn-lg btn-full" onClick={setportfolioname}>
                            Continue
                          </button>


                        </div>
                        {/* <button className="btn btn-primary btn-lg btn-full mt-4 " onClick={setportfolioname}>
                          Continue
                        </button> */}

                      </ul> :
                      <div className="gform mt-20">
                        <div className='col-lg-12'>
                          <div className="gform-box mg-b-20">
                            <label>Your accumulated annuity corpus needs to be Rs <span className="tx-theme">{as}</span></label>
                          </div>
                        </div>
                        <div className='col-lg-12'>
                          <div className="gform-box mg-b-20">
                            <label>And you need to invest monthly Rs <span className="tx-theme">{asip}</span></label>
                          </div>
                        </div>

                        <div className="footer-button">
                          <button
                            className="btn btn-secondary btn-lg btn-full mb-4"
                            onClick={restartprocessflow2}
                          >
                            Try with diffrent number
                          </button>
                          {annuitycalcerror == 0 ?
                            <>
                              <div className="d-flex ">
                                <button className='btn btn-secondary btn-lg btn-full me-2' onClick={() => { setStep(11) }}>Previous</button>
                                <button className="btn btn-primary btn-lg btn-full" onClick={setportfolioname}>Continue</button>
                              </div>
                            </>


                            : ""}
                        </div>
                        {/* <div className="footer-button">
                        <button className="btn btn-secondary btn-lg btn-full mt-4 " onClick={restartprocessflow2}>
                          Try with diffrent numbers
                        </button>
                        {annuitycalcerror == 0 ?
                        <>
                        
                        <div className="d-flex "> 
                        <button className='btn btn-secondary btn-lg btn-full me-2' onClick={()=>{setStep(11)}}>Previous</button> 
                        <button className="btn btn-primary btn-lg btn-full mt-4 " onClick={setportfolioname}>
                            Continue
                          </button>
                      
                        
                        </div>
                        
                        </>
                          : ""}
                       
            </div> */}


                      </div>
                    }

                  </div>
                </div>
              </div>
            </div>
          </>
          : ""}
        {step == 13 ?
          <>
            <div className="mobile-main">
              <div className="mobile-form">
                <div className="p15 mt-3">
                  <div className="row g-2 mt-3">
                    <CustomProgressBar percent={25} />
                    <div className="col-12">
                      <div className="from-group mb-2">
                        <label>Goal Type<span className="text-danger fs-5">*</span></label>
                        <select name="goal_type" className="custom-select" value={goal_type} onChange={(e) => { if (e.target.value != '6' && e.target.value != '8' && e.target.value != '9' && e.target.value != '7') { setCustomize(0); setGoalType(e.target.value); if (['2', '3', '4', '5'].includes(e.target.value)) { setStep(13); setprocessbarflow3(1); } else { setprocessbarflow3(0); setStep(eval(e.target.value)) } } else { customizegoal(e.target.value) } }}>
                          <option value="1">Plan for pension after retirement</option>
                          <option value="2">Plan for buying a new house</option>
                          <option value="3">Plan for Child’s education</option>
                          <option value="4">Plan for Child’s Marriage</option>
                          <option value="5">Plan for a vacation</option>
                          <option value="7">Plan my own customised Goal</option>
                          <option value="6">Don’t have a goal, but want to invest</option>
                          <option value="8">Start SIP</option>
                          <option value="9">Start Lumpsum Investment</option>
                        </select>
                      </div>
                    </div>
                    {customize == 1 ?
                      <>
                        <div className="col-12">
                          <div className="from-group mb-2">
                            <label className="tx-gray">Select Customize Goal<span className="text-danger fs-5">*</span></label>
                            <select name="goal_type" className="custom-select" value={customize_type} onChange={(e) => { if (e.target.value != '13') { setprocessbarflow3(0) } else { setprocessbarflow3(1) } setCustomizeType(e.target.value); setGoalType('7'); setStep(eval(e.target.value)) }}>
                              <option value="7">Plan an annuity goal (e.g Monthly Pension)</option>
                              <option value="13">Plan to accumulate (e.g for new Car)</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="from-group mb-2">
                            <label className="tx-gray">Set Portfolio Name<span className="text-danger fs-5">*</span></label>
                            <input type="text" name="goalname" value={portfolioname} onChange={(e) => { setPortfolioname(e.target.value) }} className="form-control" />
                          </div>
                        </div>
                      </>
                      : ""}
                    <div className="col-12">
                      <div className="from-group mb-2">
                        <label>How much money is required ? Rs<span className="text-danger fs-5">*</span></label>
                        <input type="number" name="moneyreq" value={q} onChange={(e) => { setq(e.target.value) }} className="form-control" />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="from-group mb-2">
                        <label>And in how many years from now ?<span className="text-danger fs-5">*</span> </label>
                        <input type="number" name="yearsfromnow3" value={n3} onChange={(e) => { setn3(e.target.value) }} className="form-control" />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="from-group mb-2">
                        <label>Don’t worry about inflation as yet. Just estimate how much do you need in today’s value. </label>
                      </div>
                    </div>

                    {customize == 1 ? <div style={{ color: 'red' }}>
                      <strong>Note :-</strong>
                      <p className='tx-11 mb-1'>- Annuity is regular flow of money on monthly, quarter, annual or any frequency basis like Pension.</p>
                      <p className='tx-11'>- Accumulation is simply saving every month to get large amount at a future date, like saving to buy a Car in future.</p>
                    </div> : ''}

                  </div>
                </div>
              </div>
            </div>
            <div className="footer-button">
              <button className="btn btn-primary btn-lg btn-full" onClick={processstep}>Continue</button>
            </div>
          </>
          : ""}
        {step == 14 ?
          <>
            <div className="mobile-main">

              <div className="mobile-form">
                <div className="p15 mt-3">
                  <div className="row g-2">
                    <CustomProgressBar percent={50} />
                    <div className="col-12">
                      <div className="from-group mb-2">
                        <label className="tx-gray">What is your expected rate of return<span className="text-danger fs-5">*</span></label>
                        <input type="number" name="ror3" defaultValue={r3} onChange={handleR3Change} className="form-control" />
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="from-group mb-2">
                        <label className="tx-gray">What is your expected rate of inflation<span className="text-danger fs-5">*</span></label>
                        <input type="number" name="roi3" defaultValue={i3} onChange={handleI3Change} className="form-control" />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="from-group mb-2">
                        <label className="tx-gray">Your expected rate of return depends upon your risk profile, your need for risk, you capacity and behavioural aspects. In general, including equities in higher percentage increases risk and also increases your rate of return.</label>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="from-group mb-2">
                        <label className="tx-gray">Inflation can slowly erode your wealth, and can make your plans go haywire. A correct estimate, and timely revision and rebalancing is important for you to succeed in your goals</label>
                      </div>
                    </div>


                  </div>
                </div>
              </div>
            </div>

            <div className='footer-button d-flex'>
              <button className='btn btn-secondary btn-lg btn-full me-2' onClick={() => { setStep(13) }}>Previous</button>

              <button className="btn btn-primary btn-lg btn-full" onClick={calculateYflow3}>Continue</button>

            </div>


          </>
          : ""}
        {step == 15 ?
          <>
            <div className="mobile-main">

              <div className="mobile-form">
                <CustomProgressBar percent={75} />
                <div className="p15 mt-3">
                  {
                    calcerrorflow3 == 1 ?
                      <div style={{ color: 'red' }}>“Oh ! Looks like the numbers are not working somehow. Try with different numbers ?”</div> : ''
                  }
                  <div className="row g-2">
                    <div className="col-12">

                      <label>At an inflation rate of {i3} you will need Rs <span className="tx-theme fw600">₹{y}</span> in {n3} years .</label>

                    </div>

                    <div className="col-12">
                      <div className="from-group mb-2">
                        <label>And you need a monthly investment of Rs <span className="tx-theme fw600">₹{sip3}</span></label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="from-group mb-2">
                        <label className="tx-gray">Your expected rate of return depends upon your risk profile, your need for risk, you capacity and behavioural aspects. In general, including equities in higher percentage increases risk and also increases your rate of return.</label>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="from-group mb-2">
                        <label className="tx-gray">Inflation can slowly erode your wealth, and can make your plans go haywire. A correct estimate, and timely revision and rebalancing is important for you to succeed in your goals</label>
                      </div>
                    </div>


                  </div>
                </div>
              </div>
            </div>
            <div className="footer-button">
              {calcerrorflow3 == 1 ?
                <button className="btn btn-secondary btn-lg btn-full mb-4" onClick={restartprocessflow3}> Try with different number</button> :

                <>
                  <div className="d-flex">
                    <button className='btn btn-secondary btn-lg btn-full me-2' onClick={() => { setStep(14) }}>Previous</button>
                    <button className="btn btn-primary btn-lg btn-full" onClick={calculateYflow3}>Continue</button>

                  </div>




                </>

              }

            </div>



          </>
          : ""}
        {step == 16 ?
          <>

            <div className="mobile-main">

              <div className="mobile-form">
                <CustomProgressBar percent={100} />
                <div className="p15 mt-3">

                  <div className="row g-2">
                    <div className="col-12">
                      <div className="from-group mb-2">
                        <label className="tx-gray">Give some name to this portfolio <span className="text-danger fs-5">*</span></label>
                        <input type="text" name="portfolio_name" defaultValue={portfolioname} onChange={(e) => { setPortfolioname(e.target.value) }} className="form-control " />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-button d-flex">

              <button className='btn btn-secondary btn-lg btn-full me-2' onClick={() => { changestep16() }}>Previous</button>
              <button className="btn btn-primary btn-lg btn-full" onClick={submitgoalsettingsdata} disabled={isLoading}>  {isLoading ? (
                <img src="/img/loder01.gif" width="60px" height="11px" />
              ) : (
                "Continue"
              )}</button>
            </div>
          </>
          : ""}
        {step == 50 ?
          <>
            <div className="mobile-main">
              <div className="mobile-form">
                <div className="p15 mt-3">

                  <div className="row g-2 mt-3">
                    <div className="col-12">
                      <div className="from-group mb-2">
                        <label>Goal Type<span className="text-danger fs-5">*</span></label>
                        <select name="goal_type" className="custom-select" value={goal_type} onChange={(e) => { if (e.target.value != '6' && e.target.value != '8' && e.target.value != '9' && e.target.value != '7') { setCustomize(0); setGoalType(e.target.value); if (['2', '3', '4', '5'].includes(e.target.value)) { setStep(13); setprocessbarflow3(1) } else { setprocessbarflow3(0); setStep(eval(e.target.value)) } } else { customizegoal(e.target.value) } }}>
                          <option value="1">Plan for pension after retirement</option>
                          <option value="2">Plan for buying a new house</option>
                          <option value="3">Plan for Child’s education</option>
                          <option value="4">Plan for Child’s Marriage</option>
                          <option value="5">Plan for a vacation</option>
                          <option value="7">Plan my own customised Goal</option>
                          <option value="6">Don’t have a goal, but want to invest</option>
                          <option value="8">Start SIP</option>
                          <option value="9">Start Lumpsum Investment</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-button">

              <button className="btn btn-primary btn-lg btn-full" onClick={skipflow}>Continue</button>
            </div>
          </>
          : ""}



        <Footer />
      </MobileView>

    </>
  )
}

export default Creategoals