import React, { useEffect, useRef, useState } from "react";
import CustomProgressBar from "../../Components/Elements/progress_bar";
import { ApiService } from "../../Components/Services/apiServices";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { BrowserView, MobileView } from "react-device-detect";
import { TailSpin } from "react-loader-spinner";
import { showToastWithOkButton } from "../../Components/Elements/toastutils";
let apiServices = new ApiService();
function Bsestep4guardiannominee() {
  const navigate = useNavigate();
  const [spinnerLoading, setSpinnerLoading] = useState(false)
  const [nomineeDetails, setNomineeDetails] = useState([
    {
      nomineeName: "",
      nomineeRelationship: "",
      nomineeApplicable: "",
      nomineeSelected: "N",
      nomineeDob: "",
      nomineeGuardian: "",
    },
  ]);
  const [nomineeOption, setNomineeOption] = useState("Y")
  const didMountRef = useRef(true);
  useEffect(() => {
    toast.error = (message) => {
      showToastWithOkButton(message);
    };
    if (didMountRef.current) {
      setSpinnerLoading(true)
      const dataString = {
      };
      apiServices.bsegetuserdataPostRequest(dataString).then((result) => {
        if (result.data.status === "success") {
          setSpinnerLoading(false)
          if (
            result.data.bse_nominee_data &&
            result.data.bse_nominee_data.length > 0
          ) {
            const fetchedNomineeDetails = result.data.bse_nominee_data.map((nomineeData) => {
            
              return {
                nomineeName: nomineeData.und_nominee_name,
                nomineeRelationship: nomineeData.und_nominee_relationship,
                nomineeApplicable: nomineeData.und_nominee_applicable,
                nomineeSelected: nomineeData.und_nominee_selected,
              }
            })
            setNomineeDetails(fetchedNomineeDetails);
         
          }
        } else if (
          result.data.status === "error" &&
          result.data.message === "Session expired"
        ) {
          setSpinnerLoading(false)
          navigate("/");
          localStorage.removeItem('AUTH_TOKEN');
        }
      });
  
    }
    didMountRef.current = false;
  }, []);
  const handleBackClick = () => {
    navigate("/bseonboarding/step3cont");
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // Ensure nomineeDetails is an array of objects
    const dataString = {
      nomineeDetails: nomineeDetails.map((nominee) => ({
        nomineeName: nominee.nomineeName,
        nomineeRelationship: nominee.nomineeRelationship,
        nomineeApplicable: nominee.nomineeApplicable,
        nomineeSelected: nominee.nomineeSelected,
        nomineeDob: nominee.nomineeDob,
        nomineeGuardian: nominee.nomineeGuardian,
      })),
      nomineeOpt: nomineeOption,
    };
    apiServices
      .bseusernomineePostRequest(dataString)
      .then((result) => {
        if (result.data.status === "success") {
          toast.success("Form submitted successfully!");
          navigate("/bseonboarding/nomineeauth");
        } else if (
          result.data.status === "error" &&
          result.data.message === "Session expired"
        ) {
          navigate("/");
          localStorage.removeItem("AUTH_TOKEN");
        } else {
          toast.error(result.data.message);
        }
      })
      .catch((error) => {
        console.error("API Error:", error);
      });

    ; // Log the correct data
  };
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const updatedNomineeDetails = [...nomineeDetails];
    updatedNomineeDetails[index][name] = value;
    setNomineeDetails(updatedNomineeDetails);

  };
//   let removeFormFields = (i) => {
//     let newFormValues = [...formValues];
//     newFormValues.splice(i, 1);
//     setFormValues(newFormValues)
// }
const removeNomineinput=(e,i)=>{
  const removeinput=[...nomineeDetails];
  removeinput.splice(i,1)
  setNomineeDetails(nomineeDetails)
}
  const handleAddNomineeClick = () => {
    setNomineeDetails([...nomineeDetails, getEmptyNomineeDetails()]);
 
  };
  const getEmptyNomineeDetails = () => {
    return {
      option: "Y",
      nomineeName: "",
      nomineeRelationship: "",
      nomineeApplicable: "",
      nomineeSelected: "",
      nomineeDob: "",
      nomineeGuardian: "",
    };
  };
  return (
    <>
     <div className={spinnerLoading == true ? 'parentDisable' : ''} width="100%">
          <div className='overlay-box'>
            <TailSpin
              color="#ffffff"
              visible={spinnerLoading}
            />
          </div>
          </div>
      <BrowserView>
        <div className="lrsection">
          <div className="lrsection-content">
            <div>
              <img
                src="/img/logo.png"
                className="wd-150 mb-4"
              ></img>
              <h2>Goal based Investment Platform</h2>
              <h4 className="tx-theme-secondary">
                Intelligent. Disciplined. Smart
              </h4>
              <p>
                Every financial goal can be achieved through disciplined
                approach and smart technology. You really don’t need fancy
                offerings or jargons.
              </p>
              <ul className="features-list mt-4">
                <li>Minimum Documents</li>
                <li>Goal based Investment </li>
                <li>Quick Onboarding</li>
              </ul>
            </div>
          </div>
          <div className="lrsection-form">
          <div className="backbutton-desktop" onClick={handleBackClick} ><i class="icon-arrow-left8 wd-20 "></i></div>
            <CustomProgressBar percent={75} />
            <div className="loginbox">
              <div className="mb-4">
                <p>Step 5 of 5</p>
              </div>
              <div className="row g-2">
                <div className="col-lg-12">
                  <div className="from-group mb-3">
                    <h6>Nominee Guardian Opt</h6>
                    <select
                      name={"nomineeOption"}
                      value={nomineeOption}
                      className="custom-select"
                      id="nomineeSelected"
                      onChange={(e) => setNomineeOption(e.target.value)}
                    >
                      <option value="Y">Yes</option>
                      <option value="N">No</option>
                    </select>
                  </div>
                </div>
              </div>
              {nomineeDetails.map((nominee, index) => (
                <div key={index}>
                  {nomineeOption === "Y" ? (
                    <div className="mb-4">
                      <div>
                        <span onClick={()=>{removeNomineinput(index)}}></span>
                      </div>
                      <h6>
                        Nominee Guardian Details {index + 1}
                        {index < 1 ? "(Default Nominee)" : ""}
                      </h6>
                      <div className="row g-2">
                        <div className="col-lg-6">
                          <div className="from-group mb-3">
                            <label>Nominee Guardian Name</label>
                            <input
                              type="text"
                              name={"nomineeName"}
                              id="nomineeName"
                              value={nominee.nomineeName}
                              onChange={(e) => handleInputChange(e, index)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="from-group mb-3">
                            <label>Nominee Guardian Relationship</label>
                            <input
                              type="text"
                              name={"nomineeRelationship"}
                              id="nomineeRelationship"
                              value={nominee.nomineeRelationship}
                              onChange={(e) => handleInputChange(e, index)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row g-2">
                        <div className="col-lg-6">
                          <div className="from-group mb-3">
                            <label>Nominee Guardian % Applicable</label>
                            <input
                              type="text"
                              name={"nomineeApplicable"}
                              id="nomineeApplicable"
                              value={nominee.nomineeApplicable}
                              onChange={(e) => handleInputChange(e, index)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="from-group mb-3">
                            <label>Nominee Guardian Minor Flag</label>
                            <select
                              name={"nomineeSelected"}
                              value={nominee.nomineeSelected}
                              className="custom-select"
                              id="nomineeSelected"
                              onChange={(e) => handleInputChange(e, index)}
                            >
                              <option value="Y">Yes</option>
                              <option value="N">No</option>
                            </select>
                          </div>
                        </div>

                        {nominee.nomineeSelected == 'Y' ?
                          <div className="row g-2" >
                            <div className="col-lg-6">
                              <div className="form-group mb-3">
                                <label>Nominee DOB</label>
                                <input
                                  type="date"
                                  name="nomineeDob"
                                  id="nomineeDob"
                                  value={nominee.nomineeDob}
                                  onChange={(e) => handleInputChange(e, index)}
                                />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="from-group mb-3">
                                <label>Nominee Guardian Name</label>
                                <input
                                  type="text"
                                  name="nomineeGuardian"
                                  id="nomineeGuardian"
                                  value={nominee.nomineeGuardian}

                                  onChange={(e) => handleInputChange(e, index)}
                                />
                              </div>
                            </div>
                          </div> : ""}
                      </div>
                    </div>
                  ) : null}
                </div>
              ))}
              {nomineeOption === "Y" && nomineeDetails.length <= 2 && (
                <button
                  className="btn btn-secondary btn-lg btn-full mb-4"
                  onClick={handleAddNomineeClick}
                >
                  Add More
                </button>
              )}
            
                <button
                  className="btn btn-primary btn-lg btn-full"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              
            </div>
          </div>
        </div>
      </BrowserView>
      <MobileView>
      <header className="mheader">
          <div className="container">
            <div className="mheader-left">
            <div className="dflex align-items-center" onClick={handleBackClick}>
            <i class="icon-arrow-left8"></i>
            <div className="ms-3">
            <h6 className="mb-0">  Nominee Guardian Details</h6>
            <p className="tx-12 mb-0 line18">Step 5 of 5</p>
            </div>
            </div>
            </div>
          </div>
        </header>
       <div className="mobile-main">
       <div className="mobile-form">
          <CustomProgressBar percent={75} />
          <div className="p15 mt-3 mb-5 pb-5">
            <div className="row g-2">
              <div className="col-lg-12">
                <div className="from-group mb-3">
                  <h6>Nominee Guardian Opt</h6>
                  <select
                    name={"nomineeOption"}
                    value={nomineeOption}
                    className="custom-select"
                    id="nomineeSelected"
                    onChange={(e) => setNomineeOption(e.target.value)}
                  >
                    <option value="Y">Yes</option>
                    <option value="N">No</option>
                  </select>
                </div>
              </div>
            </div>
            {nomineeDetails.map((nominee, index) => (
              <div key={index}>
                {nomineeOption === "Y" ? (
                  <div className="mb-4">
                    <h6>
                      Nominee Guardian Details {index + 1}
                      {index < 1 ? "(Default Nominee)" : ""}
                    </h6>
                    <div className="row g-2">
                      <div className="col-lg-6">
                        <div className="from-group mb-3">
                          <label>Nominee Guardian Name</label>
                          <input
                            type="text"
                            name={"nomineeName"}
                            id="nomineeName"
                            value={nominee.nomineeName}
                            onChange={(e) => handleInputChange(e, index)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="from-group mb-3">
                          <label>Nominee Guardian Relationship</label>
                          <input
                            type="text"
                            name={"nomineeRelationship"}
                            id="nomineeRelationship"
                            value={nominee.nomineeRelationship}
                            onChange={(e) => handleInputChange(e, index)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row g-2">
                      <div className="col-lg-6">
                        <div className="from-group mb-3">
                          <label>Nominee Guardian % Applicable</label>
                          <input
                            type="text"
                            name={"nomineeApplicable"}
                            id="nomineeApplicable"
                            value={nominee.nomineeApplicable}
                            onChange={(e) => handleInputChange(e, index)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="from-group mb-3">
                          <label>Nominee Guardian Minor Flag</label>
                          <select
                            name={"nomineeSelected"}
                            value={nominee.nomineeSelected}
                            className="custom-select"
                            id="nomineeSelected"
                            onChange={(e) => handleInputChange(e, index)}
                          >
                            <option value="Y">Yes</option>
                            <option value="N">No</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            ))}
            {nomineeOption === "Y" && nomineeDetails.length <= 2 && (
              <button
                className="btn btn-secondary btn-lg btn-full mb-4"
                onClick={handleAddNomineeClick}
              >
                Add More
              </button>
            )}
          
          </div>
        </div>
       </div>
       <div className="footer-button">
       
              <button
                className="btn btn-primary btn-lg btn-full "
                onClick={handleSubmit}
              >
                Submit
              </button>
            
            </div>
      </MobileView>
    </>
  );
}
export default Bsestep4guardiannominee;
