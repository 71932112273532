import React, { useRef, useState, useEffect } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import CustomProgressBar from '../../Components/Elements/progress_bar';
import { ApiService } from "../../Components/Services/apiServices";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { TailSpin } from "react-loader-spinner";
import { showToastWithOkButton } from "../../Components/Elements/toastutils";
let apiServices = new ApiService()
function BseOnboardingNomineeAuth() {
  const [nomineeOtp, setNomineeOtp] = useState("")
  const [counter, setCounter] = React.useState(0);
  const [seconds, setseconds] = useState(0);
  const [minutes, setminutes] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [nomineeData, setNomineeData] = useState([])
  const [showOTPScreen, setShowOTPScreen] = useState(false)
  const [spinnerLoading, setSpinnerLoading] = useState(false)
  const navigate = useNavigate()
  const didMountRef = useRef(true)
  useEffect(() => {
    if (didMountRef.current == true) {
      toast.error = (message) => {
        showToastWithOkButton(message);
      };
      setSpinnerLoading(true)
      apiServices.usernomineedatapostrequest({}).then(res => {
        if (res.data.status == "success") {         
          setNomineeData(res.data.data)
          setSpinnerLoading(false)
        }
        else if (res.data.status === 'error' && res.data.message === 'Session expired') {
          navigate('/')
          localStorage.removeItem('AUTH_TOKEN');
          setSpinnerLoading(false)
        }
      })
    }
    didMountRef.current = false
  })
  const verifyotpnomineeauth = () => {
    if (nomineeOtp == "") {
      toast.error("Please Enter OTP")
      return false
    }
    setIsLoading(true)
    const datastring = {
      otp: nomineeOtp
    }
    apiServices.verifybseonboardnomineeauthotppostrequest(datastring).then(res => {
      if (res.data.status == "success") {
        toast.success(res.data.message)
        navigate("/bseonboarding/step4/upload_signature")
        setIsLoading(false)
      }
      else if (res.data.status == 'error' && res.data.message == 'Session expired') {
        navigate("/");
      }
      else {
        toast.error(res.data.message)
        setIsLoading(false)
      }
})
  }
  React.useEffect(() => {
    setTimeout(() => {
      if (seconds > 0) {
        setseconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
        } else {
          setseconds(59);
          setminutes(minutes - 1);
        }
      }
    }, 1000);
  });
  React.useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);
  const resendotp = (e) => {
    e.preventDefault();
    setIsLoading(true)
    apiServices
      .resendotpnomineeauthpostrequest({})
      .then((result) => {
        if (result.data.status == "success") {
          setShowOTPScreen(true)
          setCounter(30);
          setminutes(10);
          setseconds(0);
          setIsLoading(false)
          return false;
        } else {
          toast.error(result.data.message);
        }
      })
      .catch((error) => { });
  };
  return (
    <>
     <div className={spinnerLoading == true ? 'parentDisable' : ''} width="100%">
          <div className='overlay-box'>
            <TailSpin
              color="#ffffff"
              visible={spinnerLoading}
            />
          </div>
          </div>
      <BrowserView>
        <div className="auth-page-wrapper pt-50">
          <div className="auth-page-content">
            <div className="container">
              <div className="row justify-content-center mt-3">
                <div className="col-lg-6">
                  <div className="crds">
                  <CustomProgressBar percent={75} />
                    <div className="crds-body p-50">
                      <div className="text-center mb-30">
                        <img src="/img/logo.png" className="wd-150 mb-4"></img>
                        <p>Step 6 of 7</p>
                        <h6>
                          Nominee Authentication
                        </h6>
                      </div>
                      {nomineeData.map((value) => (
                       <div className="panel mb-4">
                       <div className="panel-body" style={{ padding: '0px' }}>
                         <table className="clienttable">
                           <>
                             <tr>
                               <th className="name">Nominee Name</th>
                               <td>{value.und_nominee_name}</td>
                             </tr>
                             <tr>
                               <th className="name">Nominee Relation</th>
                               <td>{value.und_nominee_relationship_name}</td>
                             </tr>
                             <tr>
                               <th className="name">Nominee %</th>
                               <td>{value.und_nominee_applicable}</td>
                             </tr>
                           </>
                         </table>
                       </div>
                     </div>
                    ))}
                    {showOTPScreen == true ?
                        <div className="col-lg-12">
                        <div className="from-group mb-3">
                          <label><b>Verify OTP</b><span className="tx-red">*</span></label>
                          <input
                            type="number"
                            name="nomineeApplicable2"
                            id="nomineeApplicable"
                            onChange={(e) => setNomineeOtp(e.target.value)}
                          />
                          {counter === 0 ? (
                            <p className="tx-12 mt-2">
                              Didn't receive OTP? Resend{" "}
                              <span
                                className="tx-theme"
                                style={{ cursor: "pointer" }}
                                onClick={resendotp}
                              >
                                (Click Here)
                              </span>
                            </p>
                          ) : (
                            <p className="tx-12 mt-2">
                              Resend OTP in {counter} seconds
                            </p>
                          )}
                        </div>
                      </div> : ""}
                      {showOTPScreen == true ?
                <button
                  className="btn btn-primary btn-lg btn-full "
                  onClick={verifyotpnomineeauth}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <img src="/img/loder01.gif" width="60px" height="11px" />
                  ) : (
                    "Continue"
                  )}
                </button> :
                <button
                  className="btn btn-primary btn-lg btn-full "
                  onClick={resendotp}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <img src="/img/loder01.gif" width="60px" height="11px" />
                  ) : (
                    "Send OTP"
                  )}
                </button>
              }
                <button className="btn btn-outline-primary btn-lg btn-full mt-3" onClick={() => { navigate("/bseonboarding/step4") }} >Back</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </BrowserView>
      <MobileView>
        <header className="mheader">
          <div className="container">
            <div className="mheader-left">
              <div className="dflex align-items-center" onClick={() => { navigate("/bseonboarding/step4") }}>
                <i class="icon-arrow-left8"></i>
                <div className="ms-3">
                  <h6 className="mb-0">Nominee Details (India)</h6>
                  <p className="tx-12 mb-0 line18">Step 6 of 7</p>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="mobile-main">
          <div className="mobile-form">
            <CustomProgressBar percent={75} />
            <div className="p15 pb-5 mb-5">
              <div className="mb-2">
                <h6 className="mt-3 mb-3">
                  Nominee Authentication
                </h6>
                {nomineeData.map((value) => (
                  <table className="clienttable">
                    <>
                      <tr>
                        <th className="name">Nominee Name</th>
                        <td>{value.und_nominee_name}</td>
                      </tr>
                      <tr>
                        <th className="name">Nominee Relation</th>
                        <td>{value.und_nominee_relationship_name}</td>
                      </tr>
                      <tr>
                        <th className="name">Nominee %</th>
                        <td>{value.und_nominee_applicable}</td>
                      </tr>
                    </>
                  </table>))}
                {showOTPScreen == true ?
                  <div className="row g-2 mt-2">
                    <div className="col-lg-6">
                      <div className="from-group mb-2">
                        <label><b>Verify OTP</b><span className="tx-red">*</span></label>
                        <input
                          type="number"
                          name="nomineeApplicable2"
                          id="nomineeApplicable"
                          onChange={(e) => setNomineeOtp(e.target.value)}
                        />
                        {counter === 0 ? (
                          <p className="tx-12 mt-2">
                            Didn't receive OTP? Resend{" "}
                            <span
                              className="tx-theme"
                              style={{ cursor: "pointer" }}
                              onClick={resendotp}
                            >
                              (Click Here)
                            </span>
                          </p>
                        ) : (
                          <p className="tx-12 mt-2">
                            Resend OTP in {counter} seconds
                          </p>
                        )}
                      </div>
                    </div>
                  </div> : ""}
              </div>
            </div>
          </div>
        </div>
        <div className="footer-button">
          {showOTPScreen == true ?
            <button
              className="btn btn-primary btn-lg btn-full "
              onClick={verifyotpnomineeauth}
              disabled={isLoading}
            >
              {isLoading ? (
                <img src="/img/loder01.gif" width="60px" height="11px" />
              ) : (
                "Continue"
              )}
            </button> : <button
              className="btn btn-primary btn-lg btn-full "
              onClick={resendotp}
              disabled={isLoading}
            >
              {isLoading ? (
                <img src="/img/loder01.gif" width="60px" height="11px" />
              ) : (
                "Send OTP"
              )}
            </button>}
        </div>
      </MobileView>
    </>
  )
}
export default BseOnboardingNomineeAuth